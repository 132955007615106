import React, { useContext } from "react";
import { Checkbox, RequiredValidator } from "@lysaab/ui-2";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";

const riskRequiredValidator = new RequiredValidator(
  "Ett företag som är skatt-/deklarationsskyldig i USA kan av " +
    "skattemässiga skäl inte bli kund hos oss."
);

const fatcaAlternative = {
  text:
    "Jag bekräftar att det valda företaget inte är deklarations- eller " +
    "skattskyldigt i USA",
  value: "-",
};

export const FatcaCheckbox = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const isChecked = contextState.companySelectionIsFatcaConfirmed;

  return (
    <Checkbox
      alternative={fatcaAlternative}
      checked={isChecked}
      onChange={() =>
        updateContext({ companySelectionIsFatcaConfirmed: !isChecked })
      }
      validators={[riskRequiredValidator]}
    />
  );
};
