import React, { FunctionComponent, useCallback } from "react";
import {
  Alternative,
  CheckboxGroup,
  RadioGroup,
  RequiredValidator,
  Select,
} from "@lysaab/ui-2";

export enum ALTERNATIVE_TYPE {
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
  DROPDOWN = "DROPDOWN",
}

export interface QuestionAlternative {
  checked: boolean;
  label: string;
  value: string;
}

interface Props {
  alternatives: QuestionAlternative[];
  question: string;
  type: ALTERNATIVE_TYPE;
  onChange: (alternatives: QuestionAlternative[]) => void;
  required?: boolean;
}

export const Question: FunctionComponent<Props> = ({
  question,
  alternatives,
  type,
  onChange,
  required = false,
}) => {
  const onUpdate = useCallback(
    (values: Alternative<string> | Alternative<string>[]) => {
      const selectedValues = Array.isArray(values) ? values : [values];
      const newAlternatives = alternatives.map((alt): QuestionAlternative => {
        const isSelected = !!selectedValues.find(
          (selected) =>
            alt.label === selected.text && alt.value === selected.value
        );
        return {
          ...alt,
          checked: isSelected,
        };
      });
      onChange(newAlternatives);
    },
    [alternatives, onChange]
  );

  const formAlternatives: Alternative<string>[] = alternatives.map(
    (alt): Alternative<string> => {
      return {
        text: alt.label,
        value: alt.value,
      };
    }
  );

  const selectedAlternatives: Alternative<string>[] = alternatives
    .filter((alt) => alt.checked)
    .map((alt): Alternative<string> => {
      return {
        text: alt.label,
        value: alt.value,
      };
    });

  if (type === ALTERNATIVE_TYPE.RADIO) {
    return (
      <div>
        <RadioGroup
          header={question}
          onChange={onUpdate}
          value={selectedAlternatives[0]}
          alternatives={formAlternatives}
          validators={[new RequiredValidator("Du måste välja ett alternativ")]}
        />
      </div>
    );
  } else if (type === ALTERNATIVE_TYPE.DROPDOWN) {
    return (
      <div>
        <Select
          label={question}
          onChange={onUpdate}
          value={selectedAlternatives[0]}
          alternatives={formAlternatives}
          placeholder="Välj ett alternativ"
          validators={[new RequiredValidator("Du måste välja ett alternativ")]}
        />
      </div>
    );
  } else if (type === ALTERNATIVE_TYPE.CHECKBOX) {
    return (
      <div>
        <CheckboxGroup
          header={question}
          onChange={onUpdate}
          values={selectedAlternatives}
          alternatives={formAlternatives}
          validators={[new RequiredValidator("Du måste välja ett alternativ")]}
        />
      </div>
    );
  }
  return <div></div>;
};
