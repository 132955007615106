import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  AllocationBar,
  Icon,
  Spinner,
  InvestmentType,
  FlashContext,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { CompanyModel } from "../../../models/company";

import "./Advice.scss";
import { InvestmentDisclaimer } from "../../../components/InvestmentDisclaimer";
import { AdviceResponse, getAdvice } from "../../../data/investments";
import { savingsHorizonLengthAlternatives } from "../subpages/Horizon";
import { SuitabilityPdfLink } from "../../../hooks/SuitabilityPdfLink";
import { useAdvice } from "../../../models/AdviceContext";

export const Advice = () => {
  const { state: contextState } = useContext(CompanySignupContext);
  const history = useHistory();
  const adviceContext = useAdvice();
  const [advice, setAdvice] = useState<AdviceResponse>();
  const pushFlash = useContext(FlashContext).pushFlash;

  useEffect(() => {
    if (!contextState.signupId) {
      history.replace(names.HOME);
    }
  }, [history, contextState.signupId]);

  useEffect(() => {
    if (advice) {
      return;
    }

    const adviceData = CompanyModel.getAdviceData(
      contextState,
      adviceContext.input
    );

    if (!contextState.signupId || !adviceData) {
      history.push(names.HOME);
      return;
    }

    getAdvice(contextState.signupId, adviceData)
      .then((response) => {
        adviceContext.setResult({
          advicedRisk: response.advisedRisk,
          investmentType: response.investmentType,
        });
        setAdvice(response);
      })
      .catch(() => {
        pushFlash({
          type: SNACKBAR_TYPES.ERROR,
          text: "Serverfel. Du måste tyvärr börja om 😞",
        });

        history.push(names.HOME);
      });
  }, [advice, contextState, history, adviceContext, pushFlash]);

  if (!advice) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="company-signup-advice">
      <h2>Lysas investerings&shy;förslag</h2>
      <Card>
        <h4>
          <Icon.Chart />
          Investeringsförslag
        </h4>
        <dl>
          <dt>Kontotyp</dt>
          <dd>Värdepapperskonto (VP)</dd>
          <dt className="target">Målfördelning</dt>
          <dd className="allocation-bar-container">
            <AllocationBar risk={advice.advisedRisk} shouldFormatNumber />
          </dd>
          <dt>Investeringsfokus</dt>
          <dd>
            {advice.investmentType === InvestmentType.BROAD
              ? "Bred"
              : "Hållbar"}
          </dd>
          <dt>Investeringshorisont</dt>
          <dd>
            {
              savingsHorizonLengthAlternatives.find(
                (alt) => alt.value === adviceContext.input.savingsHorizon
              )?.text
            }
          </dd>
        </dl>
        <br className="clearFix" />
      </Card>

      <Button
        block
        type="button"
        onClick={() => {
          history.push(names.SUMMARY);
        }}
        label="Gå vidare"
      />
      <Button
        variant="secondary"
        block
        type="button"
        onClick={() => history.push(names.EDIT_ALLOCATION)}
        label="Jag vill ändra min fördelning"
      />
      <Button
        variant="secondary"
        block
        type="button"
        onClick={() => history.push(names.EDIT_ALLOCATION_FEES)}
        label="Visa avgifter och detaljerade innehav"
      />
      <Card className="box-margin">
        <h4>Varför denna fördelning?</h4>
        <p>{advice.declaration}</p>
      </Card>
      {advice.esgResult && <SuitabilityPdfLink esgResult={advice.esgResult} />}
      <InvestmentDisclaimer />
    </div>
  );
};
