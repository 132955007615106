import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSigningInfo, GENERIC_ERROR } from "../../data/signup";
import { parse } from "query-string";
import { SNACKBAR_TYPES, FlashContext, DocModal } from "@lysaab/ui-2";
import "./Docs.scss";
import { DocumentInformation, getLatestDocuments } from "../../data/documents";

function prettyNow() {
  const d = new Date();
  const yyyy = d.getFullYear();
  const mm = ("0" + (d.getMonth() + 1)).slice(-2);
  const dd = ("0" + d.getDate()).slice(-2);
  return `${yyyy}-${mm}-${dd}`;
}

const managementDocuments = ["legal/se/sv/customer-agreement.md"];
const depositorDocuments = ["legal/se/sv/depositor-information.md"];

const documents = [
  managementDocuments,
  ["legal/se/sv/placeringsriktlinjer.md"],
  ["legal/se/sv/account-terms.md"],
  [
    "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument.md",
    "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument-v-2024-1.md",
  ],
  ["legal/se/sv/bästa-orderutförande-1.md"],
  [
    "legal/se/sv/information-om-lysa-v-2021-2.md",
    "legal/se/sv/information-om-lysa-v-2021-1.md",
    "legal/se/sv/information-om-lysa-v-2022-1.md",
    "legal/se/sv/information-om-lysa-v-2023-1.md",
  ],
  ["legal/se/sv/depåavtal-1.md"],
  depositorDocuments,
];

interface Props {
  information: DocumentInformation[];
}

export const Docs = ({ information }: Props) => {
  const pushFlash = useContext(FlashContext).pushFlash;
  const location = useLocation();
  const [varValues, setVarValues] = useState({});
  const [showDoc, setShowDoc] = useState<string>();

  const latestDocuments = useMemo(
    () => getLatestDocuments(information, ...documents),
    [information]
  );

  useEffect(() => {
    const params = parse(location.search);
    const signupId = params.creationToken;

    if (!signupId || typeof signupId !== "string") {
      return;
    }

    getSigningInfo(signupId)
      .then((info) => {
        if (!info.summary) {
          return;
        }
        setVarValues({
          SIGNING_DATE: prettyNow(),
          COMPANY_NAME: info.name,
          ACCOUNT_NAME: "Lysakonto 1",
          CUSTOMER_NAME: info.name,
          STOCKS: info.summary.takenRisk,
          BONDS: 100 - info.summary.takenRisk,
          INVESTMENT_TYPE: info.summary.investmentType,
        });
      })
      .catch(() => {
        pushFlash({ type: SNACKBAR_TYPES.ERROR, text: GENERIC_ERROR });
      });
  }, [location, pushFlash]);

  return (
    <div className="docs-page">
      <DocModal
        onRequestClose={() => setShowDoc(undefined)}
        active={!!showDoc}
        modalAnnouncement={""}
        docList={latestDocuments}
        docName={showDoc}
        variableValues={varValues}
      />
      <button
        className="as-link"
        onClick={() => {
          setShowDoc(getLatestDocuments(information, managementDocuments)?.[0]);
        }}
      >
        Villkor och avtal
      </button>
      <button
        className="as-link"
        onClick={() => {
          setShowDoc(getLatestDocuments(information, depositorDocuments)?.[0]);
        }}
      >
        Insättningsgaranti
      </button>
    </div>
  );
};
