import { LysaLinkButton } from "@lysaab/ui-2";
import { useState } from "react";
import { Modal } from "../../../../../../components/modal/Modal";

export const TaxonomyQuestionInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <LysaLinkButton onClick={() => setIsOpen(true)}>
        Läs mer om Taxonomin
      </LysaLinkButton>
      <Modal
        showModal={isOpen}
        onClose={() => setIsOpen(false)}
        header="Taxonomin"
        closeOnOverlayClick
      >
        <p>
          Ny EU-reglering ställer krav på att kunder ska tillfrågas om sina
          hållbarhetspreferenser. Det är mot denna bakgrund som Lysa frågar dig
          om du önskar att dina investeringar ska göras i miljömässigt hållbara
          investeringar enligt EU:s Taxonomi, och huruvida denna typ av
          investering ska ske med en viss minsta andel.
        </p>
        <p>
          Taxonomin är ett relativt nytt EU-regelverk som definierar
          miljömässigt hållbar ekonomiska aktiviteter, enligt ett antal särskilt
          angivna kriterier. För att en ekonomisk aktivitet ska anses
          miljömässigt hållbar enligt förordningen (vara taxonomiförenlig) ska
          den väsentligt bidra till minst ett av sju miljömål (tex begränsning
          av klimatförändringar och omställning till cirkulär ekonomi). Utöver
          det får inte aktiviteten väsentligt skada något av de andra miljömålen
          och måste utföras i överensstämmelse med så kallade
          minimiskyddsåtgärder.
        </p>
        <p>
          ”EU-taxonomin är dock endast delvis klar och rapporteringsskyldigheten
          är fortfarande begränsad. Detta medför att de flesta sparprodukter
          inom en överskådlig framtid väntas redovisa synnerligen låga nivåer av
          taxonomiförenliga aktiviteter.
        </p>
      </Modal>
    </>
  );
};
