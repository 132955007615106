import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { names } from "../../../../names";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import { COMPANY_VALIDATION_REASON, Company } from "../../../../data/bankid";
import {
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  TextInput,
  OrgValidator,
  RequiredValidator,
  LysaFormRef,
  Form,
  Spinner,
} from "@lysaab/ui-2";
import { InvalidCompanyValidator } from "../../../../components/InvalidCompanyValidator";
import { FatcaCheckbox } from "./FatcaCheckbox";
import AnimateHeight from "react-animate-height";
import { validateCompany } from "../../../../data/signup";

import "./CompanySelection.scss";
import { TaxResidencyCheckbox } from "./TaxResidencyCheckbox";

export const REASON_ERRORS = {
  // Could be that they're already a customer for example
  [COMPANY_VALIDATION_REASON.NOT_AVAILABLE]:
    "Företaget kan tyvärr inte bli kund hos oss. Kontakta oss för mer " +
    "info.",
  [COMPANY_VALIDATION_REASON.INVALID_ID]:
    "Organisationsnumret är inte giltigt.",
  // Foreign authorized signatories (no Swedish pnr). Or, we don't have
  // complete information about the company owner structure. Or, some
  // other reason we can't perform an authorized signatory
  [COMPANY_VALIDATION_REASON.INCOMPLETE_SIGNEES]:
    "Företaget kan tyvärr inte bli kund hos oss. Kontakta oss för mer " +
    "info.",
  [COMPANY_VALIDATION_REASON.NO_VALID_OWNERS]:
    "Företaget har inga uppgifter om registrerad verklig huvudman. " +
    "Detta kan bero på att företaget är nytt.",
  [COMPANY_VALIDATION_REASON.FOREIGN_OWNER]:
    "En eller flera av företagets ägare saknar svenskt personnummer",
  [COMPANY_VALIDATION_REASON.CONTACT_US]:
    "Vår digitala process kan tyvärr inte hantera det här företaget. " +
    "Kontakta oss så hjälper vi er.",
};

const manualOrgValidators = [
  new OrgValidator("Felaktigt organisationsnummer"),
  new RequiredValidator("Fyll i organisationsnummer"),
  new InvalidCompanyValidator(
    "Tyvärr kan inte enskilda näringsverksamheter bli kunder hos oss"
  ),
];

function normalizeCompanyId(companyId: string) {
  return companyId.replace(/ |-/g, "");
}

export const CompanySelection = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const formRef = useRef<LysaFormRef>();
  const tinRef = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    contextState.selectedCompany
  );
  const [isManualSelected, setIsManualSelected] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  const hasCompanies = contextState.companies?.length > 0;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      hasCompanies &&
      !isManualSelected &&
      (!selectedCompany || selectedCompany.disabled)
    ) {
      setError("Du måste välja ett företag för att kunna gå vidare");

      return;
    }

    if (formRef.current?.isValid && contextState.signupId) {
      if (isManualSelected || !hasCompanies) {
        setLoading(true);

        validateCompany(normalizeCompanyId(companyId), contextState.signupId)
          .then((response) => {
            if (response.disabled && response.reason) {
              setLoading(false);
              setError(REASON_ERRORS[response.reason]);
            } else {
              updateContext({ selectedCompany: response });
              history.push(names.BENEFICIAL_OWNERS);
            }
          })
          .catch(() => {
            setLoading(false);
            setError("Något gick fel. Försök igen senare");
          });
      } else {
        updateContext({ selectedCompany });
        history.push(names.BENEFICIAL_OWNERS);
      }
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <div className="company-selection">
        {hasCompanies && (
          <div className="company-list">
            <h2>Välj företag</h2>
            <ul>
              {contextState.companies.map((company) => (
                <li
                  className={
                    "card company-list-item" +
                    (company.reason ? " disabled-company" : "")
                  }
                  onClick={() => {
                    setError("");
                    setIsManualSelected(false);
                    setSelectedCompany(company);
                  }}
                  key={company.companyId}
                >
                  <div className="flex-container">
                    <span>
                      <span className="company-name">{company.name}</span>
                      <span className="company-id">
                        {"(" + company.companyId + ")"}
                      </span>
                    </span>
                    {selectedCompany?.companyId !== company.companyId ||
                    selectedCompany?.disabled ? (
                      <Button
                        variant="secondary"
                        size="small"
                        className="select-button"
                        disabled={company.disabled}
                        label="Välj"
                      />
                    ) : (
                      <Button
                        size="small"
                        className="select-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setError("");
                          setSelectedCompany(undefined);
                        }}
                        label="Valt"
                      />
                    )}
                  </div>

                  <AnimateHeight
                    duration={300}
                    height={
                      selectedCompany?.companyId === company.companyId &&
                      company.reason
                        ? "auto"
                        : 0
                    }
                    animateOpacity
                  >
                    <div className="inner-animate-wrapper">
                      {company.reason && (
                        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                          {REASON_ERRORS[company.reason]}
                        </Snackbar>
                      )}
                    </div>
                  </AnimateHeight>
                </li>
              ))}
              <li
                className="card company-list-item manual-selection"
                onClick={() => {
                  setError("");
                  setIsManualSelected(true);
                  setSelectedCompany(undefined);
                }}
              >
                <p>
                  Finns inte ditt företag i listan? Skriv in
                  organisationsnummret i rutan nedan
                </p>
                <div className="flex-container">
                  <div>
                    <p>
                      Finns inte ditt företag i listan? Skriv in
                      organisationsnummret i rutan nedan
                    </p>
                    <div
                      className="click-catcher"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {isManualSelected ? (
                        <TextInput
                          label="Organisationsnummer"
                          validators={manualOrgValidators}
                          value={companyId}
                          onChange={(value) => {
                            setCompanyId(value);
                            setError("");
                            if (value.length > 0) {
                              setIsManualSelected(true);
                            }
                          }}
                          placeholder="NNNNNN-NNNN"
                          key={"with-validator"}
                          ref={tinRef}
                        />
                      ) : (
                        <TextInput
                          label="Organisationsnummer"
                          value={companyId}
                          onChange={(value) => {
                            setCompanyId(value);
                            if (value.length > 0) {
                              setError("");
                              setIsManualSelected(true);
                              setSelectedCompany(undefined);
                              // TODO: Remove this entire TinInput
                              //   when we can dynamically update
                              //   the validator list
                              setTimeout(() => tinRef.current?.focus());
                            }
                          }}
                          placeholder="NNNNNN-NNNN"
                          key={"without-validator"}
                        />
                      )}
                    </div>
                  </div>
                  {!isManualSelected ? (
                    <Button
                      variant="secondary"
                      size="small"
                      className="select-button"
                      label="Välj"
                    />
                  ) : (
                    <Button
                      size="small"
                      className="select-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setError("");
                        setIsManualSelected(false);
                      }}
                      label="Valt"
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
        )}
        {!hasCompanies && (
          <div>
            <h2>Välj företag</h2>
            <p>
              Skriv in organisationsnummret för företaget du vill ska bli kund
            </p>
            <TextInput
              label="Organisationsnummer"
              validators={manualOrgValidators}
              value={companyId}
              onChange={(value) => {
                setCompanyId(value);
                setError("");
              }}
              placeholder="NNNNNN-NNNN"
            />
          </div>
        )}

        <TaxResidencyCheckbox />
        <FatcaCheckbox />

        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            {error}
          </Snackbar>
        )}

        <Button block type="submit" label="Gå vidare" />
      </div>
    </Form>
  );
};
