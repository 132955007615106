import { useCallback, useRef, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import * as Sentry from "@sentry/react";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  FlashContext,
  SNACKBAR_TYPES,
  AllocationBar,
  InvestmentType,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { CompanyModel } from "../../../models/company";
import {
  save,
  SaveRequest,
  refreshCompanySignupData,
} from "../../../data/signup";

import "./Summary.scss";
import { PepType, PepRequest } from "@lysaab/lysa-pep";
import { useAdvice } from "../../../models/AdviceContext";

function sanitizeSensitivePepData(pep: PepRequest) {
  return {
    ...pep,
    ownerName: pep.ownerName ? "REDACTED" : undefined,
    nameOfPEP: pep.nameOfPEP ? "REDACTED" : undefined,
    birthdayOfPEP: pep.birthdayOfPEP ? "REDACTED" : undefined,
    tin: pep.tin ? "REDACTED" : undefined,
    role: pep.role ? "REDACTED" : undefined,
    governmentCompany: pep.governmentCompany ? "REDACTED" : undefined,
    governmentCompanyRole: pep.governmentCompanyRole ? "REDACTED" : undefined,
    internationalOrganisation: pep.internationalOrganisation
      ? "REDACTED"
      : undefined,
    internationalOrganisationRole: pep.internationalOrganisationRole
      ? "REDACTED"
      : undefined,
    militaryRank: pep.militaryRank ? "REDACTED" : undefined,
  };
}

function pepType(type: PepType | undefined) {
  switch (type) {
    case PepType.NOT_PEP:
      return "Inte PEP";
    case PepType.COLLEAGUE:
      return "Relation till PEP";
    case PepType.ME:
      return "Är själv PEP";
    case PepType.RELATIVE:
      return "Relation till PEP";
    default:
      return "Inte PEP";
  }
}

export const Summary = () => {
  const { state: contextState, clearContext } =
    useContext(CompanySignupContext);
  const advice = useAdvice();
  const history = useHistory();
  const pushFlash = useContext(FlashContext).pushFlash;
  const [saveData, setSaveData] = useState<SaveRequest>();
  const [registratorIdentifier, setRegistratorIdentifier] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    // This is a navigation guard - if someone goes straight to this
    // page without a proper state in the context, we redirect to the
    // first page of the story.
    // If we have `saveData` we have already been past this, and don't
    // need to redirect again. This extra check is to be able to clear
    // signupId from the context state in preparation for the agreements
    // page.
    if (!contextState.signupId && !saveData) {
      history.replace(names.HOME);
      return;
    }
  }, [contextState.signupId, history, saveData]);

  useEffect(() => {
    if (!contextState.signupId) {
      return;
    }

    const data = CompanyModel.getSaveData(
      contextState,
      advice.input,
      advice.result
    );

    if (!data) {
      pushFlash({
        type: SNACKBAR_TYPES.ERROR,
        text: "Saknar data. Du måste tyvärr börja om 😞",
      });

      history.push(names.HOME);
      return;
    }

    setSaveData(data);
  }, [history, contextState, pushFlash, advice.input, advice.result]);

  useEffect(() => {
    if (!contextState.signupId || !contextState?.selectedCompany?.companyId) {
      return;
    }

    refreshCompanySignupData(
      contextState.selectedCompany.companyId,
      contextState.signupId
    )
      .then((response) => {
        if (response.contactInformation.name) {
          setRegistratorIdentifier(response.contactInformation.name);
        } else {
          setRegistratorIdentifier(contextState.email);
        }
      })
      .catch(() => {
        setRegistratorIdentifier(contextState.email);
      });
  }, [
    contextState.signupId,
    contextState.selectedCompany?.companyId,
    contextState.email,
  ]);

  const formRef = useRef<LysaFormRef>();

  const next = useCallback(() => {
    if (!contextState.signupId || !saveData || saving) {
      return;
    }

    setSaving(true);

    if (
      saveData.owners.designated?.pep &&
      !saveData.owners.designated?.pep.type
    ) {
      Sentry.addBreadcrumb({
        category: "PEP",
        message: "PEP defined, but no type for designated owner",
      });
    }
    if (
      saveData.owners?.registered?.some((owner) => owner.pep && !owner.pep.type)
    ) {
      Sentry.addBreadcrumb({
        category: "PEP",
        message: "PEP defined, but no type for some registered owner",
      });
    }

    // TODO: Progress indicator
    save(contextState.signupId, saveData)
      .then(() => {
        history.push(
          names.AGREEMENT + `?creationToken=${contextState.signupId}`
        );

        // This is all we can do to "prevent" using the browser
        // "back" button from the next step
        // This has to be after updating history, otherwise the access
        // guard(s) will redirect us
        clearContext();
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          const pepData: {
            registered?: PepRequest[];
            designated?: PepRequest;
          } = {};
          if (saveData.owners.designated?.pep) {
            pepData["designated"] = sanitizeSensitivePepData(
              saveData.owners.designated.pep
            ) as PepRequest;
          }
          if (saveData.owners.registered) {
            pepData["registered"] = saveData.owners.registered.map((owner) =>
              sanitizeSensitivePepData(owner.pep)
            ) as PepRequest[];
          }
          scope.setContext("pepData", pepData);
          Sentry.captureException(error);
        });
        pushFlash({
          type: SNACKBAR_TYPES.ERROR,
          text: "Ursäkta, något gick fel. Kontakta oss gärna, eller försök igen lite senare.",
        });
      })
      .finally(() => {
        setSaving(true);
      });
  }, [
    contextState.signupId,
    history,
    saveData,
    clearContext,
    pushFlash,
    saving,
  ]);

  interface Owner {
    name: string;
    pep?: PepRequest;
  }

  let owners: Owner[] = [];

  if (
    contextState.registeredOwners &&
    contextState.registeredOwners.length > 0
  ) {
    owners = contextState.registeredOwners.map((owner) => {
      return {
        name: owner.name,
        pep: owner.pep,
      };
    });
  } else if (contextState.designatedOwner) {
    owners.push(contextState.designatedOwner);
  }

  if (typeof saveData === "undefined") {
    return null;
  }

  return (
    <div className="company-signup-summary">
      <h2>Summering</h2>
      <Card>
        <dl>
          <dt>Registreringen påbörjades av</dt>
          <dd>
            {registratorIdentifier}
            <br />({contextState.email})
          </dd>
          <dt>Kontotyp</dt>
          <dd>Värdepapperskonto (VP)</dd>
          <dt className="target">Målfördelning</dt>
          <dd className="risk-indicator-container">
            <AllocationBar
              risk={saveData.advice.takenRisk}
              shouldFormatNumber
            />
          </dd>
          <dt>Investeringsfokus</dt>
          <dd>
            {saveData?.advice.investmentType === InvestmentType.SUSTAINABLE
              ? "Hållbar"
              : "Bred"}
          </dd>
        </dl>
        <br className="clearFix" />

        <h5>Användare</h5>
        <table>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Typ av användare</th>
            </tr>
          </thead>
          <tbody>
            {contextState.users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.admin ? "Administratör" : "Begränsad"}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h5>Verklig huvudman</h5>
        <table>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Pep</th>
            </tr>
          </thead>
          <tbody>
            {owners.map((owner, i) => (
              <tr key={i}>
                <td>{owner.name}</td>
                <td>{pepType(owner?.pep?.type)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <Card>
        <p>
          Du är nu klar att gå vidare till signering av avtalet. Från nästa steg
          kommer du inte kunna gå tillbaka och ändra några uppgifter. Genom att
          gå vidare bekräftar du att alla uppgifter stämmer och att signering
          kan påbörjas.
        </p>
      </Card>

      <Form lysaFormRef={formRef} onSubmit={next}>
        <Button block type="submit" disabled={saving} label="Gå vidare" />
      </Form>
    </div>
  );
};
