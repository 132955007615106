import {
  NewIcon,
  RadioGroupCard,
  RadioGroupCardItem,
  RequiredValidator,
} from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import { ExpandableContent } from "../../../../components/expandableContent/ExpandableContent";
import { SustainabilityImportance } from "../../../../data/investments";
import { useAdvice } from "../../../../models/AdviceContext";

const sustainabilityAltMessages: Record<SustainabilityImportance, string> = {
  [SustainabilityImportance.NOT_IMPORTANT]: "Så brett som möjligt",
  [SustainabilityImportance.IMPORTANT]: "Så hållbart som möjligt",
};

const alternativeDescriptionMessages: Record<SustainabilityImportance, string> =
  {
    [SustainabilityImportance.NOT_IMPORTANT]:
      "Investera med maximal riskspridning i tusentals bolag över hela världen.",
    [SustainabilityImportance.IMPORTANT]:
      "Investera i utvalda bolag med fokus på samhälls- och miljöansvar.",
  };

const alternativeExpandDescriptionCorpNotImportantMessage = {
  item1:
    "Företagets pengar investeras i över 7 000 små och stora aktiebolag och 5 000 ränteinstrument.",
};

const alternativeExpandDescriptionCorpImportantMessage = {
  item1:
    "Färre bolag och lägre riskspridning till förmån för fokus på hållbarhet.",
  item2:
    "Exkluderar bolag som är verksamma inom bl.a. spel, tobak och fossila bränslen.",
};

const alternativeExpandDescriptionCorpMessages = {
  [SustainabilityImportance.NOT_IMPORTANT]:
    alternativeExpandDescriptionCorpNotImportantMessage,
  [SustainabilityImportance.IMPORTANT]:
    alternativeExpandDescriptionCorpImportantMessage,
};

const alternativeIcon: Record<SustainabilityImportance, JSX.Element> = {
  [SustainabilityImportance.NOT_IMPORTANT]: <NewIcon.Earth size={24} />,
  [SustainabilityImportance.IMPORTANT]: <NewIcon.Sustainable size={24} />,
};

export const SustainabilityQuestion: FunctionComponent = () => {
  const advice = useAdvice();

  const alternatives: RadioGroupCardItem<
    SustainabilityImportance | undefined
  >[] = Object.values(SustainabilityImportance).map((key) => ({
    header: sustainabilityAltMessages[key],
    description: alternativeDescriptionMessages[key],
    expandableContent: (
      <ExpandableContent>
        <ul>
          {Object.values(alternativeExpandDescriptionCorpMessages[key]).map(
            (item, index) => (
              <li key={`selection-expandable-content-${index}`}>{item}</li>
            )
          )}
        </ul>
      </ExpandableContent>
    ),
    logo: <>{alternativeIcon[key]}</>,
    value: key,
  }));

  return (
    <RadioGroupCard
      legend={<h2>Hur vill företaget investera?</h2>}
      value={advice.input.sustainability}
      alternatives={alternatives}
      onChange={(sustainability) => advice.setInput({ sustainability })}
      validators={[new RequiredValidator("Du måste välja ett alternativ")]}
    />
  );
};
