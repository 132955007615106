import React, { FunctionComponent } from "react";
import { Button, Icon } from "@lysaab/ui-2";
import { Signer } from "../../../data/signup";

import "./AgreementSigner.scss";

interface AgreementSignerProps {
  signer: Signer;
  onClick: (user: { userId: number }) => void;
  buttonText: string;
}

export const AgreementSigner: FunctionComponent<AgreementSignerProps> = ({
  signer,
  onClick,
  buttonText,
}) => {
  if (signer.confirmed) {
    return (
      <li key={signer.id} className="agreement-signer">
        <div className="name">{signer.name}</div>
        <div className="button-status">
          <span className="signer-status">
            <Icon.Checkmark />
          </span>
        </div>
      </li>
    );
  } else {
    return (
      <li key={signer.id} className="agreement-signer">
        <div className="name">{signer.name}</div>
        <div className="button-status">
          <Button
            size="small"
            onClick={() => {
              onClick({ userId: signer.id });
            }}
            label={buttonText}
          />
        </div>
      </li>
    );
  }
};
