import { LysaLinkButton } from "@lysaab/ui-2";
import React, { useCallback, VoidFunctionComponent } from "react";
import { useSuitabilityExport } from "./useSuitabilityExport";
import { EsgResultResponse } from "../data/investments";

interface Props {
  esgResult: EsgResultResponse["esgResult"];
}

export const SuitabilityPdfLink: VoidFunctionComponent<Props> = ({
  esgResult,
}) => {
  const [exportPdf] = useSuitabilityExport({ esgResult });

  const handleClick = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();
      exportPdf();
    },
    [exportPdf]
  );

  return (
    <LysaLinkButton onClick={(ev: React.MouseEvent) => handleClick(ev)}>
      Ladda ner ert investeringsförslag
    </LysaLinkButton>
  );
};
