import React, { useContext } from "react";
import { Card, Button } from "@lysaab/ui-2";
import { OwnerTable } from "./OwnerTable";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import { useHistory } from "react-router-dom";
import { names } from "../../../../names";

import "./RegisteredOwners.scss";

export const RegisteredOwners = () => {
  const { state: contextState } = useContext(CompanySignupContext);
  const history = useHistory();
  const next = () => {
    const fatcaCheckNeeded = contextState.registeredOwners.some(
      (owner) => owner.taxDomicile === "US" || owner.citizenship === "US"
    );

    if (fatcaCheckNeeded) {
      history.push(names.FATCA_CHECK);
    } else {
      history.push(names.USERS);
    }
  };

  return (
    <div className="registered-owners">
      <h2>Verklig huvudman</h2>
      <Card>
        <p>Bekräfta uppgifterna nedan om bolagets verkliga huvudman.</p>

        {contextState.registeredOwners.map((owner) => (
          <OwnerTable owner={owner} key={owner.id} />
        ))}

        <p className="wrong-info">
          Stämmer inte uppgifterna ovan? Vi hämtar uppgifterna direkt från
          Bolagsverket. Vänligen kontrollera att uppgifterna som finns
          registrerade hos Bolagsverket stämmer. Kontakta gärna oss om du har
          vidare frågor.
        </p>
      </Card>
      <Button
        block
        type="button"
        onClick={next}
        label="Bekräfta och gå vidare"
      />
    </div>
  );
};
