import React from "react";
import { Alternative, InvestmentType } from "@lysaab/ui-2";
import { createContext, FunctionComponent, useContext, useState } from "react";
import {
  AccountQuestions,
  NeedEarlierProbability,
  PAIImportance,
  RiskImportance,
  RiskPropensity,
  RiskReaction,
  SavingsHorizonLength,
  SFDRImportance,
  SustainabilityImportance,
  SustainabilityPreference,
  TaxonomyImportance,
} from "../data/investments";

interface AdviceContextProps {
  input: AdviceInput;
  result: AdviceResult;
  setInput(newInput: Partial<AdviceInput>): void;
  setResult(newResult: Partial<AdviceResult>): void;
}

const AdviceContext = createContext<AdviceContextProps | undefined>(undefined);

export interface AdviceState {
  input: AdviceInput;
  result: AdviceResult;
}

export type AdviceInput = Partial<AccountQuestions> & {
  /** Risk */
  riskImportance?: Alternative<RiskImportance>;
  riskReaction?: Alternative<RiskReaction>;
  riskPropensity?: Alternative<RiskPropensity>;

  /** Horizon */
  savingsHorizon?: SavingsHorizonLength;
  needEarlier?: NeedEarlierProbability;

  //** ESG */
  sustainability?: SustainabilityImportance;
  sfdr?: SFDRImportance;
  taxonomy?: TaxonomyImportance;
  pai?: PAIImportance;
  sustainabilityPreference?: SustainabilityPreference;
};

export interface AdviceResult {
  /** Investment type */
  investmentType?: InvestmentType;

  /** Advice */
  takenRisk?: number;
  advicedRisk?: number;
}

interface AdviceProviderProps {
  children: React.ReactNode;
}

const defaultState: AdviceState = {
  input: {},
  result: {},
};

export const AdviceProvider: FunctionComponent<AdviceProviderProps> =
  React.memo(({ children }) => {
    const [{ input, result }, setState] = useState<AdviceState>(defaultState);

    function setInput(newInput: Partial<AdviceInput>) {
      setState((prev) => ({
        input: { ...prev.input, ...newInput },
        result: {},
      }));
    }

    function setResult(newResult: Partial<AdviceResult>) {
      setState((prev) => ({
        input: { ...prev.input },
        result: { ...prev.result, ...newResult },
      }));
    }

    return (
      <AdviceContext.Provider value={{ input, result, setInput, setResult }}>
        {children}
      </AdviceContext.Provider>
    );
  });

export const useAdvice = (): AdviceContextProps => {
  const context = useContext(AdviceContext);

  if (!context) {
    throw new Error("useAdvice must be used within a AdviceProvider");
  }

  return context;
};
