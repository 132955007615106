import React, { useCallback, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  RadioGroup,
  RequiredValidator,
  Alternative,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import AnimateHeight from "react-animate-height";
import {
  isInvalidLiquidity,
  isInvalidRisk,
  isValidSituation,
} from "../../../models/situation";

const liquidityAlternatives: Alternative<"more" | "less">[] = [
  {
    text: "Mer än 6 månader",
    value: "more",
  },
  {
    text: "Mindre än 6 månader",
    value: "less",
  },
];

const riskAlternatives: Alternative<"yes" | "no">[] = [
  {
    text: "Nej",
    value: "no",
  },
  {
    text: "Ja",
    value: "yes",
  },
];

export const Situation = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const history = useHistory();

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  const formRef = useRef<LysaFormRef>();

  const persistData = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!isValidSituation(contextState)) {
        return;
      }

      if (formRef.current && formRef.current.isValid) {
        history.push(names.HORIZON);
      }
    },
    [contextState, history]
  );

  return (
    <div className="company-signup-situation">
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <h2>Ekonomisk situation</h2>
        <Card>
          <RadioGroup
            header="Hur många månader kommer företagets nuvarande kassa att täcka företagets löpande utgifter?"
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
            onChange={(alternative) =>
              updateContext({ situationLiquidity: alternative })
            }
            value={contextState.situationLiquidity}
            alternatives={liquidityAlternatives}
          />

          <AnimateHeight
            duration={300}
            height={isInvalidLiquidity(contextState) ? "auto" : 0}
            animateOpacity
          >
            <div className="signup-experience-warning">
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                Om företagets nuvarande kassa inte minst täcker utgifter för de
                kommande sex månaderna anser vi inte att det är lämpligt att
                investera med Lysa. Du är varmt välkommen att kontakta oss för
                mer information.
              </Snackbar>
            </div>
          </AnimateHeight>

          <RadioGroup
            header="Finns det en risk att företaget inom en snar framtid kan hamna på obestånd?"
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
            onChange={(alternative) =>
              updateContext({ situationRisk: alternative })
            }
            value={contextState.situationRisk}
            alternatives={riskAlternatives}
          />

          <AnimateHeight
            duration={300}
            height={isInvalidRisk(contextState) ? "auto" : 0}
            animateOpacity
          >
            <div className="signup-experience-warning">
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                Om det finns en risk att företaget hamnar på obestånd anser vi
                inte att det är lämpligt att investera med Lysa. Du är varmt
                välkommen att kontakta oss för mer information.
              </Snackbar>
            </div>
          </AnimateHeight>
        </Card>
        <Button block type="submit" label="Gå vidare" />
      </Form>
    </div>
  );
};
