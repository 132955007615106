import { MaxValidator, MinValidator, MoneyInput } from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import { MAX_INVESTMENT, MIN_INVESTMENT } from "./EditAllocation";

const MIN_INVESTMENT_VALIDATOR_TEXT =
  "Exempelberäkningarna hanterar inte investeringar på mindre än 1000 SEK";
const MAX_INVESTMENT_VALIDATOR_TEXT =
  "Exempelberäkningarna hanterar inte investeringar på mer än 100 miljarder SEK";

interface ExampleInvestmentEditorProps {
  investment: number;
  onInvestmentChange: (num: number) => void;
}
export const ExampleInvestmentEditor: FunctionComponent<
  ExampleInvestmentEditorProps
> = ({ investment, onInvestmentChange }) => {
  return (
    <>
      <h4>Exempelinvestering</h4>
      <MoneyInput
        currency="SEK"
        value={investment.toString()}
        onChange={(value) =>
          onInvestmentChange(value ? parseInt(value, 10) : 0)
        }
        placeholder="10 000"
        validators={[
          new MinValidator(MIN_INVESTMENT, MIN_INVESTMENT_VALIDATOR_TEXT),
          new MaxValidator(MAX_INVESTMENT, MAX_INVESTMENT_VALIDATOR_TEXT),
        ]}
      />
    </>
  );
};
