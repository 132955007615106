import { LysaLinkButton } from "@lysaab/ui-2";
import { useState } from "react";
import { Modal } from "../../../../../../components/modal/Modal";

export const SFDRQuestionInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <LysaLinkButton onClick={() => setIsOpen(true)}>
        Läs mer om Disclosureförordningen (SFDR)
      </LysaLinkButton>
      <Modal
        showModal={isOpen}
        onClose={() => setIsOpen(false)}
        header="Disclosureförordningen (SFDR)"
        closeOnOverlayClick
      >
        <p>
          Ny EU-reglering ställer krav på att kunder ska tillfrågas om sina
          hållbarhetspreferenser. Det är mot denna bakgrund som Lysa frågar dig
          om du önskar att dina investeringar ska göras i hållbara investeringar
          enligt disclosureförordningen (SFDR), och huruvida denna typ av
          investering ska ske med en viss minsta andel.
        </p>
        <p>
          SFDR definierar, på en övergripande nivå, vad som utgör en “hållbar
          investering” från ett miljömässigt eller socialt perspektiv.
          Definitionen av hållbara investeringar är mer övergripande än den som
          följer av Taxonomin, och omfattar även sociala hållbarhetsmål. Som
          exempel på hållbara investeringar enligt SFDR kan nämnas investeringar
          i linje med FN:s globala hållbarhetsmål.
        </p>
      </Modal>
    </>
  );
};
