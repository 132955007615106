import { useCallback, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  EmailInput,
  Form,
  EmailValidator,
  LysaFormRef,
  RequiredValidator,
  BankAccountInput,
  BankAccountNumberValidator,
  TelephoneInput,
  Checkbox,
  DocModalLink,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import "./Details.scss";

export const Details = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const history = useHistory();

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  useEffect(() => {
    const users = contextState.users;

    if (!users || users.length < 1) {
      return;
    }

    updateContext((ctx) => {
      if (ctx.detailsEmail) {
        return ctx;
      } else {
        return { ...ctx, detailsEmail: users[0].email };
      }
    });
  }, [contextState.users, updateContext]);

  const formRef = useRef<LysaFormRef>();

  const persistData = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formRef.current && formRef.current.isValid) {
        history.push(names.PEP);
      }
    },
    [formRef, history]
  );

  return (
    <div className="company-signup-details">
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <h2>Kontaktuppgifter</h2>
        <Card>
          <TelephoneInput
            label="Telefonnummer"
            value={contextState.detalsPhoneNumber ?? ""}
            placeholder="Telefonnummer"
            onChange={(value) => updateContext({ detalsPhoneNumber: value })}
          />
          <EmailInput
            label="E-post"
            value={contextState.detailsEmail ?? ""}
            placeholder="Företagsemail"
            onChange={(value) => updateContext({ detailsEmail: value })}
            validators={[
              new EmailValidator("Felaktig emailadress"),
              new RequiredValidator("Fyll i emailadress"),
            ]}
          />
        </Card>

        <h2>Uttags- och avgiftskonto</h2>
        <p>
          Ange vilket konto ni vill ha som förvalt uttagskonto. Detta konto
          kommer även att debiteras med portfölj&shy;förvaltningsavgiften.
        </p>
        <Card>
          <BankAccountInput
            label="Kontonummer"
            value={contextState.detailsBankAccount ?? ""}
            placeholder="Clearing- och kontonummer"
            onChange={(value) => updateContext({ detailsBankAccount: value })}
            validators={[
              new BankAccountNumberValidator("Felaktigt kontonummer"),
              new RequiredValidator("Fyll i kontonummer"),
            ]}
          />
          <p>
            Lösningen bygger på säker autogiroinfrastruktur. Om ni vill kan ni
            även enkelt göra insättningar från kontot till Lysa.
          </p>
          <DocModalLink
            document="legal/se/sv/villkor-för-insättning-via-autogiro.md"
            modalAnnouncement="Visar en modal med dokument"
            className="autogiro-terms"
          >
            Villkor för autogiro
          </DocModalLink>
          <Checkbox
            alternative={{
              text: "Jag har läst och godkänner villkoren",
              value: "-",
            }}
            checked={!!contextState.detailsTermsCheckbox}
            onChange={() =>
              updateContext({
                detailsTermsCheckbox: !contextState.detailsTermsCheckbox,
              })
            }
            validators={[
              new RequiredValidator("Du måste läsa och godkänna villkoren"),
            ]}
          />
        </Card>

        <Button block type="submit" label="Gå vidare" />
      </Form>
    </div>
  );
};
