import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Based off of
// https://gist.github.com/eyecatchup/d210786daa23fd57db59634dd231f341

// native smooth scrolling for Chrome, Firefox & Opera
// @see: https://caniuse.com/#feat=css-scroll-behavior
const nativeSmoothScrollTop = () => {
  window.scroll({
    behavior: "smooth",
    left: 0,
    top: 0,
  });
};

// polyfilled smooth scrolling for IE, Edge & Safari
const smoothScrollTop = (duration: number) => {
  const element = document.scrollingElement || document.documentElement,
    start = element.scrollTop,
    change = -start,
    startDate = +new Date();

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = parseInt(
      "" + easeInOutQuad(currentTime, start, change, duration)
    );
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = 0;
    }
  };

  animateScroll();
};

// detect support for the behavior property in ScrollOptions
const supportsNativeSmoothScroll =
  "scrollBehavior" in document.documentElement.style;

// smooth scrolling stub
const scrollTop = () => {
  if (supportsNativeSmoothScroll) {
    nativeSmoothScrollTop();
  } else {
    smoothScrollTop(600);
  }
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollTop();
  }, [pathname]);

  return null;
};
