import React, {
  useCallback,
  useRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  RadioGroup,
  Alternative,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { refreshCompanySignupData } from "../../../data/signup";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import AnimateHeight from "react-animate-height";
import { Question, ALTERNATIVE_TYPE } from "../../../components/Question";
import { isNotOwnMoney, isValidKyc } from "../../../models/kyc";

const yesNoAlternatives: Alternative<boolean>[] = [
  { text: "Ja", value: true },
  { text: "Nej", value: false },
];

const OWN_MONEY_TEXT = "Äger företaget pengarna som sätts in hos Lysa?";
const OWN_MONEY_ERROR_TEXT =
  "Som kund hos Lysa måste företaget äga de pengar som sätts in hos oss";
const SUBMIT_ERROR =
  "Du har ett fel i formuläret ovan som du måste korrigera innan du kan " +
  "gå vidare";

export const Kyc = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const history = useHistory();
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  useEffect(() => {
    const selectedCompany = contextState.selectedCompany;

    if (
      !selectedCompany ||
      (contextState.accountKyc && contextState.companyKyc) ||
      !contextState.signupId
    ) {
      return;
    }

    refreshCompanySignupData(
      selectedCompany.companyId,
      contextState.signupId
    ).then((company) => {
      updateContext({
        accountKyc: company.kyc.account,
        companyKyc: company.kyc.company,
      });
    });
  }, [
    contextState.accountKyc,
    contextState.companyKyc,
    contextState.selectedCompany,
    contextState.signupId,
    updateContext,
  ]);

  const formRef = useRef<LysaFormRef>();

  const persistData = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (
        isValidKyc(contextState) &&
        formRef.current &&
        formRef.current.isValid
      ) {
        history.push(names.EXPERIENCE);
      } else {
        setSubmitError(true);
      }
    },
    [contextState, formRef, history]
  );

  return (
    <div className="company-signup-kyc">
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <h2>Om företaget</h2>
        <Card>
          {contextState.companyKyc?.map((question, idx, questions) => (
            <Question
              key={question.id}
              type={question.type as ALTERNATIVE_TYPE}
              question={question.question}
              alternatives={question.alternatives}
              onChange={(newAlternatives) => {
                setSubmitError(false);
                const newQuestions = [...questions];
                newQuestions[idx].alternatives = newAlternatives;
                updateContext({ companyKyc: newQuestions });
              }}
            />
          ))}

          <div className="question-wrapper">
            <RadioGroup
              header={OWN_MONEY_TEXT}
              alternatives={yesNoAlternatives}
              onChange={(alternative) => {
                setSubmitError(false);
                updateContext({ ownMoney: alternative });
              }}
              value={contextState.ownMoney}
              validators={[
                new RequiredValidator("Du måste välja ett alternativ"),
              ]}
            />

            <AnimateHeight
              duration={300}
              height={isNotOwnMoney(contextState) ? "auto" : 0}
              animateOpacity
            >
              <div className="signup-experience-warning">
                <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                  {OWN_MONEY_ERROR_TEXT}
                </Snackbar>
              </div>
            </AnimateHeight>
          </div>
        </Card>

        <h2>Om företagets konto</h2>
        <Card>
          {contextState.accountKyc?.map((question, idx, questions) => (
            <Question
              key={question.id}
              type={question.type as ALTERNATIVE_TYPE}
              question={question.question}
              alternatives={question.alternatives}
              onChange={(newAlternatives) => {
                setSubmitError(false);
                const newQuestions = [...questions];
                newQuestions[idx].alternatives = newAlternatives;
                updateContext({ accountKyc: newQuestions });
              }}
            />
          ))}
        </Card>

        {submitError && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            {SUBMIT_ERROR}
          </Snackbar>
        )}

        <Button block type="submit" label="Gå vidare" />
      </Form>
    </div>
  );
};
