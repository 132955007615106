import { useRef, useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import * as Sentry from "@sentry/react";
import {
  Button,
  EmailInput,
  EmailValidator,
  RequiredValidator,
  Form,
  LysaFormRef,
  Card,
  Icon,
  DocModalLink,
  BankIDStatus,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { names } from "../../../names";
import {
  createGetFailedMessages,
  createGetMessages,
  createGetPendingMessages,
} from "../../../utils/identifyBankIdMessages";
import { stringify, parse } from "query-string";
import { Location } from "history";
import {
  BankIdIdentifyPollCompletedResponse,
  Bankid,
} from "../../../data/bankid";
import "./Identify.scss";
interface SearchParams {
  id?: string;
  t?: string;
  at?: string;
}

export const Identify = () => {
  const intl = useIntl();
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();
  const location = useLocation();

  const urlParams = getParamsFromUrl(location);

  const onComplete = useCallback(
    (response: BankIdIdentifyPollCompletedResponse) => {
      updateContext({
        companies: response.payload.companies,
        signupId: response.creationToken,
      });

      history.replace(names.COMPANY_SELECTION);
    },
    [history, updateContext]
  );

  const onPollError = useCallback(
    (error: unknown) => {
      Sentry.captureException(error, {
        extra: {
          bankId: "pollIdentifyError",
        },
      });
      history.replace(names.HOME);
    },
    [history]
  );

  const pollFnRaw = useMemo(() => {
    const orderRef = urlParams.orderRef;
    return orderRef ? () => Bankid.pollIdentify(orderRef) : undefined;
  }, [urlParams.orderRef]);
  const pollFn = useDisablePollInBackground(pollFnRaw);

  const qrCodePollFn = useCallback(
    () => Bankid.pollIdentifyQrCode(urlParams.orderRef),
    [urlParams.orderRef]
  );

  const {
    pollStatus,
    latestResponse,
    initiate,
    reset,
    qrCode,
    setOpenOnOtherDevice,
  } = useBankId({
    onComplete,
    onPollError,
    initPollFn: Bankid.initIdentify,
    qrCodePollFn,
    pollFn,
    // This is a bit ugly, but pragmatic since there's trouble mocking time in the current test setup
    pollInterval: process.env.NODE_ENV === "test" ? 10 : undefined,
  });

  const initIdentifyAndSyncToUrl = useCallback(
    (email: string) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        initiate(email)
          .then((response) => {
            if (response) {
              // This causes pollFn to be defined which starts the polling
              history.push(
                getUrlWithParams(
                  location,
                  response.orderRef,
                  response.autoStartToken
                )
              );
            }
          })
          .catch((error: unknown) => {
            Sentry.captureException(error, {
              extra: {
                bankId: "initIdentifyError",
              },
            });
            history.replace(names.HOME);
          });
      }
    },
    [pollStatus, initiate, history, location]
  );

  if (pollStatus === "PENDING" || pollStatus === "FAILED") {
    return (
      <BankIDStatus
        autoStartToken={urlParams.autoStartToken}
        qrCode={qrCode}
        setOpenOnOtherDevice={setOpenOnOtherDevice}
        getMessages={createGetMessages(intl)}
        getFailedMessages={createGetFailedMessages(intl)}
        getPendingMessages={createGetPendingMessages(intl)}
        response={latestResponse}
        retry={() => {
          if (contextState.email) {
            initIdentifyAndSyncToUrl(contextState.email);
          } else if (pollStatus === "FAILED") {
            history.replace(names.HOME);
            reset();
          }
        }}
        reset={() => {
          history.replace(names.HOME);
          reset();
        }}
      />
    );
  }

  return (
    <div className="company-signup-identify">
      <h1>Välkommen</h1>

      <p className="company-signup-ingress">
        När du blir kund hos Lysa och skapar ditt första företagskonto så öppnas
        automatiskt en <u>värdepappersdepå</u>. När du har slutfört alla steg
        kan du enkelt öppna en kapitalförsäkring och/eller fler konton.
      </p>

      <h2>Så här fungerar det</h2>

      <Card>
        <ol>
          <li>
            <Icon.Identity size={24} />
            <p>
              För att registrera ditt företag behöver du ha ett svenskt
              personnummer och kunna legitimera dig med BankID
            </p>
          </li>
          <li>
            <Icon.Bank size={24} />
            <p>
              Du behöver kunna ange ett kontonummer till extern bank för uttag
            </p>
          </li>
          <li>
            <Icon.Chart size={24} />
            <p>
              Du lämnar information om dig och företaget och vi tar fram ett
              investeringsförslag
            </p>
          </li>
          <li>
            <Icon.Sign size={24} />
            <p>Du signerar avtal och är klar att investera</p>
          </li>
        </ol>
      </Card>

      <div>
        <Form
          className="row-wrapper"
          lysaFormRef={formRef}
          onSubmit={(event) => {
            event.preventDefault();

            if (formRef.current?.isValid) {
              // This function is idempotent, so it's safe to call it multiple
              // times if the user spams the button
              initIdentifyAndSyncToUrl(contextState.email);
            }
          }}
        >
          <div className="item-wrapper">
            <EmailInput
              label="E-post"
              validators={[
                new EmailValidator("Felaktig emailadress"),
                new RequiredValidator("Fyll i emailadress"),
              ]}
              value={contextState.email}
              onChange={(email) => updateContext({ email })}
              placeholder="email@exempel.se"
            />

            <Button type="submit" block label="Logga in med BankID" />
          </div>
        </Form>
      </div>

      <div className="disclaimer">
        <p>
          Genom att fortsätta bekräftar jag att jag har tagit del av information
          om Lysas{" "}
          <DocModalLink
            document="legal/se/sv/lämplighetsbedömning.md"
            modalAnnouncement="Modal med dokumentet lämplighetsbedömning"
          >
            lämplighetsbedömning
          </DocModalLink>
          .
        </p>
      </div>
    </div>
  );
};

function getUrlWithParams(
  location: Location,
  orderRef: string | undefined,
  autoStartToken: string | undefined
) {
  const search = parse(location.search) as SearchParams;
  search.t = new Date().getTime().toString();
  search.id = orderRef;
  search.at = autoStartToken;

  return {
    pathname: names.HOME,
    search: stringify(search),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    time: search.t,
    orderRef: search.id,
    autoStartToken: search.at,
  };
}
