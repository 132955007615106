import { FunctionComponent } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import {
  isSustainabilityImportantSpecific,
  TaxonomyImportance,
} from "../../../../../data/investments";
import { TaxonomyQuestionInfoModal } from "./modals/TaxonomyQuestionInfoModal";
import { useAdvice } from "../../../../../models/AdviceContext";

export const taxonomyAltMessages: Record<TaxonomyImportance, string> = {
  [TaxonomyImportance.NONE]: "Ingen",
  [TaxonomyImportance.LEAST_25]: "Minst 25 %",
  [TaxonomyImportance.LEAST_50]: "Minst 50 %",
  [TaxonomyImportance.LEAST_75]: "Minst 75 %",
};

export const TaxonomyQuestion: FunctionComponent = () => {
  const advice = useAdvice();

  if (!isSustainabilityImportantSpecific(advice.input)) {
    return null;
  }

  const alternatives = Object.values(TaxonomyImportance).map((key) => ({
    text: taxonomyAltMessages[key],
    value: key,
  }));

  const value =
    typeof advice.input.taxonomy === "string"
      ? {
          text: taxonomyAltMessages[advice.input.taxonomy],
          value: advice.input.taxonomy,
        }
      : advice.input.taxonomy;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          <>
            Hur stor andel av företagets investeringar vill ni ska vara
            miljömässigt hållbara enligt EU:s Taxonomi för hållbara
            investeringar?
            <br />
            <p>
              <TaxonomyQuestionInfoModal />
            </p>
          </>
        }
        onChange={({ value }) => advice.setInput({ taxonomy: value })}
        value={value}
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
      />
    </Card>
  );
};
