import { FC, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export const ScrollIntoViewOnMount: FC<{
  ifQueryParam: string;
  afterDelay: number;
}> = ({ ifQueryParam, afterDelay }) => {
  const el = useRef<HTMLSpanElement>(null);
  const history = useHistory();

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    if (search.has(ifQueryParam)) {
      setTimeout(
        () => el.current?.scrollIntoView({ behavior: "smooth" }),
        afterDelay
      );
    }
  }, [afterDelay, history.location.search, ifQueryParam]);

  return <span ref={el} />;
};
