import { FC, JSX } from "react";
import { Slider, BaseValidator } from "@lysaab/ui-2";

interface Props {
  value: number;
  onChange: (value: number) => void;
  steps: number[];
  validators?: BaseValidator[];
  label: string | JSX.Element;
}

export const CustomStepsSlider: FC<Props> = ({
  value,
  onChange,
  steps,
  validators,
  label,
}) => {
  return (
    <Slider
      min={0}
      max={steps.length - 1}
      step={1}
      value={steps.indexOf(value || 0)}
      onChange={(value) => onChange(steps[value])}
      validators={validators}
      label={label}
    />
  );
};
