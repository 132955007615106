import {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  AllocationBar,
  Card,
  Snackbar,
  SNACKBAR_TYPES,
  Icon,
  FlashContext,
  InvestmentType,
  Spinner,
  BankIDStatus,
  PendingHintCode,
  Status,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { Bankid, SIGN_OWNER_URL, SIGN_SIGNEE_URL } from "../../../data/bankid";
import {
  createGetFailedMessages,
  createGetMessages,
  createGetPendingMessages,
} from "../../../utils/agreementBankIdMessages";
import { AgreementEmails } from "./AgreementEmail";
import { names } from "../../../names";
import {
  getSigningInfo,
  SigningInfoResponse,
  GENERIC_ERROR,
  SIGNING_STATUS,
} from "../../../data/signup";
import { AgreementSigner } from "./AgreementSigner";
import "./Agreement.scss";
import { stringify, parse } from "query-string";
import { Location } from "history";
import { Docs } from "../../docs/Docs";
import { useDocumentsInformation } from "../../../hooks/useDocumentsInformation";
import { CopyLinkButton } from "../../../components/CopyLinkButton";
import { useIntl } from "react-intl";

const SIGNING_POLL_TIMER = 2000;

interface SearchParams {
  id?: string;
  t?: string;
  url?: string;
  creationToken?: string;
  at?: string;
}

function isWithinNextTwoWeeks(timestamp: string | undefined) {
  const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

  if (!timestamp) {
    return false;
  }

  const time = new Date(timestamp).getTime();

  return time > Date.now() && time < Date.now() + TWO_WEEKS;
}

function formatDate(timestamp: string | undefined) {
  if (!timestamp) {
    return "";
  }

  const d = new Date(timestamp);
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.substr(-2, 2);
  const day = `0${d.getDate()}`.substr(-2, 2);
  return `${year}-${month}-${day}`;
}

function accountType(accType?: string) {
  if (accType === "VP") {
    return "Värdepapperskonto (VP)";
  }

  return accType;
}

export const Agreement = () => {
  const intl = useIntl();

  // Used to handle the scenario where the current user signs on this
  // computer, and at the same time some other user signs on his computer.
  // In this scenario we want to show the "Thanks for signing" message long
  // enough to be read.
  const signatureDoneTimestamp = useRef(0);

  const [signatureState, setSignatureState] = useState<
    | { status: "IDLE" }
    | { status: "PENDING-POST-SIGNATURE-UPDATE" }
    | { status: "SUCCESS" }
  >({ status: "IDLE" });
  const [info, setInfo] = useState<SigningInfoResponse>();

  const pushFlash = useContext(FlashContext).pushFlash;
  const history = useHistory();
  const location = useLocation();

  const { information, isLoading: isLoadingInformation } =
    useDocumentsInformation();

  const urlParams = useMemo(() => {
    const params = getParamsFromUrl(location);

    return {
      ...params,
      signupId: params.signupId ?? "",
    };
  }, [location]);

  const onComplete = useCallback(() => {
    setSignatureState({
      status: "PENDING-POST-SIGNATURE-UPDATE",
    });
    signatureDoneTimestamp.current = Date.now();
    history.replace(removeParamsFromUrl(history.location));
  }, [history]);

  const onPollError = useCallback(
    (error: unknown) => {
      Sentry.captureException(error, {
        extra: {
          bankId: "pollSignError",
        },
      });
      history.replace(removeParamsFromUrl(history.location));
    },
    [history]
  );

  const pollFnRaw = useMemo(() => {
    const url = urlParams.url;
    const orderRef = urlParams.orderRef;
    const signupId = urlParams.signupId;

    return orderRef && url && signupId
      ? () => Bankid.pollSign(url, orderRef, signupId)
      : undefined;
  }, [urlParams.url, urlParams.orderRef, urlParams.signupId]);
  const pollFn = useDisablePollInBackground(pollFnRaw);

  const qrCodePollFn = useCallback(
    () => Bankid.pollSignQrCode(urlParams.orderRef),
    [urlParams.orderRef]
  );

  const {
    pollStatus,
    latestResponse,
    initiate,
    reset,
    qrCode,
    setOpenOnOtherDevice,
  } = useBankId({
    onComplete,
    onPollError,
    initPollFn: Bankid.initSign,
    qrCodePollFn,
    pollFn: pollFn,
    // This is a bit ugly, but pragmatic since there's trouble mocking time in the current test setup
    pollInterval: process.env.NODE_ENV === "test" ? 10 : undefined,
  });

  const initSignAndSyncToUrl = useCallback(
    (id: number, url: string) => {
      if (pollStatus === "IDLE") {
        initiate(url, id, urlParams.signupId)
          .then((response) => {
            if (response) {
              // This causes pollFn to be defined which starts the polling
              history.push(
                getUrlWithParams(
                  history.location,
                  response.orderRef,
                  url,
                  response.autoStartToken
                )
              );
            }
          })
          .catch((error: unknown) => {
            Sentry.captureException(error, {
              extra: {
                bankId: "initSignError",
              },
            });
            history.replace(removeParamsFromUrl(history.location));
          });
      }
    },
    [pollStatus, initiate, urlParams.signupId, history]
  );

  const handleNewSigningInfo = useCallback(
    (info: SigningInfoResponse, { skipNavigationDelay = false } = {}) => {
      function navigateUnlessDelayed(pathname: string) {
        if (
          skipNavigationDelay ||
          Date.now() - signatureDoneTimestamp.current > 2000
        ) {
          history.push(pathname);
        }
      }

      if (info.status === SIGNING_STATUS.COMPLETE) {
        navigateUnlessDelayed(names.DONE);
      } else if (info.status === SIGNING_STATUS.MANUAL_REVIEW) {
        navigateUnlessDelayed(names.MANUAL_REVIEW);
      } else if (info.status === SIGNING_STATUS.CREATING) {
        navigateUnlessDelayed(
          `${names.CREATING}?creationToken=${urlParams.signupId}`
        );
      } else {
        setInfo(info);
      }
    },
    [setInfo, history, urlParams.signupId]
  );

  // Fetch signing info immediately on page load
  useEffect(() => {
    getSigningInfo(urlParams.signupId)
      .then(handleNewSigningInfo)
      .catch(() => {
        pushFlash({ type: SNACKBAR_TYPES.ERROR, text: GENERIC_ERROR });
      });
  }, [urlParams.signupId, handleNewSigningInfo, pushFlash]);

  // Poll signing info
  const isWaitingForPostSignUpdate =
    signatureState.status === "PENDING-POST-SIGNATURE-UPDATE";
  const shouldPollSigningInfo = pollStatus === "IDLE";
  useEffect(() => {
    if (!urlParams.signupId || !shouldPollSigningInfo) {
      return;
    }

    let timerId: NodeJS.Timeout;
    let isStale = false;

    function pollSigningInfo() {
      getSigningInfo(urlParams.signupId)
        .then((info) => {
          // If the request was already in flight when shouldPollSigningInfo changed
          // the effect cleanup will have set isStale to true, so !isStale
          // here also indirectly checks shouldPollSigningInfo
          if (!isStale) {
            handleNewSigningInfo(info, {
              // If the info that comes back immediately after BankID signing
              // indicates signing is complete, we want to navigate immediately
              skipNavigationDelay: isWaitingForPostSignUpdate,
            });
            timerId = setTimeout(pollSigningInfo, SIGNING_POLL_TIMER);

            if (isWaitingForPostSignUpdate) {
              setSignatureState({
                status: "SUCCESS",
              });
            }
          }
        })
        .catch(() => {
          if (!isStale) {
            pushFlash({
              type: SNACKBAR_TYPES.ERROR,
              text: GENERIC_ERROR,
            });
            clearTimeout(timerId);
          }
        });
    }

    timerId = setTimeout(
      pollSigningInfo,
      // If user just signed with bankid, we want to get signing info immediately
      isWaitingForPostSignUpdate ? 0 : SIGNING_POLL_TIMER
    );

    return () => {
      isStale = true;
      clearTimeout(timerId);
    };
  }, [
    urlParams.signupId,
    handleNewSigningInfo,
    pushFlash,
    shouldPollSigningInfo,
    isWaitingForPostSignUpdate,
  ]);

  const onClickOwner = useCallback(
    (user: { userId: number }) => {
      initSignAndSyncToUrl(user.userId, SIGN_OWNER_URL);
    },
    [initSignAndSyncToUrl]
  );

  const onClickSign = useCallback(
    (user: { userId: number }) => {
      initSignAndSyncToUrl(user.userId, SIGN_SIGNEE_URL);
    },
    [initSignAndSyncToUrl]
  );

  if (!urlParams.signupId || typeof urlParams.signupId !== "string") {
    return <Redirect to={names.HOME} />;
  }

  if (
    pollStatus === "PENDING" ||
    pollStatus === "FAILED" ||
    // Delay on the BankID screen until we have fresh signing data
    (pollStatus === "IDLE" && isWaitingForPostSignUpdate)
  ) {
    return (
      <BankIDStatus
        qrCode={qrCode}
        setOpenOnOtherDevice={setOpenOnOtherDevice}
        autoStartToken={urlParams.autoStartToken}
        getMessages={createGetMessages(intl)}
        getFailedMessages={createGetFailedMessages(intl)}
        getPendingMessages={createGetPendingMessages(intl)}
        response={
          pollStatus === "IDLE"
            ? {
                status: Status.PENDING,
                hintCode: PendingHintCode.OUTSTANDINGTRANSACTION,
              }
            : latestResponse
        }
        retry={() => {
          if (pollStatus === "FAILED") {
            // We don't know what id to retry with here, so we reset the
            // hook state and let the user try again from the signing page
            history.replace(removeParamsFromUrl(history.location));
            reset();
          }
        }}
        reset={() => {
          history.replace(removeParamsFromUrl(history.location));
          reset();
        }}
      />
    );
  }

  if (isLoadingInformation) {
    return <Spinner />;
  }

  return (
    <div className="company-signup-agreement">
      <h2>Skapa företags&shy;konto</h2>
      {signatureState.status === "SUCCESS" &&
        info?.status === SIGNING_STATUS.SIGNING_STARTED && (
          <Snackbar type={SNACKBAR_TYPES.SUCCESS} icon>
            {/*
              TODO: Different texts if we're waiting for
                    beneficial owners to confirm, or signing groups
                    to complete
            */}
            Tack för att du signerade.
            <br />
            Nu väntar vi på att alla verkliga huvudmän ska bekräfta och att en
            signeringsgrupp signerar klart.
          </Snackbar>
        )}

      {isWithinNextTwoWeeks(info?.expires) && (
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          Firmatecknare behöver signera avtalet senast{" "}
          {formatDate(info?.expires)}
        </Snackbar>
      )}
      <div className="before-card">
        <Docs information={information} />
      </div>
      <Card>
        <h4>
          <Icon.Summary />
          Summering
        </h4>

        <dl>
          <dt>Registreringen påbörjades av</dt>
          <dd>
            {info?.summary?.registrator}
            <br />({info?.summary?.email})
          </dd>
          <dt>Kontotyp</dt>
          <dd>{accountType(info?.summary?.accountType)}</dd>
          <dt>Skattehemvist</dt>
          <dd>Sverige</dd>
          <dt className="target">Målfördelning</dt>
          <dd className="risk-indicator-container">
            <AllocationBar
              risk={info?.summary?.takenRisk || 0}
              shouldFormatNumber
            />
          </dd>
          <dt>Investeringsfokus</dt>
          <dd>
            {info?.summary?.investmentType === InvestmentType.SUSTAINABLE
              ? "Hållbar"
              : "Bred"}
          </dd>
        </dl>
        <br className="clearFix" />

        <h5>Användare</h5>
        <table>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Typ av användare</th>
            </tr>
          </thead>
          <tbody>
            {info?.users?.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.admin ? "Administratör" : "Begränsad"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className="admin-warning">
          Administratörer kan i framtiden ge behörighet till nya administratörer
          och användare. Begränsade användare har endast läsrättigheter.
        </p>
      </Card>
      <Card>
        <h4>Identifiering av verklig huvudman med BankID</h4>

        <div className="beneficial-owners">
          <ul className="signer-names">
            {info?.owners?.map((owner) => (
              <AgreementSigner
                key={owner.id}
                signer={owner}
                onClick={onClickOwner}
                buttonText="Identifiera"
              />
            ))}
          </ul>
        </div>
      </Card>
      <Card>
        <h4>Signera avtal med BankID</h4>
        <p className="signing-group-description">
          En av grupperna nedan måste signera för att skapa kontot
        </p>

        <div className="signers-groups">
          {info?.signingGroups?.map((group, i) => (
            <div className="signing-group" key={"s-g-" + i}>
              <div className="signing-group-header">
                <h5>Signeringsgrupp {i + 1}</h5>
                <p>{group.signingText}</p>
              </div>
              <div className="signing-group-content">
                <ul className="signer-names">
                  {group.signees.map((signee) => (
                    <AgreementSigner
                      key={signee.id}
                      signer={signee}
                      onClick={onClickSign}
                      buttonText="Signera"
                    />
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Card>
      <Card>
        <h4>Signaturer och identifiering</h4>
        <p>
          För att öppna ett företagskonto måste vi ha signaturer av företagets
          firmatecknare och verkliga huvudmän. Om det krävs fler firmatecknare
          utöver dig själv, eller om du endast administrerar kontot, kan du
          själv kopiera länken till den här sidan och skicka till de
          firmatecknare och verkliga huvudmän som ska signera. Du kan också
          skriva in mailadressen här nedan så förmedlar vi länken.
        </p>
        <CopyLinkButton href={window.location.href} />

        <div className="email-reminders">
          <AgreementEmails signupId={urlParams.signupId} />
        </div>
      </Card>
    </div>
  );
};

function getUrlWithParams(
  location: Location,
  orderRef: string | undefined,
  url: string,
  autoStartToken: string
) {
  const search = parse(location.search) as SearchParams;
  search.t = new Date().getTime().toString();
  search.id = orderRef;
  search.url = btoa(url);
  search.at = autoStartToken;

  return {
    pathname: names.AGREEMENT,
    search: stringify(search),
  };
}

function removeParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  delete search.t;
  delete search.id;
  delete search.url;
  delete search.at;

  return {
    pathname: names.AGREEMENT,
    search: stringify(search),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    time: search.t,
    orderRef: search.id,
    url: search.url ? atob(search.url) : undefined,
    signupId: search.creationToken,
    autoStartToken: search.at,
  };
}
