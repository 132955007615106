import React, { useContext } from "react";
import { Checkbox, RequiredValidator } from "@lysaab/ui-2";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";

const riskRequiredValidator = new RequiredValidator(
  "Du måste vara införstådd i riskerna med investeringar för att gå vidare"
);

const okAlternative = {
  text: "Ok, jag förstår!",
  value: "-",
};

export const VolatilityCheckbox = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);

  return (
    <Checkbox
      alternative={okAlternative}
      checked={!!contextState.volatilityAgreement}
      onChange={() =>
        updateContext({
          volatilityAgreement: !contextState.volatilityAgreement,
        })
      }
      validators={[riskRequiredValidator]}
    />
  );
};

export const ExperienceCheckbox = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);

  return (
    <Checkbox
      alternative={okAlternative}
      checked={!!contextState.experienceAgreement}
      onChange={() =>
        updateContext({
          experienceAgreement: !contextState.experienceAgreement,
        })
      }
      validators={[riskRequiredValidator]}
    />
  );
};
