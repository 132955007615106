import React, { FunctionComponent, useContext, useState } from "react";
import * as Sentry from "@sentry/react";
import { PepContext } from "@lysaab/lysa-pep";
import { useHistory } from "react-router-dom";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import { PepEditOverview } from "./PepEditOverview";
import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { names } from "../../../../names";
import { validatePep } from "../../../../data/kyc";
import { pepStateWithType } from "./PepOwnerSelection";

export const PepConfirmation: FunctionComponent = () => {
  const pepContext = useContext(PepContext);
  const signupContext = useContext(CompanySignupContext);
  const history = useHistory();
  const [validatingIds, setValidatingIds] = useState<{
    [key: string]: "YES" | "NO" | "DONE" | undefined;
  }>({});
  const [error, setError] = useState("");

  const designatedOwner = signupContext.state.designatedOwner;
  const registeredOwners = signupContext.state.registeredOwners;

  function validatePepAnswers() {
    const validations = [];

    if (designatedOwner?.pep) {
      setValidatingIds({ [designatedOwner.name]: "YES" });
      validations.push(
        validatePep(pepStateWithType(designatedOwner)).then(() => {
          setValidatingIds({ [designatedOwner.name]: "DONE" });

          // TODO:
          // * Handle response.status === VALIDATION_STATUS.OK
          // * Handle !OK
        })
      );

      Sentry.addBreadcrumb({
        category: "PEP",
        message: `PEP confirmed with designated owner type: ${designatedOwner.pep.type}`,
      });
      signupContext.setState({ designatedOwner });
    } else {
      registeredOwners.forEach((owner) => {
        setValidatingIds((old) => ({ ...old, [owner.id]: "YES" }));

        validations.push(
          validatePep(pepStateWithType(owner)).then(() => {
            setValidatingIds((old) => ({
              ...old,
              [owner.id]: "DONE",
            }));

            // TODO:
            // * Handle response.status === VALIDATION_STATUS.OK
            // * Handle !OK
          })
        );
      });

      Sentry.addBreadcrumb({
        category: "PEP",
        message: `PEP confirmed with registered owners types: ${registeredOwners.map(
          (owner) => owner?.pep?.type
        )}`,
      });
      signupContext.setState({ registeredOwners });
    }

    Promise.all(validations)
      .then((results) => {
        history.push(names.KYC);
      })
      .catch((e) => {
        setValidatingIds({});
        setError("Ajdå, något gick fel 😟 Försök gärna igen ✨");
      });
  }

  return (
    <div className="pep-confirm">
      <h2>Kontrollerar svar</h2>

      {registeredOwners
        .filter((owner) => owner.pep)
        .map((owner) => (
          <PepEditOverview
            key={owner.id}
            pepContext={pepContext}
            owner={owner}
            isValidating={validatingIds[owner.id.toString()]}
          />
        ))}

      {designatedOwner && designatedOwner.pep && (
        <PepEditOverview
          pepContext={pepContext}
          owner={designatedOwner}
          isValidating={validatingIds[designatedOwner.name]}
        />
      )}

      {error && <Snackbar type={SNACKBAR_TYPES.ERROR}>{error}</Snackbar>}

      <Button
        block
        onClick={() => {
          validatePepAnswers();
        }}
        label="Gå vidare"
      />
    </div>
  );
};
