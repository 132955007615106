import { API } from "@lysaab/ui-2";

export interface DocumentInformation {
  fileName: string;
  title: string;
  type: string;
  lang: string;
  country: string;
  created: string;
}

export function getDocumentsInformation() {
  return API.get<DocumentInformation[]>(
    `${process.env.REACT_APP_LYSA_DOCS_URL}/docs.json`,
    true,
    {
      credentials: undefined,
      headers: {},
    }
  );
}

export const getLatestDocuments = (
  information: DocumentInformation[],
  ...groups: string[][]
) => {
  return groups
    .map(
      (documents) =>
        documents
          .filter((name) => {
            const docInfo = getDocumentInfo(name, information);
            if (!docInfo) {
              return false;
            }
            return new Date().getTime() >= new Date(docInfo.created).getTime();
          })
          .sort((a, b) => {
            const docAInfo = getDocumentInfo(a, information);
            const docBInfo = getDocumentInfo(b, information);
            if (!docAInfo || !docBInfo) return -1;
            return (
              new Date(docBInfo.created).getTime() -
              new Date(docAInfo.created).getTime()
            );
          })?.[0]
    )
    .filter(Boolean);
};

const getDocumentInfo = (name: string, information: DocumentInformation[]) =>
  information.find(({ fileName }) => fileName === name);
