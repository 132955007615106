import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import {
  isSustainabilityImportantSpecific,
  PAIImportance,
} from "../../../../../data/investments";
import { useAdvice } from "../../../../../models/AdviceContext";
import { PAIQuestionInfoModal } from "./modals/PAIQuestionInfoModal";

export const paiAltMessages: Record<PAIImportance, string> = {
  [PAIImportance.NOT_IMPORTANT]: "Inte viktigt",
  [PAIImportance.IMPORTANT]: "Viktigt",
};

export const PAIQuestion: FunctionComponent = () => {
  const advice = useAdvice();

  if (!isSustainabilityImportantSpecific(advice.input)) {
    return null;
  }

  const alternatives = Object.values(PAIImportance).map((key) => ({
    text: paiAltMessages[key],
    value: key,
  }));

  const value =
    typeof advice.input.pai === "string"
      ? {
          text: paiAltMessages[advice.input.pai],
          value: advice.input.pai,
        }
      : advice.input.pai;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          <>
            Hur viktigt är det att företagets investeringar tar hänsyn till
            huvudsakliga negativa konsekvenser inom områden såsom mänskliga
            rättigheter och biologisk mångfald?
            <br />
            <p>
              <PAIQuestionInfoModal />
            </p>
          </>
        }
        onChange={({ value }) => advice.setInput({ pai: value })}
        value={value}
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
      />
    </Card>
  );
};
