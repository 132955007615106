export const names = {
  HOME: "/",
  COMPANY_SELECTION: "/company-selection",
  EXPERIENCE: "/experience",
  BENEFICIAL_OWNERS: "/beneficial-owners",
  OWNER_INFO: "/owner-info",
  FATCA_CHECK: "/fatca-check",
  USERS: "/users",
  ADD_EDIT_USER: "/add-edit-user",
  DETAILS: "/details",
  PEP: "/pep",
  PEP_IS_PEP: "/pep/ispep",
  PEP_RELATION: "/pep/relation",
  PEP_STATUS: "/pep/status",
  PEP_ROLE: "/pep/role",
  PEP_CONFIRM: "/pep/confirm",
  KYC: "/kyc",
  PROFIT_AND_RISK: "/profit-and-risk",
  SITUATION: "/situation",
  HORIZON: "/horizon",
  SUSTAINABILITY: "/sustainability",
  SUSTAINABILITY_PREFERENCE: "/sustainability-preference",
  SUSTAINABILITY_QUESTIONS: "/sustainability-questions",
  INVESTMENT_TYPE: "/investment-type",
  CONFIRM_ESG_UPDATE: "/confirm-esg-update",
  ADVICE: "/advice",
  EDIT_ALLOCATION: "/edit-allocation",
  EDIT_ALLOCATION_FORECAST: "/edit-allocation/forecast",
  EDIT_ALLOCATION_FEES: "/edit-allocation/fees",
  EDIT_ALLOCATION_POSITIONS: "/edit-allocation/positions",
  WARNING: "/warning",
  SUMMARY: "/summary",
  AGREEMENT: "/agreement",
  MANUAL_REVIEW: "/manual-review",
  CREATING: "/creating",
  DONE: "/done",
  DOCS: "/docs",
  DOCS_CUSTOMER_AGREEMENT: "/docs/customer-agreement",
  DOCS_INFORMATION_ABOUT_LYSA: "/docs/information-about-lysa",
  DOCS_AGREEMENT_ACCOUNT_COMPANY: "/docs/agreement-account-company",
  DOCS_INVESTMENT_GUIDELINES: "/docs/investment-guidelines",
  DOCS_ACCOUNT_TERMS: "/docs/account-terms",
  DOCS_RISKS: "/docs/risks",
  DOCS_BEST_EXECUTION: "/docs/best-execution",
};
