import React from "react";
import { IntlProvider } from "react-intl";
import svMessages from "./locales/sv.json";

type Language = "sv";

const languagePacks: Record<Language, Record<string, string>> = {
  sv: svMessages,
};

export const Localization: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <IntlProvider
      locale="sv-SE"
      defaultLocale="sv-SE"
      messages={languagePacks["sv"]}
    >
      {children}
    </IntlProvider>
  );
};
