import React, { FunctionComponent } from "react";
import { SuggestedOwner, DESIGNATED_ROLE } from "../../../../data/signup";
import { SelectableCard } from "@lysaab/ui-2";

const ROLE_TRANSLATIONS = {
  [DESIGNATED_ROLE.CEO]: "VD",
  [DESIGNATED_ROLE.EXTERNAL_CEO]: "Extern VD",
  [DESIGNATED_ROLE.BOARD_CHAIRMAN]: "Ordförande",
};

interface Props {
  owner: SuggestedOwner;
  selected: boolean;
  onClick: () => void;
}

export const SuggestedOwnerCard: FunctionComponent<Props> = ({
  owner,
  selected,
  onClick,
}) => {
  return (
    <SelectableCard selected={selected} onClick={onClick}>
      <div>
        <p>
          <b>För- och efternamn</b>
          <br />
          {owner.name}
        </p>
        <p>
          <b>Roll</b>
          <br />
          {ROLE_TRANSLATIONS[owner.role]}
        </p>
      </div>
    </SelectableCard>
  );
};
