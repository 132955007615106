import { VALIDATION_STATE, ValidationResponse, SwedishTin } from "@lysaab/ui-2";
import { BaseValidator } from "@lysaab/ui-2/validators/BaseValidator";

const ERROR_MSG = "En användare med detta personnummer finns redan";

export class DuplicateUserValidator extends BaseValidator {
  protected name = "DuplicateUserValidator";

  private ids: string[];

  constructor(ids: string[]) {
    super(ERROR_MSG);
    this.ids = ids;
  }

  getId() {
    return this.getBaseId() + this.ids.join("");
  }

  validate(id: string) {
    /**
     * We should always validate empty string as VALIDATION_STATE.SUCCESS
     * This is allows fields to be optional. If the field is required the
     * requiredValidator should be added
     */
    if (id.length === 0) {
      return Promise.resolve({
        status: VALIDATION_STATE.SUCCESS,
        hideError: true,
      });
    }

    return new Promise<ValidationResponse>((resolve) => {
      if (
        this.ids.find(
          (existingId) =>
            new SwedishTin(existingId).getNormalizedTin() ===
            new SwedishTin(id).getNormalizedTin()
        )
      ) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: false,
        });
      } else {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      }
    });
  }
}
