import React, {
  useContext,
  FunctionComponent,
  ReactNode,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import {
  CompanySignupContext,
  State,
} from "../../models/CompanySignupContextProvider";
import { names } from "../../names";

interface Props {
  children: ReactNode;
  route: string;
  isValid: (state: State) => boolean;
}

export const BlockingAnswersAccessGuard: FunctionComponent<Props> = ({
  children,
  route,
  isValid,
}) => {
  const signupContext = useContext(CompanySignupContext);
  const history = useHistory();

  useEffect(() => {
    if (
      history.location.pathname !== names.AGREEMENT &&
      !isValid(signupContext.state)
    ) {
      history.replace(route);
    }
  }, [history, signupContext.state, isValid, route]);

  return <>{children}</>;
};
