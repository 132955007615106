export class LocalStorage {
  static getItem(key: LocalStorage.Key) {
    /**
     * Some browsers throw errors in incognito mode or with high privacy settings
     */
    try {
      const data = window.localStorage.getItem(key);
      if (data === null) {
        return;
      }
      return data;
    } catch (e) {
      return;
    }
  }

  static setItem(key: LocalStorage.Key, value: string) {
    /**
     * Silently fail if the browser blocks it
     */
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      return;
    }
  }

  static removeItem(key: LocalStorage.Key) {
    /**
     * Silently fail if the browser blocks it
     */
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      return;
    }
  }
}

export namespace LocalStorage {
  export enum Key {
    STORE_SETTINGS_COOKIE_CONSENT_OK = "settings.cookieConsent.ok",
  }
}
