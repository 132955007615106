import * as Sentry from "@sentry/react";
import {
  CONTROLLING_SHARE,
  SaveRequest,
  SaveRegisteredOwner,
  SaveDesignatedOwner,
} from "../data/signup";
import { CONTROL_TYPES, User } from "../data/signup";
import { State } from "./CompanySignupContextProvider";
import { PepRequest } from "@lysaab/lysa-pep";
import { SwedishTin } from "@lysaab/ui-2";
import {
  AdviceRequest,
  getAccountQuestions,
  isValidAccountQuestions,
} from "../data/investments";
import { AdviceInput, AdviceResult } from "./AdviceContext";

function formatPnrs(users: User[]): User[] {
  return users.map((user) => {
    return {
      ...user,
      identificationNumber: new SwedishTin(
        user.identificationNumber
      ).getNormalizedTin(),
    };
  });
}

export class CompanyModel {
  static COMPANY_CONTROLLING_SHARE = {
    [CONTROLLING_SHARE.SHARES_0_25]: "< 25 %",
    [CONTROLLING_SHARE.SHARES_25_50]: "25 - 50 %",
    [CONTROLLING_SHARE.SHARES_50_75]: "50 - 75 %",
    [CONTROLLING_SHARE.SHARES_75_100]: "75 - 100 %",
    [CONTROLLING_SHARE.SHARES_100]: "100 %",
  };

  static COMPANY_CONTROL_TYPES = {
    [CONTROL_TYPES.ART_10]:
      "Personen har kontroll genom aktier, andelar, medlemskap, avtal eller bestämmelse i exempelvis bolagsordning eller stadgar",
    [CONTROL_TYPES.ART_14]:
      "Personen är styrelseledamot eller motsvarande befattningshavare",
    [CONTROL_TYPES.ART_20]:
      "Personen har rätt att utse eller avsätta mer än hälften av styrelseledamötena eller motsvarande befattningshavare",
    [CONTROL_TYPES.ART_30]: "Personen har kontroll tillsammans med närstående",
    [CONTROL_TYPES.ART_40]:
      "Personen har kontroll genom andra företag eller föreningar",
    [CONTROL_TYPES.ART_74]: "Personen har kontroll på annat sätt",
  };

  static getAdviceData(
    contextState: State,
    adviceInput: AdviceInput
  ): AdviceRequest | undefined {
    const riskImportance = adviceInput.riskImportance?.value;
    const riskAction = adviceInput.riskReaction?.value;
    const riskRisk = adviceInput.riskPropensity?.value;

    if (
      typeof riskImportance === "undefined" ||
      typeof riskAction === "undefined" ||
      typeof riskRisk === "undefined" ||
      !isValidAccountQuestions(adviceInput)
    ) {
      return;
    }

    return {
      tracingReference: "company-signup_" + contextState.signupId,
      riskAnswers: [riskImportance, riskAction, riskRisk],
      ...getAccountQuestions(adviceInput),
    };
  }

  static getSaveData(
    contextState: State,
    adviceInput: AdviceInput,
    adviceResult: AdviceResult
  ): SaveRequest | undefined {
    const bankAccount = contextState.detailsBankAccount;
    const email = contextState.detailsEmail;
    const phone = contextState.detalsPhoneNumber;
    const users = formatPnrs(contextState.users);
    const kycCompany = contextState.companyKyc;
    const kycAccount = contextState.accountKyc;
    const ownMoney = contextState.ownMoney?.value;
    const adviceData = this.getAdviceData(contextState, adviceInput);
    const investmentType = adviceResult.investmentType;
    const campaign = contextState.campaign;
    const accountName = contextState.accountName;
    const owners = {
      registered: contextState.registeredOwners.map(
        (owner): SaveRegisteredOwner => {
          return {
            id: owner.id,
            pep: owner.pep || ({} as PepRequest),
          };
        }
      ),
      designated: contextState.designatedOwner
        ? ({
            role: contextState.designatedOwner.role,
            pep: contextState.designatedOwner.pep,
            citizenship: contextState.designatedOwner.citizenship,
            taxDomicile: contextState.designatedOwner.taxDomicile,
          } as SaveDesignatedOwner)
        : undefined,
    };

    let takenRisk = adviceResult.advicedRisk;
    if (typeof adviceResult.takenRisk !== "undefined") {
      takenRisk = adviceResult.takenRisk;
    }

    if (
      !bankAccount ||
      !email ||
      !users ||
      !kycCompany ||
      !kycAccount ||
      typeof ownMoney === "undefined" ||
      typeof takenRisk === "undefined" ||
      !adviceData ||
      !investmentType ||
      !(owners.registered.length > 0 || owners.designated)
    ) {
      const isTruthy = (value: any) => (Boolean(value) ? "set" : "not set");
      Sentry.withScope((scope) => {
        scope.setContext("saveData", {
          bankAccount: isTruthy(bankAccount),
          email: isTruthy(email),
          users: isTruthy(users),
          kycCompany: isTruthy(kycCompany),
          kycAccount: isTruthy(kycAccount),
          ownMoney: typeof ownMoney !== "undefined" ? "set" : "not set",
          takenRisk: typeof takenRisk !== "undefined" ? "set" : "not set",
          adviceData: isTruthy(adviceData),
          investmentType: isTruthy(investmentType),
          registered: owners.registered.length > 0 ? "set" : "not set",
          designated: isTruthy(owners.designated),
        });
        Sentry.captureException("Missing data for save request");
      });
      return;
    }

    return {
      kyc: {
        company: kycCompany,
        account: kycAccount,
        handlesCash: false,
        ownMoney,
      },
      advice: {
        ...adviceData,
        investmentType,
        takenRisk,
      },
      owners,
      bankAccount,
      contactInformation: {
        email,
        phone,
      },
      users,
      campaign,
      accountName,
    };
  }
}
