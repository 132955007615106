import { API, encode, InvestmentType } from "@lysaab/ui-2";

export enum SavingsHorizonLength {
  VERY_LONG = "VERY_LONG",
  LONG = "LONG",
  KINDA_LONG = "KINDA_LONG",
  MIDDLE = "MIDDLE",
  SHORT = "SHORT",
}

export enum NeedEarlierProbability {
  VERY_UNLIKELY = "VERY_UNLIKELY",
  UNLIKELY = "UNLIKELY",
  SOMEWHAT_UNLIKELY = "SOMEWHAT_UNLIKELY",
  SOMEWHAT_LIKELY = "SOMEWHAT_LIKELY",
  LIKELY = "LIKELY",
  VERY_LIKELY = "VERY_LIKELY",
}

export enum SustainabilityImportance {
  NOT_IMPORTANT = "NOT_IMPORTANT",
  IMPORTANT = "IMPORTANT",
}

export enum SustainabilityPreference {
  NONE = "NONE",
  SPECIFIC = "SPECIFIC",
}

export enum PAIImportance {
  NOT_IMPORTANT = "NOT_IMPORTANT",
  IMPORTANT = "IMPORTANT",
}

export enum SFDRImportance {
  NONE = "NONE",
  LEAST_25 = "LEAST_25",
  LEAST_50 = "LEAST_50",
  LEAST_75 = "LEAST_75",
}

export enum TaxonomyImportance {
  NONE = "NONE",
  LEAST_25 = "LEAST_25",
  LEAST_50 = "LEAST_50",
  LEAST_75 = "LEAST_75",
}

export enum RiskImportance {
  MAXIMIZE = 10,
  MINIMIZE = 0,
  BOTH = 5,
}

export enum RiskReaction {
  SELL = 3,
  KEEP = 7,
  BUY = 10,
}

export enum RiskPropensity {
  BAD = 10,
  PRETTY_BAD = 8,
  GOOD = 6,
  PRETTY_GOOD = 4,
  TOO_RISKY = 2,
}

export const isSavingsHorizonLength = (
  value?: string
): value is SavingsHorizonLength => {
  return Object.values(SavingsHorizonLength).includes(
    value as SavingsHorizonLength
  );
};

export const isNeedEarlierProbability = (
  value?: string
): value is NeedEarlierProbability => {
  return Object.values(NeedEarlierProbability).includes(
    value as NeedEarlierProbability
  );
};

export const isSustainabilityImportance = (
  value?: string
): value is SustainabilityImportance => {
  return Object.values(SustainabilityImportance).includes(
    value as SustainabilityImportance
  );
};

export const isSustainabilityPreference = (
  value?: string
): value is SustainabilityPreference => {
  return Object.values(SustainabilityPreference).includes(
    value as SustainabilityPreference
  );
};

export const isPAIImportance = (value: string): value is PAIImportance => {
  return Object.values(PAIImportance).includes(value as PAIImportance);
};

export const isSFDRImportance = (value: string): value is SFDRImportance => {
  return Object.values(SFDRImportance).includes(value as SFDRImportance);
};

export const isTaxonomyImportance = (
  value?: string
): value is TaxonomyImportance => {
  return Object.values(TaxonomyImportance).includes(
    value as TaxonomyImportance
  );
};

/** Horizon **/

export interface HorizonQuestions {
  needEarlier: NeedEarlierProbability;
  savingsHorizon: SavingsHorizonLength;
}

export function isHorizonUpdated(
  data: Partial<HorizonQuestions>,
  oldData: Partial<HorizonQuestions>
): boolean {
  const [horizon, oldHorizon] = [data, oldData].map((horizon) => {
    return {
      needEarlier: horizon.needEarlier,
      savingsHorizon: horizon.savingsHorizon,
    };
  });

  return JSON.stringify(horizon) !== JSON.stringify(oldHorizon);
}

export function isValidHorizon(
  data: Partial<HorizonQuestions>
): data is HorizonQuestions {
  if (!isNeedEarlierProbability(data.needEarlier)) {
    return false;
  }

  if (!isSavingsHorizonLength(data.savingsHorizon)) {
    return false;
  }

  return true;
}

export function getHorizonQuestions(data: HorizonQuestions): HorizonQuestions;
export function getHorizonQuestions(
  data: Partial<HorizonQuestions>
): Partial<HorizonQuestions>;
export function getHorizonQuestions(
  data: Partial<HorizonQuestions>
): Partial<HorizonQuestions> {
  if (!isValidHorizon(data)) {
    throw new Error(
      "getAccountQuestions - data didn't fulfill requirements for being AccountQuestions"
    );
  }

  const horizonQuestions: Partial<HorizonQuestions> = {
    needEarlier: data.needEarlier,
    savingsHorizon: data.savingsHorizon,
  };

  return horizonQuestions;
}

/** Sustainability **/

export interface SustainabilityQuestionsNotImportant {
  sustainability: SustainabilityImportance.NOT_IMPORTANT;
}

export interface SustainabilityQuestionsImportantNone {
  sustainability: SustainabilityImportance.IMPORTANT;
  sustainabilityPreference: SustainabilityPreference.NONE;
}

export interface SustainabilityQuestionsImportantSpecific extends EsgQuestions {
  sustainability: SustainabilityImportance.IMPORTANT;
  sustainabilityPreference: SustainabilityPreference.SPECIFIC;
}

export interface EsgQuestions {
  pai: PAIImportance;
  sfdr: SFDRImportance;
  taxonomy: TaxonomyImportance;
}

export function isEsgQuestionsUpdate(
  data: Partial<EsgQuestions>,
  oldData: Partial<AccountQuestions>
): boolean {
  const [esg, oldEsg] = [data, oldData].map((esgQuestions) => {
    if (isSustainabilityImportantSpecific(esgQuestions)) {
      return {
        pai: esgQuestions.pai,
        sfdr: esgQuestions.sfdr,
        taxonomy: esgQuestions.taxonomy,
      };
    }
    return {};
  });

  return JSON.stringify(esg) !== JSON.stringify(oldEsg);
}

export type SustainabilityQuestions =
  | SustainabilityQuestionsNotImportant
  | SustainabilityQuestionsImportantNone
  | SustainabilityQuestionsImportantSpecific;

export function isSustainabilityNotImportant(
  data: Partial<SustainabilityQuestions>
): data is SustainabilityQuestionsNotImportant {
  return data.sustainability === SustainabilityImportance.NOT_IMPORTANT;
}

export function isSustainabilityImportantNone(
  data: Partial<SustainabilityQuestions>
): data is SustainabilityQuestionsImportantNone {
  return (
    data.sustainability === SustainabilityImportance.IMPORTANT &&
    (data as SustainabilityQuestionsImportantNone).sustainabilityPreference ===
      SustainabilityPreference.NONE
  );
}

export function isSustainabilityImportantSpecific(
  data: Partial<SustainabilityQuestions>
): data is SustainabilityQuestionsImportantSpecific {
  return (
    data.sustainability === SustainabilityImportance.IMPORTANT &&
    (data as SustainabilityQuestionsImportantSpecific)
      .sustainabilityPreference === SustainabilityPreference.SPECIFIC
  );
}

export function isSustainabilityUpdated(
  data: Partial<SustainabilityQuestions>,
  oldData: Partial<SustainabilityQuestions>
): boolean {
  const [sustainability, oldSustainability] = [data, oldData].map(
    (sustainabilityQuestions) => {
      if (isSustainabilityImportantNone(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
          sustainabilityPreference:
            sustainabilityQuestions.sustainabilityPreference,
        };
      }

      if (isSustainabilityImportantSpecific(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
          sustainabilityPreference:
            sustainabilityQuestions.sustainabilityPreference,
          pai: sustainabilityQuestions.pai,
          sfdr: sustainabilityQuestions.sfdr,
          taxonomy: sustainabilityQuestions.taxonomy,
        };
      }

      if (isSustainabilityNotImportant(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
        };
      }

      return {};
    }
  );

  return JSON.stringify(sustainability) !== JSON.stringify(oldSustainability);
}

export function isValidSustainability(
  data: Partial<SustainabilityQuestions>
): data is SustainabilityQuestions {
  if (isSustainabilityImportantNone(data)) {
    return (
      isSustainabilityImportance(data.sustainability) &&
      isSustainabilityPreference(data.sustainabilityPreference)
    );
  } else if (isSustainabilityImportantSpecific(data)) {
    return (
      isSustainabilityImportance(data.sustainability) &&
      isSustainabilityPreference(data.sustainabilityPreference) &&
      isPAIImportance(data.pai) &&
      isSFDRImportance(data.sfdr) &&
      isTaxonomyImportance(data.taxonomy)
    );
  } else if (isSustainabilityNotImportant(data)) {
    return isSustainabilityImportance(data.sustainability);
  }
  return false;
}

/** AccountQuestions **/

export type AccountQuestions = SustainabilityQuestions & HorizonQuestions;

export function isValidAccountQuestions(
  data: Partial<AccountQuestions>
): data is AccountQuestions {
  if (!isValidHorizon(data as Partial<AccountQuestions>)) {
    return false;
  }

  if (!isValidSustainability(data as Partial<AccountQuestions>)) {
    return false;
  }

  return true;
}

export function isAccountQuestionsUpdated(
  data: Partial<AccountQuestions>,
  oldData: Partial<AccountQuestions>
): boolean {
  return (
    isHorizonUpdated(data, oldData) || isSustainabilityUpdated(data, oldData)
  );
}

export function getAccountQuestions(data: AccountQuestions): AccountQuestions;
export function getAccountQuestions(
  data: Partial<AccountQuestions>
): Partial<AccountQuestions>;
export function getAccountQuestions(
  data: Partial<AccountQuestions>
): Partial<AccountQuestions> {
  const horizon: Partial<HorizonQuestions> = data;
  const sustainability: Partial<SustainabilityQuestions> = data;

  if (!isValidHorizon(horizon)) {
    throw new Error(
      "getAccountQuestions - data didn't fulfill requirements for being HorizonQuestions"
    );
  }

  const horizonQuestions: Partial<HorizonQuestions> = {
    needEarlier: horizon.needEarlier,
    savingsHorizon: horizon.savingsHorizon,
  };

  if (!isValidSustainability(sustainability)) {
    throw new Error(
      "getAccountQuestions - data didn't fulfill requirements for being AccountQuestions"
    );
  }

  if (isSustainabilityImportantNone(sustainability)) {
    return {
      ...horizonQuestions,
      sustainability: sustainability.sustainability,
      sustainabilityPreference: sustainability.sustainabilityPreference,
    };
  } else if (isSustainabilityImportantSpecific(sustainability)) {
    return {
      ...horizonQuestions,
      sustainability: sustainability.sustainability,
      sustainabilityPreference: sustainability.sustainabilityPreference,
      pai: sustainability.pai,
      sfdr: sustainability.sfdr,
      taxonomy: sustainability.taxonomy,
    };
  } else if (isSustainabilityNotImportant(sustainability)) {
    return {
      ...horizonQuestions,
      sustainability: sustainability.sustainability,
    };
  }

  throw new Error("getAccountQuestions - failed to match any sustainability");
}

/** Request & Responses **/

export interface EsgResultResponse {
  esgResult: {
    esgAnswers: EsgQuestions;
    esgBestMatch?: EsgQuestions;
  };
}

export type AdviceRequest = AccountQuestions & {
  tracingReference?: string;
  riskAnswers: [RiskImportance, RiskReaction, RiskPropensity];
};

export interface AdviceResponse extends EsgResultResponse {
  advisedRisk: number;
  declaration: string;
  allocation: {
    [key: string]: number;
  };
  investmentType: InvestmentType;
}

export function getAdvice(token: string, data: AdviceRequest) {
  return API.post<AdviceResponse>(
    encode`/investments/suitability-assessment?creationToken=${token}`,
    data
  );
}
