import React, { useCallback, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  RadioGroup,
  RequiredValidator,
  Alternative,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import {
  NeedEarlierProbability,
  SavingsHorizonLength,
} from "../../../data/investments";
import { useAdvice } from "../../../models/AdviceContext";

export const savingsHorizonLengthAlternatives: Alternative<SavingsHorizonLength>[] =
  [
    {
      text: "Mycket lång, över 15 år",
      value: SavingsHorizonLength.VERY_LONG,
    },
    {
      text: "Lång, 10-15 år",
      value: SavingsHorizonLength.LONG,
    },
    {
      text: "Ganska lång, 5-10 år",
      value: SavingsHorizonLength.KINDA_LONG,
    },
    {
      text: "Mellan, 3-5 år",
      value: SavingsHorizonLength.MIDDLE,
    },
    {
      text: "Kort, 1-3 år",
      value: SavingsHorizonLength.SHORT,
    },
  ];

export const needEarlierAlternatives: Alternative<NeedEarlierProbability>[] = [
  {
    text: "Mycket osannolikt",
    value: NeedEarlierProbability.VERY_UNLIKELY,
  },
  {
    text: "Osannolikt",
    value: NeedEarlierProbability.UNLIKELY,
  },
  {
    text: "Ganska osannolikt",
    value: NeedEarlierProbability.SOMEWHAT_UNLIKELY,
  },
  {
    text: "Ganska sannolikt",
    value: NeedEarlierProbability.SOMEWHAT_LIKELY,
  },
  {
    text: "Sannolikt",
    value: NeedEarlierProbability.LIKELY,
  },
  {
    text: "Mycket sannolikt",
    value: NeedEarlierProbability.VERY_LIKELY,
  },
];

export const Horizon = () => {
  const { state: contextState } = useContext(CompanySignupContext);
  const advice = useAdvice();
  const history = useHistory();

  useEffect(() => {
    if (!contextState.signupId) {
      history.replace(names.HOME);
    }
  }, [history, contextState.signupId]);

  const formRef = useRef<LysaFormRef>();

  const persistData = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      // contextState.setDetails.call(undefined, { email, bankAccount });

      if (formRef.current && formRef.current.isValid) {
        history.push(names.SUSTAINABILITY);
      }
    },
    [formRef, history]
  );

  const HORIZON_TEXT =
    "Hur lång investeringshorisont har företaget för sitt första " +
    "Lysakonto?";

  const NEED_EARLIER_TEXT =
    "Hur sannolikt är det att företaget får behov av mer än hälften av " +
    "pengarna tidigare än planerat?";

  return (
    <div className="company-signup-horizon">
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <h2>Investeringshorisont</h2>
        <Card>
          <RadioGroup
            header={HORIZON_TEXT}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
            onChange={(alternative) =>
              advice.setInput({
                savingsHorizon: alternative.value,
              })
            }
            value={savingsHorizonLengthAlternatives.find(
              (alt) => alt.value === advice.input.savingsHorizon
            )}
            alternatives={savingsHorizonLengthAlternatives}
          />
          <RadioGroup
            header={NEED_EARLIER_TEXT}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
            onChange={(alternative) =>
              advice.setInput({
                needEarlier: alternative.value,
              })
            }
            value={needEarlierAlternatives.find(
              (alt) => alt.value === advice.input.needEarlier
            )}
            alternatives={needEarlierAlternatives}
          />
        </Card>

        <Button block type="submit" label="Gå vidare" />
      </Form>
    </div>
  );
};
