import React from "react";
import { Switch, Route } from "react-router-dom";
import { AccessabilityTabber, FlashOverlay } from "@lysaab/ui-2";
import { Refocus } from "./components/Refocus";
import { Localization } from "./Localization";

export const BaseProviders: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <Localization>
      <Refocus />
      <AccessabilityTabber />
      <FlashOverlay>
        <Switch>
          <Route>{children}</Route>
        </Switch>
      </FlashOverlay>
    </Localization>
  );
};
