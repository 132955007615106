interface Props {
  value: number;
  decimals?: number;
}

export const FormattedPercentage: React.FunctionComponent<Props> = ({
  value,
  decimals = 0,
}) => {
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return <>{formatter.format(value / 100)}</>;
};
