import React from "react";
import { names } from "../../../names";
import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";

import "./HorizonWarning.scss";

export const HorizonWarning = () => (
  <div className="horizon-warning">
    <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
      Med en kort investeringshorisont <strong>(1-3 år)</strong> är det
      rekommenderat att välja en låg andel aktier. Du kan därför inte välja mer
      än <strong>50 %</strong> aktier.{" "}
      <a href={names.HORIZON}>Ändra investeringshorisont</a>
    </Snackbar>
  </div>
);
