import React from "react";
import { LysaLinkButton } from "@lysaab/ui-2";
import { client as elevioClient } from "elevio";

interface Props {
  articleId: string | number;
  linkText: string;
}

export const ElevioLink: React.VFC<Props> = ({ articleId, linkText }) => {
  return (
    <LysaLinkButton onClick={() => elevioClient.openArticle("" + articleId)}>
      {linkText}
    </LysaLinkButton>
  );
};
