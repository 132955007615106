import { FunctionComponent } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import {
  isSustainabilityImportantSpecific,
  SFDRImportance,
} from "../../../../../data/investments";
import { SFDRQuestionInfoModal } from "./modals/SFDRQuestionInfoModal";
import { useAdvice } from "../../../../../models/AdviceContext";

export const sfdrAltMessages: Record<SFDRImportance, string> = {
  [SFDRImportance.NONE]: "Ingen",
  [SFDRImportance.LEAST_25]: "Minst 25 %",
  [SFDRImportance.LEAST_50]: "Minst 50 %",
  [SFDRImportance.LEAST_75]: "Minst 75 %",
};

export const SFDRQuestion: FunctionComponent = () => {
  const advice = useAdvice();

  if (!isSustainabilityImportantSpecific(advice.input)) {
    return null;
  }

  const alternatives = Object.values(SFDRImportance).map((key) => ({
    text: sfdrAltMessages[key],
    value: key,
  }));

  const value =
    typeof advice.input.sfdr === "string"
      ? {
          text: sfdrAltMessages[advice.input.sfdr],
          value: advice.input.sfdr,
        }
      : advice.input.sfdr;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          <>
            Hur stor andel av företagets investeringar vill ni ska vara hållbara
            genom att bidra till en bättre miljö eller social utveckling enligt
            EU:s Disclosureförordning (SFDR)?
            <br />
            <p>
              <SFDRQuestionInfoModal />
            </p>
          </>
        }
        onChange={({ value }) => advice.setInput({ sfdr: value })}
        value={value}
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
      />
    </Card>
  );
};
