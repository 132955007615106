import { State } from "./CompanySignupContextProvider";

export function isInvalidLiquidity(state: State) {
  return state.situationLiquidity?.value === "less";
}

export function isInvalidRisk(state: State) {
  return state.situationRisk?.value === "yes";
}

export function isValidSituation(state: State) {
  return (
    state.situationLiquidity?.value === "more" &&
    state.situationRisk?.value === "no"
  );
}
