import { VALIDATION_STATE, ValidationResponse, SwedishTin } from "@lysaab/ui-2";
import { BaseValidator } from "@lysaab/ui-2/validators/BaseValidator";

/**
 * We don't support companies where the org number is a Pnr number.
 * Due to backend limitations
 */
export class InvalidCompanyValidator extends BaseValidator {
  protected name = "InvalidCompanyValidator";

  getId() {
    return this.getBaseId();
  }

  validate(id: string) {
    /**
     * We should always validate empty string as VALIDATION_STATE.SUCCESS
     * This allows fields to be optional. If the field is required the
     * requiredValidator should be added
     */
    if (id.length === 0) {
      return Promise.resolve({
        status: VALIDATION_STATE.SUCCESS,
        hideError: true,
      });
    }

    return new Promise<ValidationResponse>((resolve) => {
      if (new SwedishTin(id).isValid()) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: id.length < 10,
        });
      } else {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      }
    });
  }
}
