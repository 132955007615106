import { FunctionComponent } from "react";
import { RadioGroupCard, RequiredValidator } from "@lysaab/ui-2";
import {
  SustainabilityImportance,
  SustainabilityPreference,
} from "../../../../data/investments";
import { ExpandableContent } from "../../../../components/expandableContent/ExpandableContent";
import { useAdvice } from "../../../../models/AdviceContext";
import { ElevioLink } from "../../../../components/ElevioLink";

export const sustainabilityPreferenceAltMessages: Record<
  SustainabilityPreference,
  string
> = {
  [SustainabilityPreference.NONE]: "Lysas fokus på hållbarhet",
  [SustainabilityPreference.SPECIFIC]: "Företaget har specifika preferenser",
};

const alternativeDescriptionMessages: Record<SustainabilityPreference, string> =
  {
    [SustainabilityPreference.NONE]:
      "Vårt hållbara alternativ för dig som vill investera mer miljövänligt och fortfarande ha en väl diversifierad portfölj med god riskspridning.",
    [SustainabilityPreference.SPECIFIC]:
      "Ett alternativ för företag med egna preferenser kring EU:s nya Disclosureförordning (SFDR), Taxonomi samt huvudsakliga negativa konsekvenser (PAI).",
  };

const alternativeExpandDescriptionCorpNoneMessage = {
  item1:
    "Företagets pengar investeras globalt i ca 1 000 aktiebolag och 200 ränteinstrument med fokus på samhälls- och miljöansvar.",
  item2: (
    <>
      Portföljen utgör en s.k. (ljusgrön) artikel 8-produkt och exkluderar bolag
      som är verksamma inom bl.a. spel, tobak och fossila bränslen.{" "}
      <ElevioLink articleId="322" linkText="Läs mer här"></ElevioLink>
    </>
  ),
};

const alternativeExpandDescriptionCorpSpecificMessage = {
  item1:
    "Mycket specifika preferenser kan leda till ett smalt sparande med låg riskspridning. Det finns risk att vi inte kan tillgodose samtliga preferenser inom ramen för företagets riskprofil.",
};

const alternativeExpandDescriptionCorpMessages = {
  [SustainabilityPreference.NONE]: alternativeExpandDescriptionCorpNoneMessage,
  [SustainabilityPreference.SPECIFIC]:
    alternativeExpandDescriptionCorpSpecificMessage,
};

export const SustainabilityPreferenceQuestion: FunctionComponent = () => {
  const advice = useAdvice();

  if (advice.input.sustainability !== SustainabilityImportance.IMPORTANT) {
    return null;
  }

  const alternatives = Object.values(SustainabilityPreference).map((key) => {
    return {
      header: sustainabilityPreferenceAltMessages[key],
      description: alternativeDescriptionMessages[key],
      expandableContent: (
        <ExpandableContent>
          <ul>
            {Object.values(alternativeExpandDescriptionCorpMessages[key]).map(
              (item, index) => (
                <li key={`preference-expandable-content-${index}`}>{item}</li>
              )
            )}
          </ul>
        </ExpandableContent>
      ),
      value: key,
    };
  });

  return (
    <RadioGroupCard
      legend={<h2>Hållbarhet</h2>}
      alternatives={alternatives}
      value={advice.input.sustainabilityPreference}
      onChange={(sustainabilityPreference) =>
        advice.setInput({ sustainabilityPreference })
      }
      validators={[new RequiredValidator("Du måste välja ett alternativ")]}
    />
  );
};
