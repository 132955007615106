import { PepState, stateToRequest } from "@lysaab/lysa-pep";
import { API } from "@lysaab/ui-2";

export enum VALIDATION_STATUS {
  OK = "OK",
  BAD_OWNER = "BAD_OWNER",
  BAD_COUNTRY = "BAD_COUNTRY",
  MISSING_TYPE = "MISSING_TYPE",
  MISSING_COUNTRY = "MISSING_COUNTRY",
  MISSING_NAME = "MISSING_NAME",
  MISSING_BUSINESS_RELATION = "MISSING_BUSINESS_RELATION",
  MISSING_RELATION = "MISSING_RELATION",
  MISSING_BIRTHDAY = "MISSING_BIRTHDAY",
  MISSING_ROLE = "MISSING_ROLE",
  MISSING_ORGANISATION = "MISSING_ORGANISATION",
  MISSING_ORGANISATION_ROLE = "MISSING_ORGANISATION_ROLE",
  MISSING_COMPANY = "MISSING_COMPANY",
  MISSING_COMPANY_ROLE = "MISSING_COMPANY_ROLE",
  MISSING_RANK = "MISSING_RANK",
  MISSING_AMBASSADOR_COUNTRY = "MISSING_AMBASSADOR_COUNTRY",
  BAD_AMBASSADOR_COUNTRY = "BAD_AMBASSADOR_COUNTRY",
}

export interface ValidationResponse {
  status: VALIDATION_STATUS;
}

export function validatePep(answers: PepState) {
  return API.post<ValidationResponse>(
    "/kyc/pep/validate",
    stateToRequest(answers)
  );
}
