import { useCallback, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  RadioGroup,
  RequiredValidator,
  Alternative,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { useAdvice } from "../../../models/AdviceContext";

export const importanceAlternatives: Alternative<number>[] = [
  {
    text: "Maximera vinster",
    value: 10,
  },
  {
    text: "Minimera förluster",
    value: 0,
  },
  {
    text: "Båda lika mycket",
    value: 5,
  },
];

export const actionAlternatives: Alternative<number>[] = [
  {
    text: "Säljer aktier",
    value: 3,
  },
  {
    text: "Behåller aktier",
    value: 7,
  },
  {
    text: "Köper aktier",
    value: 10,
  },
];

export const riskAlternatives: Alternative<number>[] = [
  {
    text: "Dåligt, företagets riskbenägenhet och avkastningskrav är högre",
    value: 10,
  },
  {
    text: "Ganska dåligt, företagets riskbenägenhet och avkastningskrav är lite högre",
    value: 8,
  },
  {
    text: "Bra, detta motsvarar väl företagets riskbenägenhet och avkastningskrav",
    value: 6,
  },
  {
    text: "Ganska väl, risken är dock lite för hög",
    value: 4,
  },
  {
    text: "Dåligt, risken är för hög",
    value: 2,
  },
];

const RISK_ACTION_TEXT =
  "Värdet på aktier kan gå upp och ned. Vi antar att företaget har " +
  "investerat i aktier och värdet på aktierna minskar med 10 % under en " +
  "månad. Hur agerar företaget?";

const RISK_OPPORTUNITY_TEXT =
  "Företaget erbjuds att investera i tillgångar som förväntas ge en " +
  "avkastning på 6 % under 1 år. Det finns dock en risk att tillgångarna " +
  "minskar i värde med 20 %. Hur väl motsvarar det företagets riskbenägenhet?";

export const ProfitAndRisk = () => {
  const { state: contextState } = useContext(CompanySignupContext);
  const advice = useAdvice();
  const history = useHistory();

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  const formRef = useRef<LysaFormRef>();

  const persistData = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      // contextState.setDetails.call(undefined, { email, bankAccount });

      if (formRef.current && formRef.current.isValid) {
        history.push(names.SITUATION);
      }
    },
    [formRef, history]
  );

  return (
    <div className="company-signup-profit-and-risk">
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <h2>Avkastning &amp; risk</h2>
        <Card>
          <RadioGroup
            header="Vad är viktigast för företagets investeringar?"
            validators={[
              new RequiredValidator("Du måste välja vad som är viktigast"),
            ]}
            onChange={(alternative) =>
              advice.setInput({
                riskImportance: alternative,
              })
            }
            value={advice.input.riskImportance}
            alternatives={importanceAlternatives}
          />
          <RadioGroup
            header={RISK_ACTION_TEXT}
            validators={[new RequiredValidator("Du måste välja hur du agerar")]}
            onChange={(alternative) =>
              advice.setInput({
                riskReaction: alternative,
              })
            }
            value={advice.input.riskReaction}
            alternatives={actionAlternatives}
          />
          <RadioGroup
            header={RISK_OPPORTUNITY_TEXT}
            validators={[
              new RequiredValidator(
                "Du måste välja hur väl det motsvarar den juridiska personens riskbenägenhet"
              ),
            ]}
            onChange={(alternative) =>
              advice.setInput({
                riskPropensity: alternative,
              })
            }
            value={advice.input.riskPropensity}
            alternatives={riskAlternatives}
          />
        </Card>
        <Button block type="submit" label="Gå vidare" />
      </Form>
    </div>
  );
};
