import { useState, useEffect, useCallback } from "react";
import {
  DocumentInformation,
  getDocumentsInformation,
} from "../data/documents";

export function useDocumentsInformation() {
  const [information, setInformation] = useState<DocumentInformation[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const retry = useCallback(() => {
    getDocumentsInformation().then((docs) => {
      setIsLoading(false);
      setError(false);
      setInformation(docs);
    });
  }, []);

  useEffect(() => {
    getDocumentsInformation()
      .then((docs) => {
        setIsLoading(false);
        setError(false);
        setInformation(docs);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  }, []);

  return { information, isLoading, error, retry };
}
