import { LysaCountry } from "@lysaab/shared";

export enum Currency {
  SEK = "SEK",
  DKK = "DKK",
  EUR = "EUR",
}

export interface LocalizationState {
  currency: Currency;
  country: LysaCountry;
}

export const useLocalizationContext = (): { state: LocalizationState } => {
  return {
    state: {
      currency: Currency.SEK,
      country: LysaCountry.SWEDEN,
    },
  };
};
