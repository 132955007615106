import { FailedHintCode, Messages, PendingHintCode } from "@lysaab/ui-2";
import { IntlShape, defineMessages } from "react-intl";

export const signingMessages = defineMessages<keyof Messages, { id: string }>({
  qrInfo1: {
    id: "sweden.agreement.bankid.qrInfo1",
  },
  qrInfo2: {
    id: "sweden.agreement.bankid.qrInfo2",
  },
  qrInfo3: {
    id: "sweden.agreement.bankid.qrInfo3",
  },
  buttonOpen: {
    id: "sweden.agreement.bankid.buttonOpen",
  },
  buttonErrorHeader: {
    id: "sweden.agreement.bankid.buttonErrorHeader",
  },
  buttonRetry: {
    id: "sweden.agreement.bankid.buttonRetry",
  },
  buttonClose: {
    id: "sweden.agreement.bankid.buttonClose",
  },
  buttonOtherDevice: {
    id: "sweden.agreement.bankid.buttonOtherDevice",
  },
});

export const signPendingMessages = defineMessages<
  PendingHintCode,
  { id: string }
>({
  [PendingHintCode.NOCLIENT]: {
    id: "sweden.agreement.bankid.hint.pending.noclient",
  },
  [PendingHintCode.OUTSTANDINGTRANSACTION]: {
    id: "sweden.agreement.bankid.hint.pending.outstandingtransaction",
  },
  [PendingHintCode.STARTED]: {
    id: "sweden.agreement.bankid.hint.pending.started",
  },
  [PendingHintCode.USERSIGN]: {
    id: "sweden.agreement.bankid.hint.pending.usersign",
  },
});

export const signFailedMessages = defineMessages<
  FailedHintCode,
  { id: string }
>({
  [FailedHintCode.CANCELLED]: {
    id: "sweden.agreement.bankid.hint.failed.cancelled",
  },
  [FailedHintCode.CERTIFICATEERR]: {
    id: "sweden.agreement.bankid.hint.failed.certificateerr",
  },
  [FailedHintCode.EXPIREDTRANSACTION]: {
    id: "sweden.agreement.bankid.hint.failed.expiredtransaction",
  },
  [FailedHintCode.STARTFAILED]: {
    id: "sweden.agreement.bankid.hint.failed.startfailed",
  },
  [FailedHintCode.USERCANCEL]: {
    id: "sweden.agreement.bankid.hint.failed.usercancel",
  },
  [FailedHintCode.UNKNOWN]: {
    id: "sweden.agreement.bankid.hint.failed.unknown",
  },
});

export function createGetMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

export function createGetPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

export function createGetFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}
