import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import {
  CardListItem,
  CardList,
  Icon,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { names } from "../../../../names";
import { refreshCompanySignupData } from "../../../../data/signup";

import "./Users.scss";

const NO_ADMIN_ERROR = "Det måste finnas minst en användare som är admin";

export const Users = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const history = useHistory();
  const [error, setError] = useState("");

  useEffect(() => {
    const selectedCompany = contextState.selectedCompany;

    if (!selectedCompany || !contextState.signupId) {
      return;
    }

    if (contextState.users.length > 0) {
      let update = false;

      const users = contextState.users.map((user) => {
        if (user.isEditing) {
          user.isEditing = false;
          update = true;
        }

        return user;
      });

      if (update) {
        updateContext({ users });
      }

      return;
    }

    refreshCompanySignupData(
      selectedCompany.companyId,
      contextState.signupId
    ).then((company) => {
      if (company.users.length > 0) {
        updateContext({ users: company.users });
      }
    });
  }, [
    updateContext,
    contextState.selectedCompany,
    contextState.signupId,
    contextState.users,
  ]);

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  const next = () => {
    if (contextState.users.some((user) => user.admin)) {
      history.push(names.DETAILS);
    } else {
      setError(NO_ADMIN_ERROR);
    }
  };

  return (
    <div className="company-signup-users">
      <h2>Användare</h2>
      <p>Ändra, ta bort eller lägg till användare</p>
      <CardList>
        {contextState.users.map((user) => (
          <CardListItem key={user.identificationNumber}>
            <Link
              key={user.identificationNumber}
              className="user-list-item"
              to={names.ADD_EDIT_USER}
              onClick={() => {
                user.isEditing = true;
                updateContext({ users: contextState.users });
              }}
            >
              <span>{user.name}</span>
              <Icon.Pen />
            </Link>
          </CardListItem>
        ))}
      </CardList>
      {error && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          {error}
        </Snackbar>
      )}
      <Button block onClick={next} label="Gå vidare" />
      <Button
        variant="secondary"
        component={Link}
        to={names.ADD_EDIT_USER}
        block
        label="Lägg till användare"
        icon="Plus"
      />
    </div>
  );
};
