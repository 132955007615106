import { VoidFunctionComponent, useRef, useContext, useEffect } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { useHistory } from "react-router-dom";
import { SustainabilityImportance } from "../../../../data/investments";
import { names } from "../../../../names";
import { SustainabilityQuestion } from "./SustainabilityQuestion";
import { useAdvice } from "../../../../models/AdviceContext";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";

export const Sustainability: VoidFunctionComponent = () => {
  const formRef = useRef<LysaFormRef>();
  const advice = useAdvice();
  const { state: contextState } = useContext(CompanySignupContext);
  const history = useHistory();

  useEffect(() => {
    if (!contextState.signupId) {
      history.replace(names.HOME);
    }
  }, [history, contextState.signupId]);

  const handleSubmit = () => {
    if (formRef.current?.isValid) {
      if (advice.input.sustainability === SustainabilityImportance.IMPORTANT) {
        history.push({
          pathname: names.SUSTAINABILITY_PREFERENCE,
        });
      } else {
        history.push({
          pathname: names.ADVICE,
        });
      }
    }
  };

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <div>
        <SustainabilityQuestion />
        <Button block type="submit" label="Nästa" />
      </div>
    </Form>
  );
};
