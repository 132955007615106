import React, { useCallback, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import {
  Card,
  RadioGroup,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
  Alternative,
  Form,
  LysaFormRef,
  RequiredValidator,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import "./FatcaCheck.scss";

const yesNoAlternatives: Alternative<boolean>[] = [
  { text: "Ja", value: true },
  { text: "Nej", value: false },
];

const optionRequiredValidator = new RequiredValidator(
  "Du måste välja ett alternativ"
);

export const FatcaCheck = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  const onSubmit = useCallback(() => {
    if (formRef.current?.isValid) {
      history.push({
        pathname: names.USERS,
      });
    }
  }, [history]);

  const NON_FINANCIAL_PASSIVE_TEXT =
    "Är företaget icke-finansiellt och räknas som passivt?";

  const FATCA_BLOCK_ERROR =
    "Ett passivt icke-finansiellt företag med verklig huvudman som bor " +
    "i USA, har amerikanskt medborgarskap eller på annat sätt är " +
    "skatt-/deklarationsskyldig i USA kan av skattemässiga skäl inte " +
    "bli kund hos oss.";

  return (
    <div className="company-signup-fatca-check">
      <h2>Skattskyldighet</h2>
      <Form lysaFormRef={formRef} onSubmit={onSubmit}>
        <Card>
          <div className="question-wrapper">
            <RadioGroup
              header={NON_FINANCIAL_PASSIVE_TEXT}
              onChange={(nonFinancialPassive) =>
                updateContext({ nonFinancialPassive })
              }
              alternatives={yesNoAlternatives}
              value={contextState.nonFinancialPassive}
              validators={[optionRequiredValidator]}
            />

            <AnimateHeight
              duration={300}
              height={contextState.nonFinancialPassive?.value ? "auto" : 0}
              animateOpacity
            >
              <div className="signup-fatca-check-error">
                <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                  {FATCA_BLOCK_ERROR}
                </Snackbar>
              </div>
            </AnimateHeight>
          </div>
        </Card>
        <Button
          block
          type="submit"
          disabled={contextState.nonFinancialPassive?.value}
          label="Gå vidare"
        />
      </Form>
      <p className="disclaimer">
        Till finansiella företag räknas t.ex. förvaringsinstitut,
        investeringsinstitut och vissa försäkringsbolag. Till passiva företag
        räknas de som till mer än 50 procent har intäkter från annat än
        försäljning av produkter och/eller tjänster och vars tillgångar till
        största del är finansiella. För mer information hänvisas till lagen
        (2015:62) om identifiering av rapporteringspliktiga konton med anledning
        av FATCA-avtalet och lagen (2015:911) om automatiskt utbyte av
        upplysningar om finansiella konton.
      </p>
    </div>
  );
};
