import React, { VoidFunctionComponent } from "react";
import { DocModalLink } from "@lysaab/ui-2";

interface Props {}

export const InvestmentDisclaimer: VoidFunctionComponent<Props> = () => {
  return (
    <React.Fragment>
      <p className="disclaimer">
        Investering i värdepapper är förknippat med risk. En investering kan
        både öka och minska i värde och det är inte säkert att du får tillbaka
        det investerade kapitalet. Förväntad avkastning utgör inte en
        tillförlitlig indikation om framtida resultat. Förväntad avkastning och
        risk beräknas enligt Lysas{" "}
        <DocModalLink
          document="legal/se/sv/modellantaganden.md"
          modalAnnouncement="Visar dokumentet modellantaganden i en modal"
        >
          modellantaganden
        </DocModalLink>
        .
      </p>
    </React.Fragment>
  );
};
