import { Currency } from "./FakeLocalizationContext";

export interface Step {
  step: number;
  stop: number;
}

export function generateTranslatedArray(intervals: Step[]) {
  if (intervals === undefined || intervals.length === 0) {
    throw new Error("Parameter is undefined or without items");
  }
  const res = [0];
  let intervalIndex = 0;
  const intervalLength = intervals.length;
  let sum = 0;
  for (intervalIndex = 0; intervalIndex < intervalLength; intervalIndex++) {
    const interval = intervals[intervalIndex];
    const step = interval.step;
    const stop = interval.stop;
    while (sum < stop) {
      sum += step;
      res.push(sum);
    }
  }
  return res;
}

export function getAssetsSteps(currency: string) {
  // Euro
  let stepData = [
    {
      step: 100,
      stop: 1000,
    },
    {
      step: 1000,
      stop: 25000,
    },
    {
      step: 5000,
      stop: 100000,
    },
    {
      step: 10000,
      stop: 250000,
    },
    {
      step: 50000,
      stop: 1000000,
    },
    {
      step: 100000,
      stop: 3000000,
    },
  ];

  if (currency === "DKK" || currency === "SEK") {
    stepData = [
      {
        step: 1000,
        stop: 10000,
      },
      {
        step: 5000,
        stop: 100000,
      },
      {
        step: 10000,
        stop: 1000000,
      },
      {
        step: 50000,
        stop: 6000000,
      },
      {
        step: 100000,
        stop: 30000000,
      },
    ];
  }

  return generateTranslatedArray(stepData);
}

export function getMonthlySavingsSteps(currency: string) {
  // Euro
  let stepData = [
    {
      step: 10,
      stop: 100,
    },
    {
      step: 100,
      stop: 3000,
    },
  ];

  if (currency === "DKK" || currency === "SEK") {
    stepData = [
      {
        step: 100,
        stop: 5000,
      },
      {
        step: 500,
        stop: 10000,
      },
      {
        step: 1000,
        stop: 20000,
      },
    ];
  }

  return generateTranslatedArray(stepData);
}

export const INITIAL_INVESTMENT = {
  [Currency.DKK]: 10000,
  [Currency.SEK]: 10000,
  [Currency.EUR]: 1000,
};

export const INITIAL_MONTHLY_SAVINGS = {
  [Currency.DKK]: 2000,
  [Currency.SEK]: 2000,
  [Currency.EUR]: 200,
};
