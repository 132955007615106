import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "query-string";
import { Card, Spinner, FlashContext, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { names } from "../../../names";
import { getSigningInfo, GENERIC_ERROR } from "../../../data/signup";
import "./Creating.scss";

export const Creating = () => {
  const [signupId, setSignupId] = useState("");
  const history = useHistory();
  const location = useLocation();
  const pushFlash = useContext(FlashContext).pushFlash;

  useEffect(() => {
    const params = parse(location.search);
    const signupId = params.creationToken;

    if (!signupId || typeof signupId !== "string") {
      history.replace(names.HOME);
      return;
    }

    setSignupId(signupId);
  }, [location, history, pushFlash]);

  useEffect(() => {
    if (!signupId) {
      return;
    }

    const timerId = setInterval(() => {
      getSigningInfo(signupId)
        .then((info) => {
          if (info.completed) {
            history.push(names.DONE);
          }
        })
        .catch(() => {
          pushFlash({
            type: SNACKBAR_TYPES.ERROR,
            text: GENERIC_ERROR,
          });
        });
    }, 3000);

    return () => {
      clearInterval(timerId);
    };
  }, [signupId, pushFlash, history]);

  return (
    <div className="company-signup-creating">
      <h2>Skapa företags&shy;konto</h2>
      <Card>
        <Spinner />
        <p>Kontot håller på att skapas, ett ögonblick...</p>
      </Card>
    </div>
  );
};
