import React from "react";
import { Fund, FundType, Isin, LargePosition } from "../data/holdings";
import { formatNumber } from "@lysaab/ui-2";

import "./PositionsList.scss";
import { PositionsRemaining } from "./PositionsRemaining";

export type Positions = {
  [key: string]: {
    worth: number;
  };
};

interface Props {
  holdings?: Fund[];
  positions: Positions;
  header: string;
  type: FundType;
}

interface FundWithWorth extends Fund {
  worth: number;
  isin: Isin;
}

export const PositionsList: React.VFC<Props> = ({
  holdings,
  positions,
  header,
  type,
}) => {
  if (!positions || !holdings) {
    return null;
  }

  const positionIsins = Object.keys(positions) as Isin[];

  const funds = positionIsins
    .reduce((funds, isin) => {
      const fund = holdings.find((fund) =>
        fund.fundShareClasses.map(({ isin }) => isin).includes(isin)
      );
      if (fund) {
        funds.push({
          ...fund,
          isin: isin,
          worth: positions[isin].worth,
        });
      }
      return funds;
    }, [] as FundWithWorth[])
    .filter(({ fundType }) => fundType === type)
    .sort((a, b) => b.worth - a.worth);

  return (
    <div className="positions-list">
      <h4>{header}</h4>
      {funds.map((fund) => {
        const shareClass = fund.fundShareClasses.find(
          ({ isin }) => isin === fund.isin
        );
        return (
          <React.Fragment key={fund.isin}>
            {fund.largePositions.map((position) => (
              <PositionsInstrument
                position={position}
                totalWorth={fund.worth}
                key={position.instruments[0].isin}
              />
            ))}
            {fund && (
              <PositionsRemaining
                positions={fund.remainingPositions}
                percent={fund.remainingPositionsPercent}
                totalWorth={fund.worth}
              />
            )}
            <dt className="summary-cell">
              Totalt {shareClass?.name ?? fund.isin}
            </dt>
            <dd className="summary-cell text-right">
              <strong>{formatNumber(fund.worth, 0)} SEK</strong>
            </dd>
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface PositionsInstrumentProps {
  position: LargePosition;
  totalWorth: number;
}

const PositionsInstrument: React.VFC<PositionsInstrumentProps> = ({
  position,
  totalWorth,
}) => {
  const instrumentText = position.instruments
    .map(({ name }) => name)
    .join(", ");

  return (
    <React.Fragment key={position.instruments[0].isin}>
      <dt>
        <span className="name">{instrumentText}</span>
      </dt>
      <dd className="position-value-cell">
        <span className="positions-percent">
          {formatNumber(position.percent, 2)} %
        </span>
        <span className="positions-currency">
          {formatNumber((totalWorth / 100) * position.percent, 2)} SEK
        </span>
      </dd>
    </React.Fragment>
  );
};
