import React, { useCallback, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import {
  Card,
  RadioGroup,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
  Alternative,
  Form,
  LysaFormRef,
  RequiredValidator,
} from "@lysaab/ui-2";
import { names } from "../../../names";

import "./Experience.scss";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { ExperienceCheckbox, VolatilityCheckbox } from "./ExperienceCheckboxes";

const yesNoAlternatives: Alternative<boolean>[] = [
  { text: "Ja", value: true },
  { text: "Nej", value: false },
];

const optionRequiredValidator = new RequiredValidator(
  "Du måste välja ett alternativ"
);

export const Experience = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  const onSubmit = useCallback(() => {
    if (formRef.current?.isValid) {
      history.push({
        pathname: names.PROFIT_AND_RISK,
      });
    }
  }, [history]);

  const EXPERIENCE_TEXT =
    "Har du som företräder företaget tidigare investerat i fonder?";

  const EXPERIENCE_WARNING_TEXT =
    "En fond består av ett flertal olika underliggande värdepapper " +
    "(till exempel aktie- eller ränteinstrument). Som sparare hos Lysa " +
    "blir man andelsägare i fonder och sprider automatiskt risken då " +
    "pengarna inte placeras i ett enskilt värdepapper.";

  const VOLATILITY_TEXT = "Kan man förlora pengar om man investerar i fonder?";

  const VOLATILITY_WARNING_TEXT =
    "Pengarna som sätts in hos Lysa investeras i fonder som i sin tur " +
    "investerar i aktie- och ränteinstrument. Dessa instrument kan " +
    "både gå upp och ner i värde och pengarna man sätter in hos Lysa " +
    "kan gå förlorade.";

  return (
    <div className="company-signup-experience">
      <h2>Erfarenhet och kunskap</h2>
      <p>Erfarenhet och kunskap hos företagets företrädare</p>
      <Form lysaFormRef={formRef} onSubmit={onSubmit}>
        <Card>
          <div className="question-wrapper">
            <RadioGroup
              header={EXPERIENCE_TEXT}
              onChange={(experience) => updateContext({ experience })}
              alternatives={yesNoAlternatives}
              value={contextState.experience}
              validators={[optionRequiredValidator]}
            />

            <AnimateHeight
              duration={300}
              height={contextState.experience?.value === false ? "auto" : 0}
              animateOpacity
            >
              <div className="signup-experience-warning">
                <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
                  {EXPERIENCE_WARNING_TEXT}
                </Snackbar>
                {contextState.experience?.value === false && (
                  <ExperienceCheckbox />
                )}
              </div>
            </AnimateHeight>
          </div>
          <div className="question-wrapper">
            <RadioGroup
              header={VOLATILITY_TEXT}
              onChange={(volatility) => updateContext({ volatility })}
              alternatives={yesNoAlternatives}
              value={contextState.volatility}
              validators={[optionRequiredValidator]}
            />

            <AnimateHeight
              duration={300}
              height={contextState.volatility?.value === false ? "auto" : 0}
              animateOpacity
            >
              <div className="signup-experience-warning">
                <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
                  {VOLATILITY_WARNING_TEXT}
                </Snackbar>
                {contextState.volatility?.value === false && (
                  <VolatilityCheckbox />
                )}
              </div>
            </AnimateHeight>
          </div>
          <Button block type="submit" label="Gå vidare" />
        </Card>
      </Form>
    </div>
  );
};
