import {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import cx from "classnames";
import {
  Card,
  Checkbox,
  RequiredValidator,
  Typography,
  Alternative,
  Button,
  Form,
  LysaFormRef,
  FlashContext,
  SNACKBAR_TYPES,
  Spinner,
  ExpandableSneakPeakCard,
} from "@lysaab/ui-2";
import { sfdrAltMessages } from "./sustainabilityImportant/questions/SFDRQuestion";
import { taxonomyAltMessages } from "./sustainabilityImportant/questions/TaxonomyQuestion";
import { paiAltMessages } from "./sustainabilityImportant/questions/PAIQuestion";
import "./ConfirmEsgUpdate.scss";
import {
  AdviceResponse,
  EsgQuestions,
  getAdvice,
} from "../../../data/investments";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { CompanyModel } from "../../../models/company";
import { useHistory } from "react-router-dom";
import { names } from "../../../names";
import { useAdvice } from "../../../models/AdviceContext";

export const ConfirmEsgUpdate: VoidFunctionComponent = () => {
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();
  const companySignupContext = useContext(CompanySignupContext);
  const adviceContext = useAdvice();
  const pushFlash = useContext(FlashContext).pushFlash;
  const [accept, setAccept] = useState<boolean>(false);
  const [advice, setAdvice] = useState<AdviceResponse>();

  useEffect(() => {
    if (advice) {
      return;
    }

    const adviceData = CompanyModel.getAdviceData(
      companySignupContext.state,
      adviceContext.input
    );

    if (!companySignupContext.state.signupId || !adviceData) {
      history.push(names.HOME);
      return;
    }

    getAdvice(companySignupContext.state.signupId, adviceData)
      .then((response) => {
        setAdvice(response);
      })
      .catch(() => {
        pushFlash({
          type: SNACKBAR_TYPES.ERROR,
          text: "Serverfel. Du måste tyvärr börja om 😞",
        });

        history.push(names.HOME);
      });
  }, [
    advice,
    history,
    pushFlash,
    companySignupContext.state,
    adviceContext.input,
  ]);

  if (!advice || !advice.esgResult.esgBestMatch) {
    return <Spinner />;
  }

  return (
    <Form
      lysaFormRef={formRef}
      className="confirm-esg-update"
      onSubmit={() => {
        if (formRef.current?.isValid) {
          history.push(names.ADVICE);
        }
      }}
    >
      <Typography type="h2">Specifika preferenser</Typography>
      <Typography type="body" className="confirm-esg-update--body">
        Vi kan i dagsläget inte matcha era hållbarhetspreferenser till fullo. Ni
        kan fortsätta med vår nuvarande portfölj med hållbart fokus (artikel 8)
        så meddelar vi er när fler hållbara alternativ finns tillgängliga.
      </Typography>
      <SustainabilityAnswers
        esg={advice?.esgResult.esgAnswers}
        updatedEsg={advice?.esgResult.esgBestMatch}
      />
      <Checkbox<boolean>
        checked={accept}
        alternative={
          {
            text: "Företaget accepterar att preferenserna ändras och vill gå vidare med Lysas hållbara investeringsfokus",
            value: true,
          } as Alternative<boolean>
        }
        onChange={() => setAccept(!accept)}
        validators={[
          new RequiredValidator(
            "Företaget måste acceptera Lysas nya hållbarhetspreferenser för att kunna gå vidare"
          ),
        ]}
      />
      <Button block type="submit" label="Nästa" />
      <ExpandableSneakPeakCard
        buttonOpenText="Visa mer"
        buttonCloseText="Visa mindre"
        className="confirm-esg-update--info"
      >
        <Typography type="h3">Varför kan ni inte matcha?</Typography>
        <Typography type="body">
          Ju mer specifika hållbarhetspreferenser du som kund har, desto färre
          bolag inkluderas i ditt sparande. Det här leder till låg riskspridning
          vilket vi inte kan rekommendera inom ramen för din riskprofil i
          dagsläget.
          <br />
          <br />
          <b>EU:s nya regleringar</b>
          <br />
          <br />
          Värt att nämna är att EU:s regleringar för hållbara investeringar inom
          Disclosureförordningen (SFDR), Taxonomin för hållbara investeringar
          och hänsyn till huvudsakliga negativa konsekvenser (PAI) är nya. För
          att kunna svara upp mot dessa kravbilder krävs en stor mängd data från
          de bolag som ingår i de underliggande fonder som vi på Lysa investerar
          dina pengar i.
          <br />
          <br />
          <b>Bristfällig data</b>
          <br />
          <br />
          I dagsläget är det få bolag och fonder som har börjat rapportera
          enligt dessa nya rapporteringskrav. Det gör i sin tur att vi på Lysa
          inte kan garantera ett sparande enligt dessa principer och dessa
          procentsatser som EU:s nya riktlinjer kräver.
          <br />
          <br />
          <b>Bra med tydliga regler</b>
          <br />
          <br />
          På Lysa tycker vi i grunden att det är bra att EU kommer med en
          lagstiftning som harmoniserar marknaderna och sätter en gemensam
          standard för vad som är hållbart. Detta för att göra det enklare för
          dig som kund att kunna lita på vad som är hållbart på riktigt.
          <br />
          <br />
          <b>Lätt att spara hållbart</b>
          <br />
          <br />
          Vår förhoppning är att rapporteringen och data från finansbranschen
          inom kort kommer att hinna ifatt regelverket, så att vi på Lysa kan
          tillgodose dina önskemål och göra det lätt för dig att spara på det
          sätt som känns rätt för dig.
        </Typography>
      </ExpandableSneakPeakCard>
    </Form>
  );
};

const SustainabilityAnswers: VoidFunctionComponent<{
  esg: EsgQuestions;
  updatedEsg: EsgQuestions;
}> = ({ esg, updatedEsg }) => {
  return (
    <Card>
      <Typography type="h5">Företagets preferenser</Typography>
      <div className="confirm-esg-update--grid-container">
        <span>SFDR</span>
        <span
          className={cx({
            "confirm-esg-update--overridden": esg.sfdr !== updatedEsg.sfdr,
          })}
        >
          <Typography type="label">{sfdrAltMessages[esg.sfdr]}</Typography>
        </span>

        <span>Taxonomin</span>
        <span
          className={cx({
            "confirm-esg-update--overridden":
              esg.taxonomy !== updatedEsg.taxonomy,
          })}
        >
          <Typography type="label">
            {taxonomyAltMessages[esg.taxonomy]}
          </Typography>
        </span>

        <span>PAI</span>
        <span
          className={cx({
            "confirm-esg-update--overridden": esg.pai !== updatedEsg.pai,
          })}
        >
          <Typography type="label">{paiAltMessages[esg.pai]}</Typography>
        </span>
      </div>

      <hr className="confirm-esg-update--card-divider" />

      <Typography type="h5">Kommer att ändras till</Typography>
      <div className="confirm-esg-update--grid-container">
        <span>SFDR</span>
        <span>
          <Typography type="label">
            {sfdrAltMessages[updatedEsg.sfdr]}
          </Typography>
        </span>

        <span>Taxonomin</span>
        <span>
          <Typography type="label">
            {taxonomyAltMessages[updatedEsg.taxonomy]}
          </Typography>
        </span>

        <span>PAI</span>
        <span>
          <Typography type="label">{paiAltMessages[updatedEsg.pai]}</Typography>
        </span>
      </div>
    </Card>
  );
};
