import { useState, useEffect } from "react";
import { getAllocation } from "../../../data/market";
import { Card, Spinner, useDebounceValue } from "@lysaab/ui-2";
import { Fund, FundType, getHoldings } from "../../../data/holdings";
import { PositionsList, Positions } from "../../../components/PositionsList";

import "./EditAllocationPositions.scss";
import { normalizeInvestment } from "./EditAllocation";
import { useAdvice } from "../../../models/AdviceContext";
import { ExampleInvestmentEditor } from "./ExampleInvestmentEditor";

interface Props {
  chosenAllocation: string;
}

const DEBOUNCE_TIMER = 500;

export const EditAllocationPositions = ({ chosenAllocation }: Props) => {
  const [loading, setLoading] = useState(true);
  const [holdings, setHoldings] = useState<Fund[]>();
  const [positions, setPositions] = useState<Positions>({});
  const [investment, setInvestment] = useState(10_000);
  const risk = parseInt(chosenAllocation, 10);
  const advice = useAdvice();
  const investmentType = advice.result.investmentType;

  const normalizedInvestment = normalizeInvestment(investment);

  useEffect(() => {
    getHoldings().then(setHoldings);
  }, []);

  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);

  useEffect(() => {
    getAllocation(debouncedRisk, investmentType).then((allocation) => {
      const pos: Positions = {};
      Object.keys(allocation).forEach((isin) => {
        pos[isin] = { worth: (normalizedInvestment * allocation[isin]) / 100 };
      });
      setPositions(pos);
      setLoading(false);
    });
  }, [debouncedRisk, investmentType, normalizedInvestment]);

  return (
    <Card>
      <ExampleInvestmentEditor
        investment={investment}
        onInvestmentChange={setInvestment}
      />
      <div className="company-signup-edit-allocation-positions">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <PositionsList
              header="Aktier"
              holdings={holdings}
              positions={positions}
              type={FundType.STOCKS}
            />
            <PositionsList
              header="Räntor"
              holdings={holdings}
              positions={positions}
              type={FundType.BONDS}
            />
          </>
        )}

        <div className="disclaimer">
          Fördelningen mellan de underliggande innehaven är en bild av fondens
          nuvarande målinnehav. Fondens faktiska innehav kan avvika från
          målfördelningen t ex på grund av marknadsrörelser, insättningar samt
          uttag i Lysas fonder.
        </div>
      </div>
    </Card>
  );
};
