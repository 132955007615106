import React, { useEffect, useContext, useState } from "react";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { CompanySignupContext } from "../models/CompanySignupContextProvider";
import { Modal } from "./modal/Modal";
import { Typography } from "@lysaab/ui-2";

const checkIfBadAffiliate = async (value: string) => {
  const response = await fetch("https://affiliatelist.lysa.se", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ value: value }),
  });

  if (!response.ok) {
    return;
  }

  const data = await response.json();

  if (data.ok) {
    return true;
  } else {
    return false;
  }
};

export const CampaignTracker: React.FC<{}> = () => {
  const [showBadAffiliate, setShowBadAffiliate] = useState<boolean>(false);
  const location = useLocation();
  const { setState: updateContext } = useContext(CompanySignupContext);

  const handleAffiliate = async (
    campaignString: string,
    updateContext: any
  ) => {
    const isInList = await checkIfBadAffiliate(campaignString);

    if (!isInList) {
      setShowBadAffiliate(false);
      updateContext({ campaign: campaignString });
    } else {
      setShowBadAffiliate(true);
    }
  };

  useEffect(() => {
    const campaign = parse(location.search).campaign;

    if (campaign && typeof campaign === "string") {
      handleAffiliate(campaign.toUpperCase(), updateContext);
    }
  }, [location.search, updateContext]);

  if (showBadAffiliate) {
    return (
      <Modal
        showModal={showBadAffiliate}
        onClose={() => setShowBadAffiliate(false)}
        header="Kampanjen finns inte"
        closeOnOverlayClick
      >
        Denna kampanj är inte längre aktiv och därmed fungerar inte länken som
        du klickat på.
        <Typography>
          <a href="mailto:kontakt@lysa.se">Kontakta oss</a> om du har frågor.
        </Typography>
      </Modal>
    );
  } else {
    return null;
  }
};
