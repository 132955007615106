import { useContext, useRef, useState, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef, Spinner } from "@lysaab/ui-2";
import { PAIQuestion } from "./questions/PAIQuestion";
import { SFDRQuestion } from "./questions/SFDRQuestion";
import { TaxonomyQuestion } from "./questions/TaxonomyQuestion";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import {
  getAdvice,
  isSustainabilityImportantSpecific,
} from "../../../../data/investments";
import { useHistory } from "react-router-dom";
import { names } from "../../../../names";
import "./SustainabilityImportantQuestions.scss";
import { CompanyModel } from "../../../../models/company";
import { useAdvice } from "../../../../models/AdviceContext";

export const SustainabilityImportantQuestions: VoidFunctionComponent = () => {
  const formRef = useRef<LysaFormRef>();
  const companySignupContext = useContext(CompanySignupContext);
  const advice = useAdvice();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  if (!isSustainabilityImportantSpecific(advice.input)) {
    throw new Error(
      "SustainabilityImportantQuestions should only be used with SustainabilityImportance.IMPORTANT"
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form
      className="sustainability-important-questions"
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          const adviceData = CompanyModel.getAdviceData(
            companySignupContext.state,
            advice.input
          );

          if (!companySignupContext.state.signupId || !adviceData) {
            history.push(names.HOME);
            return;
          }

          setLoading(true);

          getAdvice(companySignupContext.state.signupId, adviceData)
            .then((advise) => {
              if (advise.esgResult.esgBestMatch) {
                history.push({
                  pathname: names.CONFIRM_ESG_UPDATE,
                });
              } else {
                history.push({
                  pathname: names.ADVICE,
                });
              }
            })
            .finally(() => setLoading(false));
        }
      }}
    >
      <div>
        <h2>Specifika preferenser</h2>
        <div>
          <SFDRQuestion />
          <TaxonomyQuestion />
          <PAIQuestion />
        </div>
        <Button block type="submit" label="Nästa" />
      </div>
    </Form>
  );
};
