import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Refocus.scss";

export const Refocus = () => {
  const location = useLocation();
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    divRef.current?.focus();
  });

  return (
    <div ref={divRef} tabIndex={-1} className="refocus-tab-nav-fix">
      Navigerade till {location.pathname}
    </div>
  );
};
