import React, {
  FunctionComponent,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  EmailInput,
  EmailValidator,
  RequiredValidator,
  Form,
  LysaFormRef,
  FlashContext,
  SNACKBAR_TYPES,
  CheckmarkButton,
} from "@lysaab/ui-2";
import { shareByEmail } from "../../../data/signup";

import "./AgreementEmail.scss";

const EMAIL_SHARE_FAIL =
  "Ajdå, något gick fel :( Ladda om sidan och försök igen";

interface SigningEmailsProps {
  signupId: string;
}

export const AgreementEmails: FunctionComponent<SigningEmailsProps> = ({
  signupId,
}) => {
  const [emails, setEmails] = useState([""]);

  const onSave = useCallback(
    (email: string) => setEmails([email, ...emails]),
    [emails]
  );

  return (
    <>
      {emails.map((email, index) => (
        <AgreementEmail
          key={"email-" + index}
          signupId={signupId}
          onSave={onSave}
          value={email}
          disabled={index < emails.length - 1}
        />
      ))}
    </>
  );
};

interface SigningEmailProps {
  onSave: (value: string) => void;
  value: string;
  disabled: boolean;
  signupId: string;
}

export const AgreementEmail: FunctionComponent<SigningEmailProps> = ({
  onSave,
  value,
  disabled,
  signupId,
}) => {
  const [email, setEmail] = useState(value);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const flashContext = useContext(FlashContext);

  const formRef = React.useRef<LysaFormRef>();

  const onSubmit = useCallback(() => {
    if (!formRef.current?.isValid || !signupId || loading) {
      return;
    }

    setLoading(true);

    shareByEmail(email, signupId)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);

        setSuccess(true);
        onSave(email);
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
        flashContext.pushFlash({
          type: SNACKBAR_TYPES.ERROR,
          text: EMAIL_SHARE_FAIL,
        });
      });
  }, [onSave, email, signupId, flashContext, loading]);

  return (
    <Form className="agreement-email" lysaFormRef={formRef} onSubmit={onSubmit}>
      <div className="email-input">
        <EmailInput
          label="E-post"
          onChange={setEmail}
          value={email}
          disabled={disabled}
          validators={[
            new EmailValidator("Felaktig emailadress"),
            new RequiredValidator("Du måste fylla i en emailadress"),
          ]}
        />
      </div>
      <div className="save-button">
        <CheckmarkButton
          type="submit"
          disabled={!loading && disabled}
          isSuccessful={success}
        >
          Maila
        </CheckmarkButton>
      </div>
    </Form>
  );
};
