import React, { useCallback, useContext } from "react";
import { EntityType, IsPepQuestion, PepType } from "@lysaab/lysa-pep";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import { RegisteredOwner, SuggestedOwner } from "../../../../data/signup";
import { Spinner } from "@lysaab/ui-2";

import "./PepCard.scss";

interface PepCardProps {
  owner: RegisteredOwner | SuggestedOwner;
  isValidating?: "YES" | "NO" | "DONE" | undefined;
}

export const PepCard = ({ owner, isValidating }: PepCardProps) => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const onTypeChange = useCallback(
    (type: PepType, id?: number) => {
      // TODO: use callback form of updateContext

      const designatedOwner = contextState.designatedOwner;
      const registeredOwners = [...contextState.registeredOwners];

      if (designatedOwner) {
        updateContext({
          designatedOwner: {
            ...designatedOwner,
            pep: { ...designatedOwner.pep, type },
          },
        });
      } else {
        const currentOwner = registeredOwners.find((owner) => owner.id === id);

        if (currentOwner) {
          currentOwner.pep = { ...currentOwner.pep, type };
        }

        updateContext({ registeredOwners });
      }
    },
    [contextState, updateContext]
  );

  const id = "id" in owner ? owner.id : undefined;

  const classes =
    "card pep-card" +
    (isValidating === "YES" ? " is-validating" : "") +
    (isValidating === "DONE" ? " done-validating" : "");

  return (
    <div className={classes}>
      <h3 className="pep-card-header">{owner.name}</h3>
      <IsPepQuestion
        entityType={EntityType.CORPORATION}
        ownerName={owner.name}
        type={owner.pep?.type}
        onChange={(type) => onTypeChange(type, id)}
      />
      {isValidating === "YES" && (
        <div className="pep-card-validating">
          <Spinner />
          <p>
            <span>Kontrollerar svar...</span>
          </p>
        </div>
      )}
      {isValidating === "DONE" && (
        <div className="pep-card-done-validating">
          <p>Klar</p>
        </div>
      )}
    </div>
  );
};
