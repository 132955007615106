import { VoidFunctionComponent, useRef, useEffect } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { useHistory } from "react-router-dom";
import {
  isSustainabilityImportantSpecific,
  SustainabilityImportance,
} from "../../../../data/investments";
import { names } from "../../../../names";
import { SustainabilityPreferenceQuestion } from "./SustainabilityPreferenceQuestion";
import { useAdvice } from "../../../../models/AdviceContext";

export const SustainabilityPreference: VoidFunctionComponent = () => {
  const formRef = useRef<LysaFormRef>();
  const advice = useAdvice();
  const history = useHistory();

  useEffect(() => {
    if (advice.input.sustainability !== SustainabilityImportance.IMPORTANT) {
      history.replace({
        pathname: names.SUSTAINABILITY,
      });
    }
  }, [advice.input.sustainability, history]);

  const handleSubmit = () => {
    if (formRef.current?.isValid) {
      if (isSustainabilityImportantSpecific(advice.input)) {
        history.push({
          pathname: names.SUSTAINABILITY_QUESTIONS,
        });
      } else {
        history.push({
          pathname: names.ADVICE,
        });
      }
    }
  };

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <div>
        <SustainabilityPreferenceQuestion />
        <Button block type="submit" label="Nästa" />
      </div>
    </Form>
  );
};
