import React, { FunctionComponent } from "react";
import { RegisteredOwner } from "../../../../data/signup";
import { countries } from "@lysaab/shared";
import { CompanyModel } from "../../../../models/company";

interface Props {
  owner: RegisteredOwner;
}

function getCountryName(code: string) {
  const country = countries.sv.find((country) => country.value === code);
  return country ? country.label : code;
}

export const OwnerTable: FunctionComponent<Props> = ({ owner }) => {
  return (
    <table>
      <tbody>
        <tr>
          <th>För- och efternamn</th>
          <td>{owner.name}</td>
        </tr>
        <tr>
          <th>Medborgarskap</th>
          <td>{getCountryName(owner.citizenship)}</td>
        </tr>
        <tr>
          <th>Skattehemvist</th>
          <td>{getCountryName(owner.taxDomicile)}</td>
        </tr>
        <tr>
          <th>Omfattning av kontroll</th>
          <td>
            {CompanyModel.COMPANY_CONTROLLING_SHARE[owner.controllingShare]}
          </td>
        </tr>
        <tr>
          <th>Typ av kontroll</th>
          <td>
            <ul>
              {owner.controlTypes.map((controlType) => (
                <li key={controlType}>
                  {CompanyModel.COMPANY_CONTROL_TYPES[controlType]}
                </li>
              ))}
            </ul>
          </td>
        </tr>
        {owner.controllingCompanies &&
          owner.controllingCompanies.length > 0 && (
            <tr>
              <th>Kontroll via</th>
              <td>
                <ul>
                  {owner.controllingCompanies.map((company) => (
                    <li key={company}>{company}</li>
                  ))}
                </ul>
              </td>
            </tr>
          )}
      </tbody>
    </table>
  );
};
