import { State } from "./CompanySignupContextProvider";

/**
 * Sorry about the naming of this function, but it's a tri-state boolean, so
 * what can you do? :shrug
 */
export function isNotOwnMoney(state: State) {
  return state.ownMoney?.value === false;
}

export function isValidKyc(state: State) {
  return state.ownMoney?.value === true;
}
