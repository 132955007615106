import React, { useContext, useCallback } from "react";
import { Language, Story, WorldCountry, useBeforeunload } from "@lysaab/ui-2";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  RouteProps,
  Redirect,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { names } from "../../names";
import { Identify } from "./subpages/Identify";
import { CompanySelection } from "./subpages/company-selection/CompanySelection";
import { Experience } from "./subpages/Experience";
import { BeneficialOwners } from "./subpages/beneficialOwners/BeneficialOwners";
import { Users } from "./subpages/users/Users";
import { AddEditUser } from "./subpages/users/AddEditUser";
import { CompanySignupContext } from "../../models/CompanySignupContextProvider";
import { Details } from "./subpages/Details";
import { Kyc } from "./subpages/Kyc";
import { ProfitAndRisk } from "./subpages/ProfitAndRisk";
import { Situation } from "./subpages/Situation";
import { Horizon } from "./subpages/Horizon";
import { Advice } from "./subpages/Advice";
import { Creating } from "./subpages/Creating";
import { ManualReview } from "./subpages/ManualReview";
import { Done } from "./subpages/Done";
import {
  EditAllocation,
  ROUTES as EditAllocationRoutes,
  RouteMap,
} from "./subpages/EditAllocation";
import { Agreement } from "./subpages/Agreement";
import {
  PepContextProvider,
  RelationPage,
  PepStatusPage,
  PepRolePage,
  IsPepPage,
  PepContext,
  PepType,
  PepState,
  stateToRequest,
  PepContextProps,
} from "@lysaab/lysa-pep";
import {
  PepOwnerSelection,
  pepStateWithType,
} from "./subpages/pep/PepOwnerSelection";
import { PepConfirmation } from "./subpages/pep/PepConfirmation";

import "./CompanySignup.scss";
import { Warning } from "./subpages/Warning";
import { Summary } from "./subpages/Summary";
import { OwnerInfo } from "./subpages/OwnerInfo";
import { FatcaCheck } from "./subpages/FatcaCheck";
import { ElevioLoader } from "../../components/ElevioLoader";
import { Sustainability } from "./subpages/sustainability/Sustainability";
import { SustainabilityImportantQuestions } from "./subpages/sustainabilityImportant/SustainabilityImportantQuestions";
import { SustainabilityPreference } from "./subpages/sustainabilityPreference/SustainabilityPreference";
import { ConfirmEsgUpdate } from "./subpages/ConfirmEsgUpdate";
import { BlockingAnswersAccessGuard } from "../../components/accessGuards/BlockingAnswersAccessGuard";
import { isValidSituation } from "../../models/situation";
import { isValidKyc } from "../../models/kyc";
import { AdviceGuard } from "../../components/adviceGuard/AdviceGuard";
import { ContactFooter } from "../../components/ContactFooter";

const WARNING_TEXT =
  "Säker på att du vill avsluta? Dina uppgifter sparas inte.";

type RouteConfigExtras = {
  subRoutes?: RouteMap;
  skipPreventUnload?: boolean;
  hideBackButton?: boolean;
};

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
type RoutePropsNoArray = Overwrite<RouteProps, { path: string }>;

type RouteMapTop = {
  [key: string]: RoutePropsNoArray & RouteConfigExtras;
};

export const CompanySignup = () => {
  const history = useHistory();
  const location = useLocation();
  const trimmedLocation = location.pathname.replace(/^(.+?)\/*?$/, "$1");
  const {
    state: contextState,
    setState: updateContext,
    clearContext,
  } = useContext(CompanySignupContext);

  const saveToOwner = useCallback(
    (pepState: PepState) => {
      const designatedOwner = contextState.designatedOwner;
      const registeredOwners = [...contextState.registeredOwners];

      if (designatedOwner) {
        updateContext({
          designatedOwner: {
            ...designatedOwner,
            pep: stateToRequest(pepState),
          },
        });
      } else {
        const currentOwner = registeredOwners.find(
          (owner) => owner.id.toString() === pepState.tin
        );

        if (currentOwner) {
          currentOwner.pep = stateToRequest(pepState);
        }

        updateContext({ registeredOwners });
      }
    },
    [contextState.designatedOwner, contextState.registeredOwners, updateContext]
  );

  const nextOwnerOrConfirm = useCallback(
    (pepContext: PepContextProps) => {
      const designatedOwner = contextState.designatedOwner;
      const registeredOwners = [...contextState.registeredOwners];

      if (designatedOwner?.pep) {
        // If we have a designated owner we have just now answered the
        // questions for the one and only designated owner
        history.push(names.PEP_CONFIRM);
        return;
      }

      const nextOwner = registeredOwners.find((owner) => {
        if (!owner.pep) {
          return true;
        }

        if (owner.pep.type === PepType.NOT_PEP) {
          return false;
        }

        if (typeof owner.pep.tin === "undefined") {
          return true;
        }

        return false;
      });

      if (nextOwner) {
        const nextState = pepStateWithType(nextOwner);
        pepContext.setState(nextState);
        if (nextState.type === PepType.ME) {
          history.push(names.PEP_STATUS);
        } else {
          history.push(names.PEP_RELATION);
        }
        return;
      }

      history.push(names.PEP_CONFIRM);
    },
    [contextState.designatedOwner, contextState.registeredOwners, history]
  );

  const ROUTES: RouteMapTop = {
    IDENTIFY: {
      path: names.HOME, // TODO: Rename to identify?
      component: Identify,
      exact: true,
      skipPreventUnload: true,
      hideBackButton: true,
    },
    COMPANY_SELECTION: {
      path: names.COMPANY_SELECTION,
      component: CompanySelection,
    },
    BENEFICIAL_OWNERS: {
      path: names.BENEFICIAL_OWNERS,
      component: BeneficialOwners,
    },
    OWNER_INFO: {
      path: names.OWNER_INFO,
      component: OwnerInfo,
    },
    FATCA_CHECK: {
      path: names.FATCA_CHECK,
      component: FatcaCheck,
    },
    USERS: {
      path: names.USERS,
      component: Users,
    },
    ADD_EDIT_USER: {
      path: names.ADD_EDIT_USER,
      component: AddEditUser,
    },
    DETAILS: {
      path: names.DETAILS,
      component: Details,
    },
    PEP: {
      path: names.PEP,
      exact: true,
      component: PepOwnerSelection,
    },
    PEP_IS_PEP: {
      path: names.PEP_IS_PEP,
      exact: true,
      render: () => (
        <PepContext.Consumer>
          {(pepContext) => (
            <IsPepPage
              next={() => {
                if (pepContext.state.type === PepType.ME) {
                  Sentry.addBreadcrumb({
                    category: "PEP",
                    message: "Changed PEP to ME",
                  });
                  history.push(names.PEP_STATUS);
                } else if (pepContext.state.type === PepType.NOT_PEP) {
                  Sentry.addBreadcrumb({
                    category: "PEP",
                    message: "Changed PEP to NOT_PEP",
                  });
                  saveToOwner(pepContext.state);
                  // PEP_IS_PEP is only used when editing an
                  // owner, so always go to confirm
                  history.push(names.PEP_CONFIRM);
                } else {
                  Sentry.addBreadcrumb({
                    category: "PEP",
                    message: "Changed PEP to COLLEAGUE or RELATIVE",
                  });
                  history.push(names.PEP_RELATION);
                }
              }}
            />
          )}
        </PepContext.Consumer>
      ),
    },
    PEP_RELATION: {
      path: names.PEP_RELATION,
      exact: true,
      render: () => (
        <PepContext.Consumer>
          {(pepContext) => (
            <RelationPage
              next={() => {
                if (pepContext.state.type === PepType.RELATIVE) {
                  Sentry.addBreadcrumb({
                    category: "PEP",
                    message: `PEP relation was ${pepContext.state.relationToPEP}`,
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: "PEP",
                    message: `PEP colleague relation was ${pepContext.state.businessRelation}`,
                  });
                }
                history.push(names.PEP_STATUS);
              }}
            />
          )}
        </PepContext.Consumer>
      ),
    },
    PEP_STATUS: {
      path: names.PEP_STATUS,
      exact: true,
      render: () => (
        <PepContext.Consumer>
          {(pepContext) => (
            <PepStatusPage
              countryCode={WorldCountry.SWEDEN}
              countryName="Sverige"
              next={() => {
                Sentry.addBreadcrumb({
                  category: "PEP",
                  message: `PEP status was ${
                    pepContext.state.isOngoing ? "ongoing" : "not ongoing"
                  }`,
                });
                history.push(names.PEP_ROLE);
              }}
              language={Language.SWEDISH}
            />
          )}
        </PepContext.Consumer>
      ),
    },
    PEP_ROLE: {
      path: names.PEP_ROLE,
      exact: true,
      render: () => (
        <PepContext.Consumer>
          {(pepContext) => (
            <PepRolePage
              next={() => {
                Sentry.addBreadcrumb({
                  category: "PEP",
                  message: `PEP role was ${
                    pepContext.state.role ? "set" : "not set"
                  }`,
                });
                saveToOwner(pepContext.state);
                nextOwnerOrConfirm(pepContext);
              }}
              language={Language.SWEDISH}
            />
          )}
        </PepContext.Consumer>
      ),
    },
    PEP_CONFIRM: {
      path: names.PEP_CONFIRM,
      exact: true,
      component: PepConfirmation,
    },
    KYC: {
      path: names.KYC,
      component: Kyc,
    },
    EXPERIENCE: {
      path: names.EXPERIENCE,
      render: () => (
        // This block is to prevent bypassing required questions in Kyc
        // by using the browser back and forward buttons
        <BlockingAnswersAccessGuard route={names.KYC} isValid={isValidKyc}>
          <Experience />
        </BlockingAnswersAccessGuard>
      ),
    },
    PROFIT_AND_RISK: {
      path: names.PROFIT_AND_RISK,
      component: ProfitAndRisk,
    },
    SITUATION: {
      path: names.SITUATION,
      component: Situation,
    },
    HORIZON: {
      path: names.HORIZON,
      render: () => (
        // This block is to prevent bypassing required questions in Situation
        // by using the browser back and forward buttons
        <BlockingAnswersAccessGuard
          route={names.SITUATION}
          isValid={isValidSituation}
        >
          <Horizon />
        </BlockingAnswersAccessGuard>
      ),
    },
    SUSTAINABILITY: {
      path: names.SUSTAINABILITY,
      component: Sustainability,
    },
    SUSTAINABILITY_PREFERENCE: {
      path: names.SUSTAINABILITY_PREFERENCE,
      component: SustainabilityPreference,
    },
    SUSTAINABILITY_QUESTIONS: {
      path: names.SUSTAINABILITY_QUESTIONS,
      component: SustainabilityImportantQuestions,
    },
    CONFIRM_ESG_UPDATE: {
      path: names.CONFIRM_ESG_UPDATE,
      component: ConfirmEsgUpdate,
    },
    ADVICE: {
      path: names.ADVICE,
      component: Advice,
    },
    EDIT_ALLOCATION: {
      path: names.EDIT_ALLOCATION,
      component: EditAllocation,
      subRoutes: EditAllocationRoutes,
    },
    WARNING: {
      path: names.WARNING,
      component: Warning,
    },
    SUMMARY: {
      path: names.SUMMARY,
      render: () => (
        <BlockingAnswersAccessGuard route={names.KYC} isValid={isValidKyc}>
          <BlockingAnswersAccessGuard
            route={names.SITUATION}
            isValid={isValidSituation}
          >
            <AdviceGuard adviceRoute={names.ADVICE}>
              <Summary />
            </AdviceGuard>
          </BlockingAnswersAccessGuard>
        </BlockingAnswersAccessGuard>
      ),
    },
    AGREEMENT: {
      path: names.AGREEMENT,
      component: Agreement,
      skipPreventUnload: true,
      hideBackButton: true,
    },
    CREATING: {
      path: names.CREATING,
      component: Creating,
      skipPreventUnload: true,
      hideBackButton: true,
    },
    MANUAL_REVIEW: {
      path: names.MANUAL_REVIEW,
      component: ManualReview,
      skipPreventUnload: true,
      hideBackButton: true,
    },
    DONE: {
      path: names.DONE,
      component: Done,
      skipPreventUnload: true,
      hideBackButton: true,
    },
  };

  const routeValues = Object.values(ROUTES);
  const progressMap: { [key: string]: number } = {};
  routeValues.forEach((route, index) => {
    progressMap[route.path] = index;

    if (route.subRoutes) {
      const subRoutesValues = Object.values(route.subRoutes);
      subRoutesValues.forEach((subRoute) => {
        progressMap[subRoute.path] = index;
      });
    }
  });

  const routeIndex = progressMap[trimmedLocation] || 0;
  const progress = (100 / routeValues.length) * (routeIndex + 1);

  const handleUnload = useCallback(() => {
    const currentRoute = routeValues[routeIndex];
    if (currentRoute.skipPreventUnload) {
      return null;
    } else {
      return WARNING_TEXT;
    }
  }, [routeIndex, routeValues]);

  useBeforeunload(handleUnload);

  return (
    <div className="company-signup-page">
      <PepContextProvider>
        <Story
          ariaLabelProgress={() =>
            `Steg ${routeIndex} av ${routeValues.length} i bli kund`
          }
          header="Ny företagskund"
          showBack={routeValues[routeIndex].hideBackButton !== true}
          onBack={() => history.goBack()}
          onExit={() => {
            clearContext();
            history.push(names.HOME);
          }}
          showClose={false}
          transitionKey={routeIndex.toString()}
          progress={progress}
        >
          <div {...{ order: routeIndex }}>
            <Switch location={location}>
              {Object.entries(ROUTES).map(([key, value]) => (
                <Route {...value} key={key} />
              ))}
              <Redirect to={names.HOME} />
            </Switch>
            <ContactFooter />
          </div>
        </Story>
      </PepContextProvider>
      <ElevioLoader />
    </div>
  );
};
