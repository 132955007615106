import React, { useState, FunctionComponent } from "react";
import { countries } from "@lysaab/shared";
import { Alternative, Select } from "@lysaab/ui-2";
import { ValidationProps } from "@lysaab/ui-2/hooks/useValidation";

export interface Country {
  label: string;
  value: string;
}

interface Props extends ValidationProps {
  value?: string;
  onChange?(value: Alternative<string>): void;
  label: string;
}

export const CountrySelect: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ value, ...props }) => {
  const [countryAlternatives] = useState(
    countries.sv.map((country) => ({
      text: country.label,
      value: country.value,
    }))
  );

  return (
    <Select
      alternatives={countryAlternatives}
      placeholder={"Välj ett land"}
      value={countryAlternatives.find((country) => country.value === value)}
      {...props}
    />
  );
};
