import { API, InvestmentType } from "@lysaab/ui-2";

export interface GetFeesResponse {
  future: {
    discretionary: number;
    fundManagement: number;
    fundAssets: number;
    transactionFees: number;
    total: number;
  };
  cost: {
    discretionary: number;
    fundManagement: number;
    fundAssets: number;
    transactionFees: number;
    total: number;
  };
}

export function getFees(
  amount: number,
  risk: number,
  investmentType: InvestmentType
) {
  const url =
    `/fees/estimate/yearly?amount=${amount}&risk=${risk}` +
    `&investmentType=${investmentType}&accountType=VP_SWE&country=SE`;

  return API.get<GetFeesResponse>(url);
}
