import { formatNumber, Icon } from "@lysaab/ui-2";
import { SmallPosition } from "../data/holdings";

interface Props {
  positions: SmallPosition[];
  percent: number;
  totalWorth?: number;
}

export const PositionsRemaining: React.VFC<Props> = ({
  positions,
  percent,
  totalWorth,
}) => {
  if (positions.length === 0) {
    return null;
  }

  return (
    <>
      <dt>
        <Icon.Add size={10} /> {positions.length} mindre innehav
      </dt>
      <dd>
        <span>{formatNumber(percent, 2)} %</span>
        <span className="positions-currency">
          {totalWorth && (
            <>{formatNumber((totalWorth / 100) * percent, 2)} SEK</>
          )}
        </span>
      </dd>
    </>
  );
};
