import React, { useState, useContext, useEffect } from "react";
import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import * as Sentry from "@sentry/react";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import { useHistory } from "react-router-dom";
import {
  PepContext,
  EntityType,
  PepType,
  PepState,
  EMPTY_PEP_STATE,
} from "@lysaab/lysa-pep";
import { names } from "../../../../names";
import "./PepOwnerSelection.scss";
import { PepCard } from "./PepCard";
import {
  RegisteredOwner,
  SuggestedOwner,
  GENERIC_ERROR,
} from "../../../../data/signup";
import { validatePep } from "../../../../data/kyc";

export function pepStateWithType(
  owner: RegisteredOwner | SuggestedOwner
): PepState {
  return {
    ...EMPTY_PEP_STATE,
    type: owner.pep?.type,
    legalEntityType: EntityType.CORPORATION,
    tin: "id" in owner ? owner.id.toString() : undefined,
    ownerName: owner.name,
  };
}

function hasAllAnswered(
  registeredOwners: RegisteredOwner[],
  designatedOwner?: SuggestedOwner
) {
  return designatedOwner?.pep || registeredOwners.every((owner) => !!owner.pep);
}

function allAreNotPep(
  registeredOwners: RegisteredOwner[],
  designatedOwner?: SuggestedOwner
) {
  if (designatedOwner?.pep) {
    return designatedOwner.pep.type === PepType.NOT_PEP;
  }

  return registeredOwners.every((owner) => owner.pep?.type === PepType.NOT_PEP);
}

function firstPepOwner(
  registeredOwners: RegisteredOwner[],
  designatedOwner?: SuggestedOwner
) {
  if (designatedOwner?.pep && designatedOwner.pep.type !== PepType.NOT_PEP) {
    return designatedOwner;
  }

  return registeredOwners.find(
    (owner) => owner.pep && owner.pep.type !== PepType.NOT_PEP
  );
}

export const PepOwnerSelection = () => {
  const signupContext = useContext(CompanySignupContext);
  const pepContext = useContext(PepContext);
  const history = useHistory();
  const [error, setError] = useState<string>();
  const designatedOwner = signupContext.state.designatedOwner;
  const registeredOwners = signupContext.state.registeredOwners;
  const [validatingIds, setValidatingIds] = useState<{
    [key: string]: "YES" | "NO" | "DONE" | undefined;
  }>({});

  useEffect(() => {
    if (!signupContext.state.signupId) {
      history.push(names.HOME);
      return;
    }
  }, [signupContext.state.signupId, history]);

  useEffect(() => {
    setError(undefined);
  }, [registeredOwners, designatedOwner]);

  function validatePepAnswers(
    registeredOwners: RegisteredOwner[],
    designatedOwner?: SuggestedOwner
  ) {
    const validations = [];

    if (designatedOwner?.pep) {
      setValidatingIds({ [designatedOwner.name]: "YES" });
      validations.push(
        validatePep(pepStateWithType(designatedOwner)).then(() => {
          setValidatingIds({ [designatedOwner.name]: "DONE" });

          // TODO:
          // * Handle response.status === VALIDATION_STATUS.OK
          // * Handle !OK
        })
      );

      signupContext.setState({ designatedOwner });
    } else {
      registeredOwners.forEach((owner) => {
        setValidatingIds((old) => ({ ...old, [owner.id]: "YES" }));

        validations.push(
          validatePep(pepStateWithType(owner)).then(() => {
            setValidatingIds((old) => ({
              ...old,
              [owner.id]: "DONE",
            }));

            // TODO:
            // * Handle response.status === VALIDATION_STATUS.OK
            // * Handle !OK
          })
        );
      });

      signupContext.setState({ registeredOwners });
    }

    Promise.all(validations).then(() => {
      setTimeout(() => {
        Sentry.addBreadcrumb({
          category: "PEP",
          message: "No owners are PEP",
        });
        history.push(names.KYC);
      }, 500);
    });
  }

  // TODO: Handle pre-populated pep info
  return (
    <div className="owner-selection">
      <h2>Politiskt utsatt ställning (PEP)</h2>

      {registeredOwners.map((owner) => (
        <PepCard
          key={owner.id}
          owner={owner}
          isValidating={validatingIds[owner.id.toString()]}
        />
      ))}

      {designatedOwner && (
        <PepCard
          owner={designatedOwner}
          isValidating={validatingIds[designatedOwner.name]}
        />
      )}

      {error && <Snackbar type={SNACKBAR_TYPES.ERROR}>{error}</Snackbar>}

      <Button
        block
        onClick={() => {
          if (!hasAllAnswered(registeredOwners, designatedOwner)) {
            setError("Det saknas svar för att kunna gå vidare");
            return;
          }

          if (allAreNotPep(registeredOwners, designatedOwner)) {
            validatePepAnswers(registeredOwners, designatedOwner);
            return;
          }

          const owner = firstPepOwner(registeredOwners, designatedOwner);

          if (!owner) {
            setError(GENERIC_ERROR);
            return;
          }

          const pepState = pepStateWithType(owner);
          pepContext.setState(pepState);

          switch (pepState.type) {
            case PepType.ME:
              Sentry.addBreadcrumb({
                category: "PEP",
                message: "First owner is PEP",
              });
              history.push(names.PEP_STATUS);
              return;
            case PepType.COLLEAGUE:
              Sentry.addBreadcrumb({
                category: "PEP",
                message: "First owner has PEP colleague",
              });
              history.push(names.PEP_RELATION);
              break;
            case PepType.RELATIVE:
              Sentry.addBreadcrumb({
                category: "PEP",
                message: "First owner has PEP relative",
              });
              history.push(names.PEP_RELATION);
              return;
            default:
              setError(GENERIC_ERROR);
          }
        }}
        disabled={Object.values(validatingIds).some(
          (status) => status === "YES"
        )}
        label="Gå vidare"
      />
    </div>
  );
};
