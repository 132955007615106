import { PepType, PepRequest } from "@lysaab/lysa-pep";
import { API, encode, InvestmentType } from "@lysaab/ui-2";
import { ALTERNATIVE_TYPE, QuestionAlternative } from "../components/Question";
import { Company } from "./bankid";
import { AccountQuestions } from "./investments";

export const GENERIC_ERROR =
  "Ajdå, något gick fel 😟 Ladda om sidan och försök igen ✨";

export enum CONTROLLING_SHARE {
  SHARES_0_25 = "SHARES_0_25",
  SHARES_25_50 = "SHARES_25_50",
  SHARES_50_75 = "SHARES_50_75",
  SHARES_75_100 = "SHARES_75_100",
  SHARES_100 = "SHARES_100",
}

export enum CONTROL_TYPES {
  ART_10 = "ART_10",
  ART_14 = "ART_14",
  ART_20 = "ART_20",
  ART_30 = "ART_30",
  ART_40 = "ART_40",
  ART_74 = "ART_74",
}

export enum ROLE {
  CEO = "CEO",
  EXTERNAL_CEO = "EXTERNAL_CEO",
  BOARD_CHAIRMAN = "BOARD_CHAIRMAN",
  BOARD_MEMBER = "BOARD_MEMBER",
  ALTERNATE = "ALTERNATE",
  PARTNER = "PARTNER",
  GENERAL_PARTNER = "GENERAL_PARTNER",
  LIMITED_PARTNER = "LIMITED_PARTNER",
  EXTERNAL_SIGNATORY = "EXTERNAL_SIGNATORY",
}

export enum DESIGNATED_ROLE {
  CEO = "CEO",
  EXTERNAL_CEO = "EXTERNAL_CEO",
  BOARD_CHAIRMAN = "BOARD_CHAIRMAN",
}

export enum ACCOUNT_TYPE {
  VP = "VP",
}

export enum SIGNATURES_REQUIRED {
  ALL = "AlL",
  MAJORITY = "MAJORITY",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
}

export enum SIGNING_STATUS {
  PENDING_SIGN = "PENDING_SIGN", // No one has signed
  SIGNING_STARTED = "SIGNING_STARTED", // At least one has signed
  MANUAL_REVIEW = "MANUAL_REVIEW", // Someone at Lysa has to manually review the application
  CREATING = "CREATING", // BE is processing
  COMPLETE = "COMPLETE", // All done. `complete` will also be set to `true`
}

export interface Question {
  type: ALTERNATIVE_TYPE;
  id: string;
  question: string;
  alternatives: QuestionAlternative[];
}

export interface RegisteredOwner {
  id: number;
  name: string;
  controllingShare: CONTROLLING_SHARE;
  controlTypes: CONTROL_TYPES[];
  controllingCompanies?: string[];
  taxDomicile: string;
  citizenship: string;
  pep?: PepRequest;
}

export interface SuggestedOwner {
  name: string;
  role: DESIGNATED_ROLE;
  pep?: PepRequest;
  taxDomicile?: string;
  citizenship?: string;
}

export interface User {
  identificationNumber: string;
  name: string;
  email: string;
  admin: boolean;
  isEditing?: boolean;
}

export interface PostCompanyResponse {
  alreadySaved?: boolean;
  companyName: string;
  companyId: string;
  bankAccount?: string;
  bank?: string;
  kyc: {
    company: Question[];
    account: Question[];
  };
  contactInformation: {
    name?: string;
    phone?: string;
    email?: string;
  };
  owners: {
    registered: RegisteredOwner[];
    suggested: SuggestedOwner[];
  };
  users: User[];
}

export interface PostNameResponse {
  name: string;
}

export interface Signer {
  id: number;
  name: string;
  confirmed: boolean;
}

export interface Owner extends Signer {
  pep: {
    type: PepType;
  };
  designated: boolean;
}

export interface Signee extends Signer {
  roles: ROLE[];
}

export interface SigningInfoResponse {
  completed: boolean;
  status: SIGNING_STATUS;
  updated?: string;
  expires?: string;
  companyId?: string;
  name?: string;
  summary?: {
    registrator: string;
    accountType: ACCOUNT_TYPE;
    takenRisk: number;
    email: string;
    investmentType: InvestmentType;
  };
  users?: {
    name: string;
    admin: boolean;
  }[];
  owners?: Owner[];
  signingGroups?: {
    signingText: string;
    signaturesRequired: SIGNATURES_REQUIRED;
    signees: Signee[];
  }[];
}

interface QuestionAlternativeAnswer {
  checked: boolean;
  value: string;
}

interface QuestionAnswer {
  id: string;
  alternatives: QuestionAlternativeAnswer[];
}

export interface SaveRegisteredOwner {
  id: number;
  pep: PepRequest;
}

export interface SaveDesignatedOwner {
  role: DESIGNATED_ROLE;
  taxDomicile: string;
  citizenship: string;
  pep: PepRequest;
}

type SaveRequestAdvice = AccountQuestions & {
  takenRisk: number;
  investmentType: InvestmentType;
  riskAnswers: [number, number, number];
};

export interface SaveRequest {
  bankAccount: string;
  kyc: {
    company: QuestionAnswer[];
    account: QuestionAnswer[];
    handlesCash: boolean;
    ownMoney: boolean;
  };
  advice: SaveRequestAdvice;
  owners: {
    registered?: SaveRegisteredOwner[];
    designated?: SaveDesignatedOwner;
  };
  contactInformation: {
    email: string;
    phone?: string;
  };
  users: {
    email: string;
    admin: boolean;
    identificationNumber: string;
  }[];
  campaign?: string;
  accountName: string;
}

/*
  Note that this actually does a POST!
  When this is called, the backend will refresh its signup data from
  a few different sources, save it and then return it here, so this is mutative,
  but doesn't have any bad side effects.

  This is also what outputs (currently) in the logs:
    `User X Started onboarding with company Y`
  which is the reason you see this multiple times in the logs for each signup.

  TODO: Rethink this together with BE when we make a bigger change to the flow
*/
export function refreshCompanySignupData(companyId: string, signupId: string) {
  return API.post<PostCompanyResponse>(
    encode`/signup/company?creationToken=${signupId}`,
    { companyId }
  );
}

export function getName(identificationNumber: string, signupId: string) {
  return API.get<PostNameResponse>(
    encode`/signup/company/name?creationToken=${signupId}` +
      encode`&identificationNumber=${identificationNumber}`
  );
}

export function shareByEmail(email: string, signupId: string) {
  return API.post(
    encode`/signup/company/signing/share-by-email?creationToken=${signupId}`,
    { email }
  );
}

export function getSigningInfo(signupId: string) {
  return API.get<SigningInfoResponse>(
    encode`/signup/company/signing?creationToken=${signupId}`,
    true
  );
}

export function validateCompany(companyId: string, signupId: string) {
  return API.get<Company>(
    encode`/signup/company/${companyId}?creationToken=${signupId}`
  );
}

export function save(signupId: string, data: SaveRequest) {
  return API.post(encode`/signup/company/save?creationToken=${signupId}`, data);
}
