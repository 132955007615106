import { CheckmarkButton } from "@lysaab/ui-2";
import { FunctionComponent, useCallback, useState } from "react";

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
function copyToClipboard(str: string) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selection = document.getSelection();
  if (!selection) {
    return false;
  }

  const selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    selection.removeAllRanges();
    selection.addRange(selected);
  }

  return true;
}

export const CopyLinkButton: FunctionComponent<{ href: string }> = ({
  href,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyLink = useCallback(() => {
    if (copyToClipboard(href)) {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } else {
      setCopySuccess(false);
    }
  }, [href]);

  return (
    <CheckmarkButton onClick={copyLink} isSuccessful={copySuccess}>
      Kopiera länk
    </CheckmarkButton>
  );
};
