import { LysaLinkButton } from "@lysaab/ui-2";
import { useState } from "react";
import { Modal } from "../../../../../../components/modal/Modal";

export const PAIQuestionInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <LysaLinkButton onClick={() => setIsOpen(true)}>
        Läs mer om huvudsakliga negativa konsekvenser (PAI)
      </LysaLinkButton>
      <Modal
        showModal={isOpen}
        onClose={() => setIsOpen(false)}
        header="Huvudsakliga negativa konsekvenser (PAI)"
        closeOnOverlayClick
      >
        <p>
          Ny EU-reglering ställer krav på att kunder ska tillfrågas om sina
          hållbarhetspreferenser. Det är mot denna bakgrund som Lysa frågar dig
          om du önskar att dina investeringar ska beakta de huvudsakliga
          negativa konsekvenserna som en investeringsprodukt potentiellt medför.
        </p>
        <p>
          Negativa konsekvenser för hållbarhetsfaktorer handlar om hur
          investeringar kan påverka omvärlden, dvs. miljön, arbetsförhållanden
          och sociala frågor. Till exempel kan verksamheten i ett företag
          medföra utsläpp av koldioxid, skada den biologiska mångfalden, eller
          bryta mot OECD:s riktlinjer för multinationella företag.
        </p>
        <p>
          Genom att du som kund väljer att din investering ska beakta
          huvudsakliga negativa konsekvenser säkerställer du att de
          underliggande fonderna beaktar dessa negativa konsekvenser.{" "}
        </p>
      </Modal>
    </>
  );
};
