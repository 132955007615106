import { InvestmentType } from "@lysaab/ui-2";
import {
  PAIImportance,
  SFDRImportance,
  SustainabilityImportance,
  SustainabilityPreference,
  TaxonomyImportance,
} from "../data/investments";

export const corpMessages = {
  suitabilityHeader: "Information om ert investeringsförslag",
  suitabilityIntro:
    "Vi på Lysa vill vara vid er sida när ni investerar era pengar. Som kund har ni alltid rätt att få er lämplighetsbedömning och de investeringsförslag vi har tagit fram åt er.",
  suitabilityP2:
    "Företagets investeringsförslag är baserat på förljande information som ni har angivit:",
  suitabilityExperienceHeader: "Kunskap och erfarenhet",
  suitabilityExperienceText:
    "Ni har angivit att ni har tidigare erfarenhet att investera i fonder och/eller förstår ni kan förlora pengar om ni investerar i fonder.",
  suitabilitySituationHeader: "Ekonomisk situation",
  suitabilitySituationText:
    "Ni har angett att företagets nuvarande kassa kommer att täcka företagets löpande utgifter i mer än 6 månader och att det inte finns någon risk att företaget inom en snar framtid kan hamna på obestånd.",
  suitabilityRiskHeader: "Avkastning & risk",
  suitabilityRiskText:
    'När vi frågade er vad som är viktigast för företaget när ni sparar och investerar så var ert svar "{important}" och om värdet på era aktier skulle minska med 10% på en månad så svarade ni att ni "{reaction}". Vi presenterade en fiktiv situation där ni fick möjlighet att investera alla företagets besparingar i tillgångar som förväntades ha en avkastning på 6% under ett år och en risk att förlora 20% av era besparingar. Vi frågade sedan hur detta stämde överens med er riskbenägenhet, och ert svar var: "{riskPropensity}".',
  suitabilityHorizonHeader: "Investeringshorisont",
  suitabilityHorizonText:
    'Ni angav att investeringshorisonten för ert Lysakonto är "{length}" och att sannolikheten att ni behöver pengar tidigare är "{probability}".',
  suitabilityHorizonLengthSHORT: "Kort, 1-3 år",
  suitabilityHorizonLengthMIDDLE: "Mellan, 3-5 år",
  suitabilityHorizonLengthKINDA_LONG: "Ganska lång, 5-10 år",
  suitabilityHorizonLengthLONG: "Lång, 10-15 år",
  suitabilityHorizonLengthVERY_LONG: "Mycket lång, över 15 år",
  suitabilityHorizonProbabilityVERY_LIKELY: "Mycket troligt",
  suitabilityHorizonProbabilityLIKELY: "Sannolikt",
  suitabilityHorizonProbabilitySOMEWHAT_LIKELY: "Ganska troligt",
  suitabilityHorizonProbabilitySOMEWHAT_UNLIKELY: "Ganska osannolikt",
  suitabilityHorizonProbabilityUNLIKELY: "Osannolikt",
  suitabilityHorizonProbabilityVERY_UNLIKELY: "Mycket osannolikt",
  suitabilityAdviceHeader: "Investeringsförslag",
  suitabilityAdviceText:
    "Baserat på svaren ovan är vårt investeringsförslag för ert företag en fördelning på {stocks} % aktier och {bonds} % räntor med en {investmentType} portfölj.",
  suitabilityLogin:
    "Informationen ovan hittar ni i sin helhet när ni loggar in på företagets konto.",
  suitabilityCTA: "Tveka inte att kontakta oss om ni har några frågor.",
  suitabilityBR: "Vänliga hälsningar,",
  suitabilityEsgHeader: "Investeringsfokus",
  sustainabilityNotImportant: "{sustainability}",
  sustainabilityImportantNone: "{sustainability} {sustainabilityPreference}",
  sustainabilityImportantSpecific:
    "{sustainability} {sustainabilityPreference} {pai} {sfdr} {taxonomy}",
  [`suitabilityInvestmentType${InvestmentType.BROAD}`]: "Bred",
  [`suitabilityInvestmentType${InvestmentType.SUSTAINABLE}`]: "Hållbar",
  [`sustainabilityImportance${SustainabilityImportance.NOT_IMPORTANT}`]:
    "Baserat på ert val att investera så brett som möjligt har ert konto bred investeringsfokus.",
  [`sustainabilityImportance${SustainabilityImportance.IMPORTANT}`]:
    "Baserat på ert val att investera hållbart har ert konto hållbart investeringsfokus.",
  [`sustainabilityPreference${SustainabilityPreference.NONE}`]:
    "Ni har valt att följa Lysas hållbara investeringsfokus.",
  [`sustainabilityPreference${SustainabilityPreference.SPECIFIC}`]:
    "Vidare har ni valt specifika hållbarhetspreferenser.",
  [`pai${PAIImportance.NOT_IMPORTANT}`]:
    "Vidare har ni uppgett att det inte är viktigt att företagets investeringar inte har betydande negativ påverkan på specifika hållbarhetsområden enligt PAI.",
  [`pai${PAIImportance.IMPORTANT}`]:
    "Vidare har ni uppgett att det är viktigt att företagets investeringar inte har betydande negativ påverkan på specifika hållbarhetsområden enligt PAI.",
  [`sfdr${SFDRImportance.NONE}`]:
    "Ni har uppgett att företaget inte har någon preferens när det gäller de pengar som ska investeras i enlighet med SFDR.",
  [`sfdr${SFDRImportance.LEAST_25}`]:
    "Ni har valt att minst 25 % av företagets pengar ska investeras i enlighet med SFDR.",
  [`sfdr${SFDRImportance.LEAST_50}`]:
    "Ni har valt att minst 50 % av företagets pengar ska investeras i enlighet med SFDR.",
  [`sfdr${SFDRImportance.LEAST_75}`]:
    "Ni har valt att minst 75 % av företagets pengar ska investeras i enlighet med SFDR.",
  [`taxonomy${TaxonomyImportance.NONE}`]:
    "Ni har uppgett att företaget inte har någon preferens när det gäller de pengar som ska investeras i enlighet med taxonomin.",
  [`taxonomy${TaxonomyImportance.LEAST_25}`]:
    "Ni har valt att minst 25 % av företagets pengar ska investeras i enlighet med taxonomin.",
  [`taxonomy${TaxonomyImportance.LEAST_50}`]:
    "Ni har valt att minst 50 % av företagets pengar ska investeras i enlighet med taxonomin.",
  [`taxonomy${TaxonomyImportance.LEAST_75}`]:
    "Ni har valt att minst 75 % av företagets pengar ska investeras i enlighet med taxonomin.",
  updatedEsg: "Ni godkänner följande förändringar.",
  updatedEsgInsert: " Från {answer} enligt {type} till {updatedAnswer}.",
  sfdr: "SFDR",
  taxonomy: "taxonomin",
};
