import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/web/url-search-params";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ScrollToTop } from "./ScrollToTop";
import { CompanySignupContextProvider } from "./models/CompanySignupContextProvider";
import { CampaignTracker } from "./components/CampaignTracker";
import { CookieConsent } from "./components/cookieConsent/CookieConsent";
import { AdviceProvider } from "./models/AdviceContext";
import { CompanySignup } from "./pages/company-signup/CompanySignup";
import { BaseProviders } from "./BaseProviders";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://9230232cb5cb46b9b8c8533919a90a07@sentry.io/5180511",
    maxValueLength: 15000,
    environment: process.env.NODE_ENV,
    normalizeDepth: 4,
    maxBreadcrumbs: 300,
  });
}

// TODO: Move CompanySignupContextProvider in to CompanySignup
//       Have to move PEP-stuff to its own subrouter first
const root = createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <BaseProviders>
      <ScrollToTop />
      <CompanySignupContextProvider>
        <AdviceProvider>
          <CampaignTracker />
          <CompanySignup />
          <CookieConsent />
        </AdviceProvider>
      </CompanySignupContextProvider>
    </BaseProviders>
  </BrowserRouter>
);
