interface Props {
  amount: number;
  decimals?: number;
}

export const Amount: React.FunctionComponent<Props> = ({
  amount,
  decimals = 0,
}) => {
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  });

  return <>{formatter.format(amount)}</>;
};
