import React, { useEffect } from "react";
import { client as elevioClient } from "elevio";

export const ELEVIO_ACCOUNT_ID = "5a7cb3ba2c89b";

const language = "sv";

export const ElevioLoader: React.VFC = () => {
  useEffect(() => {
    elevioClient.load(ELEVIO_ACCOUNT_ID).then((elevio) => {
      const groups = ["country_sv", "type_corporation", "context_onboarding"];

      elevio.setUser({ groups });
      elevio.setLanguage(language);
    });
  }, []);

  return null;
};
