import React from "react";
import { Card } from "@lysaab/ui-2";
import "./ManualReview.scss";

export const ManualReview = () => {
  return (
    <div className="company-signup-manual-review">
      <h2>Vi behandlar din ansökan</h2>
      <Card>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 150 150"
          width="200"
          height="200"
          preserveAspectRatio="xMidYMid meet"
          className="big-icon"
        >
          <defs>
            <clipPath id="__lottie_element_38">
              <rect width="150" height="150" x="0" y="0"></rect>
            </clipPath>
          </defs>
          <g clipPath="url(#__lottie_element_38)">
            <g transform="matrix(1,0,0,1,74.83399963378906,74.5)">
              <g>
                <path
                  fill="rgb(14,49,227)"
                  d=" M40.54999923706055,20.249000549316406 C40.54999923706055,20.249000549316406 20.308000564575195,20.249000549316406 20.308000564575195,20.249000549316406 C20.308000564575195,20.249000549316406 20.308000564575195,-17.878000259399414 20.308000564575195,-17.878000259399414 C20.308000564575195,-19.225000381469727 19.214000701904297,-20.315000534057617 17.867000579833984,-20.309999465942383 C17.867000579833984,-20.309999465942383 -20.176000595092773,-20.235000610351562 -20.176000595092773,-20.235000610351562 C-20.176000595092773,-20.235000610351562 -20.176000595092773,-40.47700119018555 -20.176000595092773,-40.47700119018555 C-20.176000595092773,-40.47700119018555 5.732999801635742,-40.47700119018555 5.732999801635742,-40.47700119018555 C24.961999893188477,-40.47700119018555 40.54999923706055,-24.888999938964844 40.54999923706055,-5.659999847412109 C40.54999923706055,-5.659999847412109 40.54999923706055,20.249000549316406 40.54999923706055,20.249000549316406z"
                ></path>
              </g>
            </g>
            <g transform="matrix(-1,0,0,-1,74.95800018310547,74.52200317382812)">
              <g>
                <path
                  fill="rgb(14,49,227)"
                  d=" M40.54999923706055,20.249000549316406 C40.54999923706055,20.249000549316406 20.308000564575195,20.249000549316406 20.308000564575195,20.249000549316406 C20.308000564575195,20.249000549316406 20.308000564575195,-17.878000259399414 20.308000564575195,-17.878000259399414 C20.308000564575195,-19.225000381469727 19.214000701904297,-20.315000534057617 17.867000579833984,-20.309999465942383 C17.867000579833984,-20.309999465942383 -20.176000595092773,-20.235000610351562 -20.176000595092773,-20.235000610351562 C-20.176000595092773,-20.235000610351562 -20.176000595092773,-40.47700119018555 -20.176000595092773,-40.47700119018555 C-20.176000595092773,-40.47700119018555 5.732999801635742,-40.47700119018555 5.732999801635742,-40.47700119018555 C24.961999893188477,-40.47700119018555 40.54999923706055,-24.888999938964844 40.54999923706055,-5.659999847412109 C40.54999923706055,-5.659999847412109 40.54999923706055,20.249000549316406 40.54999923706055,20.249000549316406z"
                ></path>
              </g>
            </g>
          </g>
        </svg>

        <p>
          Hej! Vad kul att du vill bli kund hos oss. Innan vi kan aktivera ditt
          konto behöver vi göra en extra kontroll av din ansökan.
        </p>
        <p>
          Detta tar normalt någon dag men kan under semestertider ta längre tid.
          Du behöver inte ansöka igen – vi kommer att skicka ett mejl när vi är
          klara.
        </p>
      </Card>
    </div>
  );
};
