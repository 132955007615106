import React, { useContext } from "react";
import { Checkbox, RequiredValidator } from "@lysaab/ui-2";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";

const requiredValidator = new RequiredValidator(
  "Ett företag som inte har skatterättslig hemvist i Sverige kan av " +
    "skattemässiga skäl inte bli kund hos oss."
);

const taxResidencyAlternative = {
  text:
    "Jag bekräftar att det valda företaget har skatterättslig hemvist " +
    "i Sverige.",
  value: "-",
};

export const TaxResidencyCheckbox = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const isChecked = contextState.companySelectionTaxResidencyConfirmed;

  return (
    <Checkbox
      alternative={taxResidencyAlternative}
      checked={isChecked}
      onChange={() =>
        updateContext({
          companySelectionTaxResidencyConfirmed: !isChecked,
        })
      }
      validators={[requiredValidator]}
    />
  );
};
