import React, { FunctionComponent, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  RequiredValidator,
} from "@lysaab/ui-2";
import { CountrySelect } from "../../../components/CountrySelect";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { names } from "../../../names";

interface Props {}

export const OwnerInfo: FunctionComponent<Props> = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const history = useHistory();
  const formRef = useRef<LysaFormRef>();

  const onSubmit = () => {
    if (formRef.current?.isValid) {
      if (
        contextState.designatedOwner?.citizenship === "US" ||
        contextState.designatedOwner?.taxDomicile === "US"
      ) {
        history.push(names.FATCA_CHECK);
      } else {
        history.push(names.USERS);
      }
    }
  };

  useEffect(() => {
    if (!contextState.designatedOwner) {
      history.push(names.HOME);
      return;
    }
    if (!contextState.designatedOwner.citizenship) {
      updateContext({
        designatedOwner: {
          ...contextState.designatedOwner,
          citizenship: "SE",
          taxDomicile: "SE",
        },
      });
    }
  }, [contextState, updateContext, history]);

  if (!contextState.designatedOwner) {
    return null;
  }

  return (
    <div>
      <Form onSubmit={onSubmit} lysaFormRef={formRef}>
        <h2>Bekräfta uppgifter för {contextState.designatedOwner.name}</h2>
        <Card>
          <CountrySelect
            label="Ange medborgarskap"
            value={contextState.designatedOwner.citizenship}
            onChange={(country) => {
              contextState.designatedOwner &&
                updateContext({
                  designatedOwner: {
                    ...contextState.designatedOwner,
                    citizenship: country.value,
                  },
                });
            }}
            validators={[new RequiredValidator("Du måste ange medborgarskap")]}
          />
          <CountrySelect
            label="Ange skattehemvist"
            value={contextState.designatedOwner.taxDomicile}
            onChange={(country) =>
              contextState.designatedOwner &&
              updateContext({
                designatedOwner: {
                  ...contextState.designatedOwner,
                  taxDomicile: country.value,
                },
              })
            }
            validators={[new RequiredValidator("Du måste ange skattehemvist")]}
          />
        </Card>
        <Button block type="submit" label="Gå vidare" />
      </Form>
    </div>
  );
};
