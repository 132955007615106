import React, {
  useCallback,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  TextInput,
  EmailInput,
  Form,
  EmailValidator,
  Snackbar,
  LysaFormRef,
  SNACKBAR_TYPES,
  SwedishTin,
  SwedishTinValidator,
  RequiredValidator,
  RadioGroup,
  Alternative,
} from "@lysaab/ui-2";
import { names } from "../../../../names";
import { User, getName } from "../../../../data/signup";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import { DuplicateUserValidator } from "./DuplicateUserValidator";
import "./AddEditUser.scss";

const NO_CHANGE_ERROR = "Det finns inga ändringar att spara";
const DELETE_LAST_USER_ERROR = "Det går inte att ta bort sista användaren";

enum USER_TYPES {
  ADMIN,
  LIMITED_USER,
}

const userTypes: Alternative<USER_TYPES>[] = [
  {
    text: "Begränsad användare",
    value: USER_TYPES.LIMITED_USER,
  },
  {
    text: "Administratör",
    value: USER_TYPES.ADMIN,
  },
];

function upsertUser(users: User[], newUser: User) {
  let found = false;
  const newUsers = users.map((user) => {
    if (user.identificationNumber === newUser.identificationNumber) {
      found = true;
      return newUser;
    } else {
      return user;
    }
  });

  if (!found) {
    newUsers.push(newUser);
  }

  return newUsers;
}

export const AddEditUser = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const history = useHistory();
  const [user, setUser] = useState<User>();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const [isNameLookupFail, setIsNameLookupFail] = useState(false);
  const [ids, setIds] = useState<string[]>([]);
  const formRef = useRef<LysaFormRef>();
  const idRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  useEffect(() => {
    const ids = contextState.users.map((user) => user.identificationNumber);
    const user = contextState.users.find((user) => user.isEditing);

    if (!user) {
      setIds(ids);
      return;
    }

    setIds(ids.filter((id) => id !== user.identificationNumber));
    setUser(user);
    setEmail(user.email || "");
    setName(user.name);
    setIdentificationNumber(user.identificationNumber);
    setIsAdmin(user.admin || false);
  }, [contextState.users]);

  const persistData = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      //This is where we persist to the server or show any errors
      event.preventDefault();

      if (user && user.email === email && user.admin === isAdmin) {
        setError(NO_CHANGE_ERROR);
        return;
      }

      if (!formRef.current || !formRef.current.isValid) {
        return;
      }

      const newUser: User = {
        identificationNumber,
        email,
        name,
        admin: isAdmin,
        isEditing: false,
      };

      updateContext({ users: upsertUser(contextState.users, newUser) });

      history.push(names.USERS);
    },
    [
      identificationNumber,
      email,
      name,
      isAdmin,
      user,
      formRef,
      history,
      contextState.users,
      updateContext,
    ]
  );

  const deleteUser = useCallback(() => {
    if (contextState.users.length < 2) {
      setError(DELETE_LAST_USER_ERROR);
      return;
    }

    const filteredUsers = contextState.users.filter(
      (user) => user.identificationNumber !== identificationNumber
    );

    updateContext({ users: filteredUsers });
    history.push.call(undefined, { pathname: names.USERS });
  }, [history.push, updateContext, contextState.users, identificationNumber]);

  const cancel = useCallback(() => {
    if (user) {
      user.isEditing = false;
      updateContext({ users: contextState.users });
    }

    history.push.call(undefined, { pathname: names.USERS });
  }, [user, updateContext, contextState.users, history.push]);

  const onChangeTin = useCallback(
    (value: string) => {
      setIdentificationNumber(value);
      setIsNameLookupFail(false);
      const id = new SwedishTin(value).getNormalizedTin();
      const signupId = contextState.signupId;

      if (new SwedishTin(id).isValid() && signupId) {
        getName(id, signupId)
          .then((response) => setName(response.name))
          .catch(() => {
            setIsNameLookupFail(true);
          });
      } else {
        setName("");
      }
    },
    [contextState.signupId]
  );

  useEffect(() => idRef.current?.focus(), []);

  const retryNameLookup = () => {
    setIsNameLookupFail(false);
    const id = new SwedishTin(identificationNumber).getNormalizedTin();
    const signupId = contextState.signupId;

    if (!signupId) {
      return;
    }

    getName(id, signupId)
      .then((response) => setName(response.name))
      .catch(() => {
        setIsNameLookupFail(true);
      });
  };

  return (
    <div className="company-signup-add-edit-user">
      {user ? <h2>Ändra användare</h2> : <h2>Lägg till användare</h2>}
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <Card>
          <TextInput
            label="Personnummer"
            placeholder="ÅÅÅÅMMDD-XXXX"
            value={identificationNumber}
            readOnly={user && !!user.identificationNumber}
            ref={idRef}
            onChange={onChangeTin}
            validators={[
              new SwedishTinValidator("Felaktigt personnummer"),
              new RequiredValidator("Fyll i personnummer"),
              new DuplicateUserValidator(ids),
            ]}
          />
          {isNameLookupFail && (
            <div className="name-lookup-fail">
              <span className="name-lookup-fail-error-text">
                Namnuppslaget gick åt skogen{" "}
                <span role="img" aria-label="tree">
                  🌲
                </span>
              </span>
              <Button
                size="small"
                inline
                onClick={retryNameLookup}
                label="Försök igen"
              />
            </div>
          )}
          <TextInput
            label="Namn"
            value={name}
            readOnly
            validators={[
              new RequiredValidator("Fyll i personnummer för att få ett namn"),
            ]}
          />
          <EmailInput
            label="E-post"
            value={email}
            placeholder="email@exempel.se"
            onChange={(value) => {
              setError("");
              setEmail(value);
            }}
            validators={[
              new EmailValidator("Felaktig emailadress"),
              new RequiredValidator("Fyll i emailadress"),
            ]}
          />
          <div className="lysa-ui-input-group">
            <RadioGroup
              header="Typ av användare"
              alternatives={userTypes}
              value={isAdmin ? userTypes[1] : userTypes[0]}
              onChange={(alternative) => {
                setError("");
                setIsAdmin(alternative.value === USER_TYPES.ADMIN);
              }}
            />
            <p className="user-types-description">
              En begränsad användare har endast läsrättigheter kopplat till
              Lysakontot
            </p>
            <p className="user-types-description">
              En adminstratör har full behörighet att administrera Lysakontot
              och kan i framtiden ge behörighet till nya administratörer och
              användare
            </p>
          </div>
        </Card>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            {error}
          </Snackbar>
        )}
        <Button block type="submit" label="Spara" />
        <Button
          variant="secondary"
          block
          type="button"
          onClick={cancel}
          label="Avbryt"
        />
        {user && (
          <Button
            variant="negative"
            block
            type="button"
            onClick={deleteUser}
            label="Ta bort användare"
          />
        )}
      </Form>
    </div>
  );
};
