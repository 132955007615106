import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAdvice } from "../../models/AdviceContext";

interface Props {
  children: ReactNode;
  adviceRoute: string;
}

export const AdviceGuard: FunctionComponent<Props> = ({
  children,
  adviceRoute,
}) => {
  const { result } = useAdvice();
  const history = useHistory();

  useEffect(() => {
    if (result.advicedRisk === undefined) {
      history.replace(adviceRoute);
    }
  }, [history, result, adviceRoute]);

  return <React.Fragment>{children}</React.Fragment>;
};
