import React, { FunctionComponent } from "react";
import { Button, Spinner } from "@lysaab/ui-2";
import { PepAnswers, PepContextProps } from "@lysaab/lysa-pep";
import { names } from "../../../../names";
import { useHistory } from "react-router-dom";
import { SuggestedOwner, RegisteredOwner } from "../../../../data/signup";
import { pepStateWithType } from "./PepOwnerSelection";
import "./PepEditOverview.scss";

interface PepEditOverviewProps {
  owner: SuggestedOwner | RegisteredOwner;
  isValidating?: "YES" | "NO" | "DONE" | undefined;
  pepContext: PepContextProps;
}

export const PepEditOverview: FunctionComponent<PepEditOverviewProps> = ({
  owner,
  isValidating,
  pepContext,
}) => {
  const history = useHistory();

  const classes =
    "card pep-edit-overview" +
    (isValidating === "YES" ? " is-validating" : "") +
    (isValidating === "DONE" ? " done-validating" : "");

  return (
    <div className={classes}>
      <h3>{owner.name}</h3>
      <div className="expander"></div>
      <div className="pep-answers-wrapper">
        {owner.pep && (
          <PepAnswers answer={{ ...owner.pep, ownerName: owner.name }} />
        )}
      </div>
      <div className="pep-card-validating">
        <Spinner />
        <p>
          <span>Kontrollerar svar...</span>
        </p>
      </div>
      {isValidating === "DONE" && (
        <div className="pep-card-done-validating">
          <p>Klar</p>
        </div>
      )}
      <Button
        block
        onClick={() => {
          if (owner.pep) {
            const pepState = pepStateWithType(owner);
            pepContext.setState(pepState);
            history.push(names.PEP_IS_PEP);
          }
        }}
        disabled={isValidating === "YES" || isValidating === "DONE"}
        label="Ändra svar"
      />
    </div>
  );
};
