import React, { useRef, useEffect, useCallback, useState } from "react";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import { DocModalLink } from "@lysaab/ui-2";
import { LocalStorage } from "../../data/localStorage";
import "./CookieConsent.scss";

const CookieLinkButton: React.FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  return <button type="button" className="cookie-link-button" {...props} />;
};

export const CookieConsent: React.FC<{}> = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(
    !LocalStorage.getItem(LocalStorage.Key.STORE_SETTINGS_COOKIE_CONSENT_OK)
  );
  const [offset, setOffset] = useState<number>(0);
  const cookieElem = useRef<HTMLDivElement>(null);
  const [acceptAllCookies, setAcceptAllCookies] = useState<boolean>();

  useEffect(() => {
    if (!cookieElem.current) {
      return;
    }
    const elem = cookieElem.current;

    const onTransitionEnd = (ev: TransitionEvent) => {
      if (ev.propertyName !== "transform") {
        return;
      }

      if (acceptAllCookies) {
        LocalStorage.setItem(
          LocalStorage.Key.STORE_SETTINGS_COOKIE_CONSENT_OK,
          new Date().toISOString()
        );
      }

      setShowCookieConsent(false);
    };

    elem.addEventListener("transitionend", onTransitionEnd);

    return () => {
      elem.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [acceptAllCookies, showCookieConsent, setShowCookieConsent]);

  const acceptCookies = useCallback(
    (accept: boolean) => () => {
      if (!cookieElem.current) {
        return;
      }
      setAcceptAllCookies(accept);
      const box = cookieElem.current.getBoundingClientRect();
      setOffset(box.height);
    },
    []
  );

  if (!showCookieConsent) {
    return null;
  }

  return (
    <div
      className="baked-dough-consent"
      ref={cookieElem}
      style={{ transform: `translateY(${offset}px)` }}
    >
      <article>
        <div className="col">
          <p>
            För att göra webbsidan och Lysa tjänsters tillgängliga för dig
            använder vi oss av kakor. Vi använder endast sådana cookies som är
            nödvändiga för att hemsidan och tjänsterna ska fungera korrekt och
            dessa kan därför inte stängas av.{" "}
            <DocModalLink
              document="legal/se/sv/kakor-1.md"
              modalAnnouncement="Öppnat information om kakor"
              component={CookieLinkButton}
            >
              Information om användning av cookies
            </DocModalLink>
            .
          </p>
        </div>
        <div className="col">
          <Button
            onClick={acceptCookies(true)}
            data-test-id="accept-cookies-button"
            label="Ok"
          />
        </div>
      </article>
    </div>
  );
};
