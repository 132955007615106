import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DocModalLink, LysaLink, StoryFooter } from "@lysaab/ui-2";

const email = "foretag@lysa.se";
const phoneNumber = "+46 10 551 32 30";
const privacyPolicyDoc = "legal/se/sv/personuppgiftspolicy.md";

export function ContactFooter() {
  const intl = useIntl();

  return (
    <StoryFooter
      contactInfoText={
        <FormattedMessage
          id="footer.contact"
          values={{
            break: () => <br />,
            phone: () => {
              return (
                <LysaLink href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                  {phoneNumber}
                </LysaLink>
              );
            },
            email: () => {
              return <LysaLink href={`mailto:${email}`}>{email}</LysaLink>;
            },
          }}
        />
      }
      privacyPolicyText={
        <FormattedMessage
          id="footer.privacyPolicy"
          values={{
            a: (linkText: React.ReactNode[]) => {
              return (
                <DocModalLink
                  document={privacyPolicyDoc}
                  modalAnnouncement={intl.formatMessage({
                    id: "footer.modalAnnouncement",
                  })}
                  key="element-key-pp"
                >
                  {linkText}
                </DocModalLink>
              );
            },
          }}
        />
      }
    />
  );
}
