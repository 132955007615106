import React, {
  useCallback,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
  RouteProps,
} from "react-router-dom";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  SegmentedSelect,
  Slider,
} from "@lysaab/ui-2";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { EditAllocationForecast } from "./EditAllocationForecast";
import { EditAllocationPositions } from "./EditAllocationPositions";
import { HorizonWarning } from "./HorizonWarning";

import "./EditAllocation.scss";

import { FeesInformation } from "../../../components/feesInformation/FeesInformation";
import { InvestmentDisclaimer } from "../../../components/InvestmentDisclaimer";
import { SavingsHorizonLength } from "../../../data/investments";
import { useAdvice } from "../../../models/AdviceContext";
import { ACCOUNT_TYPE } from "../../../data/signup";

export const MIN_INVESTMENT = 1_000;
export const MAX_INVESTMENT = 100_000_000_000;

const subpageAlternatives = [
  {
    text: "Utveckling",
    value: "forecast",
  },
  {
    text: "Avgifter",
    value: "fees",
  },
  {
    text: "Innehav",
    value: "positions",
  },
];

const WARNING_THRESHOLD = 15;

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
type RoutePropsNoArray = Overwrite<RouteProps, { path: string }>;

export type RouteMap = {
  [key: string]: RoutePropsNoArray;
};

export const ROUTES: RouteMap = {
  FORECAST: {
    path: names.EDIT_ALLOCATION_FORECAST,
  },
  FEES: {
    path: names.EDIT_ALLOCATION_FEES,
  },
  POSITIONS: {
    path: names.EDIT_ALLOCATION_POSITIONS,
  },
};

function routeIndex(path: string) {
  return Math.max(
    0,
    Object.values(ROUTES).findIndex((route) => route.path === path)
  );
}

export const normalizeInvestment = (investment: number) => {
  if (investment < MIN_INVESTMENT || investment > MAX_INVESTMENT) {
    return 0;
  }
  return investment;
};

export const EditAllocation = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const advice = useAdvice();
  const history = useHistory();
  const location = useLocation();
  const [showHorizonWarning, setShowHorizonWarning] = useState(false);
  const [subpage, setSubpage] = useState(
    subpageAlternatives[routeIndex(location.pathname)]
  );

  useEffect(() => {
    if (typeof advice.result.advicedRisk === "undefined") {
      history.replace(names.HOME);
    }

    if (typeof contextState.allocationSelectedRisk === "undefined") {
      updateContext({ allocationSelectedRisk: advice.result.advicedRisk });
    }
  }, [contextState, updateContext, history, advice.result.advicedRisk]);

  useEffect(() => {
    setSubpage(subpageAlternatives[routeIndex(location.pathname)]);
  }, [location.pathname]);

  useEffect(() => {});

  const onSliderChange = (value: number) => {
    if (!advice.input.savingsHorizon) {
      return;
    }

    if (
      advice.input.savingsHorizon === SavingsHorizonLength.SHORT &&
      value > 50
    ) {
      setShowHorizonWarning(true);
      updateContext({ allocationSelectedRisk: 50 });
    } else {
      setShowHorizonWarning(false);
      updateContext({ allocationSelectedRisk: value });
    }
  };

  const formRef = useRef<LysaFormRef>();

  const risk = contextState.allocationSelectedRisk;
  const advisedRisk = advice.result.advicedRisk;
  const investmentType = advice.result.investmentType;

  const persistData = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (typeof risk === "undefined" || typeof advisedRisk === "undefined") {
        return;
      }

      if (formRef.current && formRef.current.isValid) {
        if (Math.abs(risk - advisedRisk) > WARNING_THRESHOLD) {
          history.push(names.WARNING);
          return;
        }

        advice.setResult({
          takenRisk: risk,
        });

        history.push(names.SUMMARY);
      }
    },
    [risk, advisedRisk, advice, history]
  );

  if (
    typeof risk === "undefined" ||
    typeof advisedRisk === "undefined" ||
    typeof investmentType === "undefined"
  ) {
    return null;
  }

  return (
    <div className="company-signup-edit-allocation">
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <Card>
          <div className="editallocation-header">
            <h4>Ändra fördelning</h4>
            <Button
              size="small"
              inline
              onClick={() =>
                updateContext({
                  allocationSelectedRisk: advisedRisk,
                })
              }
              className={
                contextState.allocationSelectedRisk === advisedRisk
                  ? "reset-hidden"
                  : ""
              }
              label="Återställ"
            />
          </div>
          <div className="allocations">
            <strong>Lysas förslag</strong> -{" "}
            <i>
              {advisedRisk} % aktier, {100 - advisedRisk} % räntor
            </i>
            <Slider
              label={
                <>
                  <strong>Målfördelning</strong> -{" "}
                  <i>
                    {risk} % aktier, {100 - risk} % räntor
                  </i>
                  {showHorizonWarning && <HorizonWarning />}
                </>
              }
              min={0}
              max={100}
              value={contextState.allocationSelectedRisk}
              onChange={onSliderChange}
            />
          </div>
        </Card>
        <SegmentedSelect
          alternatives={subpageAlternatives}
          value={subpage}
          onChange={(value) => {
            setSubpage(value);
            history.push(names.EDIT_ALLOCATION + "/" + value.value);
          }}
        />

        <Switch>
          <Route path={ROUTES.FORECAST.path}>
            <EditAllocationForecast chosenAllocation={risk.toString()} />
          </Route>
          <Route path={ROUTES.FEES.path}>
            <FeesInformation
              risk={risk}
              investmentType={investmentType}
              accountType={ACCOUNT_TYPE.VP}
            />
          </Route>
          <Route path={ROUTES.POSITIONS.path}>
            <EditAllocationPositions chosenAllocation={risk.toString()} />
          </Route>
          <Redirect to={names.EDIT_ALLOCATION_FORECAST} />
        </Switch>

        <Button block type="submit" label="Gå vidare" />
      </Form>
      <InvestmentDisclaimer />
    </div>
  );
};
