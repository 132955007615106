import { useCallback, useContext, useEffect } from "react";
import { Card, Button, Icon } from "@lysaab/ui-2";
import "./Warning.scss";
import { useHistory } from "react-router-dom";
import { names } from "../../../names";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { useAdvice } from "../../../models/AdviceContext";

export const Warning = () => {
  const { state: contextState } = useContext(CompanySignupContext);
  const history = useHistory();
  const advice = useAdvice();

  const risk = contextState.allocationSelectedRisk;
  const advisedRisk = advice.result.advicedRisk;

  if (typeof risk === "undefined" || typeof advisedRisk === "undefined") {
    throw new Error("Missing required data");
  }

  const stocks = risk;
  const bonds = 100 - risk;
  const stocksAdviced = advisedRisk;
  const bondsAdviced = 100 - advisedRisk;

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  const next = useCallback(() => {
    advice.setResult({ takenRisk: risk });
    history.push(names.SUMMARY);
  }, [advice, risk, history]);

  const nextLysa = useCallback(() => {
    advice.setResult({ takenRisk: advisedRisk });
    history.push(names.SUMMARY);
  }, [advice, advisedRisk, history]);

  return (
    <div className="risk-warning">
      <h2>Avrådan från fördelning</h2>
      <Card>
        <Icon.Error size={48} color="#FDDE4A" />
        <p>
          Målfördelningen <strong>{stocks}% aktier</strong>,{" "}
          <strong>{bonds}% räntor</strong>, avviker från vårt
          investeringsförslag om <strong>{stocksAdviced}% aktier</strong>,{" "}
          <strong>{bondsAdviced}% räntor</strong>. Även om din valda fördelning
          är lämplig för dig anser vi att det är bättre om du väljer en
          fördelning närmare investeringsförslaget.
        </p>
      </Card>
      <Button onClick={nextLysa} block label="Gå vidare med Lysas förslag" />
      <Button
        variant="secondary"
        onClick={next}
        block
        label="Gå vidare med din valda fördelning"
      />
      <Button
        variant="secondary"
        onClick={() => history.goBack()}
        block
        label="Tillbaka"
      />
    </div>
  );
};
