import { API, InvestmentType } from "@lysaab/ui-2";

export interface GetAllocationResponse {
  [isin: string]: number;
}

export function getAllocation(risk: number, type = InvestmentType.BROAD) {
  const url = `/investments/allocations?risk=${risk}&investmentType=${type}`;
  return API.get<GetAllocationResponse>(url);
}
