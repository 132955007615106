import {
  API,
  encode,
  OrderRef,
  BankIdPendingResponse,
  BankIdFailedResponse,
  BankIdCompletedResponse,
  BankIdInitResponse,
} from "@lysaab/ui-2";

export enum COMPANY_VALIDATION_REASON {
  NOT_AVAILABLE = "NOT_AVAILABLE",
  INVALID_ID = "INVALID_ID",
  INCOMPLETE_SIGNEES = "INCOMPLETE_SIGNEES",
  NO_VALID_OWNERS = "NO_VALID_OWNERS",
  FOREIGN_OWNER = "FOREIGN_OWNER",
  CONTACT_US = "CONTACT_US",
}

export interface Company {
  name: string;
  companyId: string;
  disabled: boolean;
  reason?: COMPANY_VALIDATION_REASON;
}

export type BankIdIdentifyPollCompletedResponse = BankIdCompletedResponse & {
  creationToken: string;
  payload: {
    companies: Company[];
  };
};

export type BankIdIdentifyPollResponse =
  | BankIdPendingResponse
  | BankIdFailedResponse
  | BankIdIdentifyPollCompletedResponse;

export type BankidSignPollResponse =
  | BankIdPendingResponse
  | BankIdFailedResponse
  | BankIdCompletedResponse;

type QrPollResponse = {
  code: string;
};

export const SIGN_OWNER_URL = "/signup/company/sign/owners";
export const SIGN_SIGNEE_URL = "/signup/company/sign/signees";

export class Bankid {
  static initSign(url: string, id: number, signupId: string) {
    return API.post<BankIdInitResponse>(`${url}?creationToken=${signupId}`, {
      id,
    });
  }

  static pollSign(url: string, orderRef: OrderRef, signupId: string) {
    return API.get<BankidSignPollResponse>(
      `${url}/${orderRef}?creationToken=${signupId}`,
      true
    );
  }

  static pollSignQrCode(orderRef?: OrderRef) {
    if (!orderRef) {
      return Promise.resolve(undefined);
    }

    return API.get<QrPollResponse>(
      encode`/signup/company/sign/qr/${orderRef}`,
      true
    );
  }

  static initIdentify(email: string) {
    return API.post<BankIdInitResponse>("/signup/company/login", {
      email,
    });
  }

  static pollIdentify(orderRef: OrderRef) {
    return API.get<BankIdIdentifyPollResponse>(
      encode`/signup/company/login/${orderRef}`,
      true
    );
  }

  static pollIdentifyQrCode(orderRef?: OrderRef) {
    if (!orderRef) {
      return Promise.resolve(undefined);
    }

    return API.get<QrPollResponse>(
      encode`/signup/company/login/qr/${orderRef}`,
      true
    );
  }
}
