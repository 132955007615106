import React, { FunctionComponent, useContext, useState } from "react";
import { SuggestedOwner } from "../../../../data/signup";
import { SuggestedOwnerCard } from "./SuggestedOwnerCard";
import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import { useHistory } from "react-router-dom";
import { names } from "../../../../names";

interface Props {
  suggestedOwners: SuggestedOwner[];
}

export const OwnerSelection: FunctionComponent<Props> = ({
  suggestedOwners,
}) => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  return (
    <React.Fragment>
      <h2>Verklig huvudman</h2>

      <p>Företaget saknar verklig huvudman. Utse en nedan.</p>

      <div className="suggested-owners-wrapper">
        {suggestedOwners.map((suggestedOwner) => (
          <SuggestedOwnerCard
            key={suggestedOwner.name}
            owner={suggestedOwner}
            selected={
              contextState.designatedOwner?.role === suggestedOwner.role
            }
            onClick={() => {
              if (contextState.designatedOwner?.role !== suggestedOwner.role) {
                updateContext({
                  designatedOwner: suggestedOwner,
                });
                setShowError(false);
              }
            }}
          />
        ))}
      </div>

      {showError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          Du måste utse en verkligen huvudman
        </Snackbar>
      )}

      <Button
        block
        type="button"
        onClick={() => {
          if (!contextState.designatedOwner) {
            setShowError(true);
            return;
          }
          history.push(names.OWNER_INFO);
        }}
        label="Gå vidare"
      />
    </React.Fragment>
  );
};
