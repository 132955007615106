import React from "react";
import { Card, Button } from "@lysaab/ui-2";
import "./Done.scss";

export const Done = () => (
  <div className="company-signup-done">
    <h2>Välkommen till Lysa!</h2>
    <Card>
      <p>
        Ditt företagskonto är nu klart och en värdepappersdepå har öppnats
        automatiskt. Vill du även öppna en kapitalförsäkring kan du göra det
        direkt genom att klicka på knappen nedan.
      </p>

      <Button
        block
        type="button"
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_APP}/se/create-account/corporation`;
        }}
        label="Öppna kapitalförsäkring"
      />

      <Button
        block
        type="button"
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_APP}/se`;
        }}
        label="Logga in"
      />
    </Card>
  </div>
);
