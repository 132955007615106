import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Spinner, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { names } from "../../../../names";
import { CompanySignupContext } from "../../../../models/CompanySignupContextProvider";
import {
  refreshCompanySignupData,
  SuggestedOwner,
  GENERIC_ERROR,
} from "../../../../data/signup";

import "./BeneficialOwners.scss";
import { OwnerSelection } from "./OwnerSelection";
import { RegisteredOwners } from "./RegisteredOwners";

export const BeneficialOwners = () => {
  const { state: contextState, setState: updateContext } =
    useContext(CompanySignupContext);
  const [showError, setShowError] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const history = useHistory();
  const [suggestions, setSuggestions] = useState<SuggestedOwner[]>([]);

  useEffect(() => {
    if (!contextState.signupId) {
      history.push(names.HOME);
    }
  }, [contextState.signupId, history]);

  useEffect(() => {
    const selectedCompany = contextState.selectedCompany;

    if (!selectedCompany || !contextState.signupId) {
      return;
    }
    setLoaded(false);
    refreshCompanySignupData(selectedCompany.companyId, contextState.signupId)
      .then((company) => {
        updateContext({
          registeredOwners: company.owners.registered || [],
        });
        setSuggestions(company.owners.suggested || []);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
        setShowError(true);
      });
  }, [contextState.selectedCompany, contextState.signupId, updateContext]);

  return (
    <div className="company-signup-beneficial-owners">
      {showError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR}>{GENERIC_ERROR}</Snackbar>
      )}
      {!loaded && <Spinner />}
      {loaded && contextState.registeredOwners.length > 0 && (
        <RegisteredOwners />
      )}
      {loaded && suggestions.length > 0 && (
        <OwnerSelection suggestedOwners={suggestions} />
      )}
    </div>
  );
};
